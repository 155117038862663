import "./style.css";

export default function LoginButton({ text, submit }) {
  const handleAction = (event) => {
    event.preventDefault();
    submit();
  };

  return (
    <div className="container-form-login-button">
      <button className="form-login-button" onClick={(e) => handleAction(e)}>
        {text}
      </button>
    </div>
  );
}
