import "./style.css";
import "../../responsive/Toast-responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Info from "../../assets/icons/InfoIcon";

export const showNotification = (message) =>
  toast(
    <>
      <div className="toast-body-message">
        <span className="icon-info">
          <Info color={"#8b68c9"} size={1.4} />
        </span>
        <span className="toast-message">{message}</span>
      </div>
    </>
  );

export default function Toast() {
  return (
    <>
      <ToastContainer
        toastClassName="toast_container"
        bodyClassName="toast-body"
        progressClassName="toast-progress-bar"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </>
  );
}
