import "./style.css";
import Bell from "../../../assets/icons/BellIcon.jsx";

export default function BoxFiringAlerts({ customer, apisFiring, logs }) {
  return (
    <div className="box-alert-information">
      <p>Firing Alerts</p>
      <div className="alerts-icon-count">
        <span className="firing-alerts-icon">
          <Bell color={"#8241B0"} size={"1.2"} />
        </span>
        <span className="firing-alerts-amount">
          {/*logs = Dashboard / apiFiring = CustomerStatus*/}
          {logs
            ? logs.length
            : customer.threshold === "all"
            ? "0"
            : apisFiring.length}
        </span>
      </div>
    </div>
  );
}
