// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Rows Per Page */

.container-pagination .rows-per-page{
    display: flex;
    align-items: center;
    column-gap: 0.8em;
    background-color: inherit;
}

.container-pagination .rows-per-page p{
    font-size: 0.85em;
}

.container-pagination .rows-per-page select{
    font-size: 0.9em;
    margin-bottom: 1rem;
    padding-right: 0.4em;
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/rowsperpage/style.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":["/* Rows Per Page */\n\n.container-pagination .rows-per-page{\n    display: flex;\n    align-items: center;\n    column-gap: 0.8em;\n    background-color: inherit;\n}\n\n.container-pagination .rows-per-page p{\n    font-size: 0.85em;\n}\n\n.container-pagination .rows-per-page select{\n    font-size: 0.9em;\n    margin-bottom: 1rem;\n    padding-right: 0.4em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
