import "./style.css";
import { isUserSensedia } from "../../utils/isUserSensedia";
import ChangeCustomerButton from "../buttons/changecustomer";
import RefreshButton from "../buttons/refresh";
import ConfigTimer from "../configtimer";
import FilterSeverity from "../filterSeverity";
import Timer from "../timer";
import BoxFiringAlerts from "./firingAlerts";
import BoxLogo from "./logo";

export default function LeftMenu({
  user,
  customer,
  logs,
  severity,
  setSeverity,
  configTime,
  setConfigTime,
  timer,
  currentDate,
  hiddenConfigTimer,
  hiddenTimer,
  getThreshold,
}) {
  return (
    <div className="left-menu-box">
      <div className={isUserSensedia(user) ? undefined : "invisible"}>
        <ChangeCustomerButton />
      </div>
      <div className="box-data-configuration">
        <BoxLogo {...{ customer }} />
        <BoxFiringAlerts {...{ customer, logs }} />
        {severity ? (
          <FilterSeverity {...{ severity, setSeverity }} />
        ) : undefined}
        <div className="refresh-config-box">
          {!hiddenConfigTimer ? (
            <ConfigTimer {...{ setConfigTime }} />
          ) : undefined}
          <RefreshButton {...{ getThreshold }} />
        </div>
        {!hiddenTimer ? (
          <Timer {...{ timer, currentDate, configTime }} />
        ) : customer.customer_id !== "all" ? (
          <Timer {...{ timer, currentDate, configTime }} />
        ) : undefined}
      </div>
    </div>
  );
}
