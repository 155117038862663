// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Settings styles */

.section-body-settings{
    width: 100%;
    max-width: 82vw;
    padding-top: 6rem;
    margin: auto;
    font-size: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.container-settings {
    font-size: 1rem;
    min-width: 980px;
    width: 94%;
    margin: 4em 0 5em;
}

.nav-settings{
    height: 5.6em;
    display: flex;
    justify-content: flex-start;
    color: #4E4E4E;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 2em;
}

.nav-settings li{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.45em;
    padding: 0 2em;

    background-color: inherit;
    font-size: 1.8em;
    cursor: pointer;
}

.nav-settings li.active{
    color: var(--primary-purple-sensedia);
    border-bottom: 2px solid var(--primary-purple-sensedia);
}

.nav-settings li .nav-settings-icon{
    position: relative;
    bottom: 0.08em;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/section/style.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,eAAe;;IAEf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,2BAA2B;IAC3B,cAAc;IACd,2CAA2C;IAC3C,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,cAAc;;IAEd,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qCAAqC;IACrC,uDAAuD;AAC3D;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":["/* Settings styles */\n\n.section-body-settings{\n    width: 100%;\n    max-width: 82vw;\n    padding-top: 6rem;\n    margin: auto;\n    font-size: 1rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.container-settings {\n    font-size: 1rem;\n    min-width: 980px;\n    width: 94%;\n    margin: 4em 0 5em;\n}\n\n.nav-settings{\n    height: 5.6em;\n    display: flex;\n    justify-content: flex-start;\n    color: #4E4E4E;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n    margin-bottom: 2em;\n}\n\n.nav-settings li{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    column-gap: 0.45em;\n    padding: 0 2em;\n\n    background-color: inherit;\n    font-size: 1.8em;\n    cursor: pointer;\n}\n\n.nav-settings li.active{\n    color: var(--primary-purple-sensedia);\n    border-bottom: 2px solid var(--primary-purple-sensedia);\n}\n\n.nav-settings li .nav-settings-icon{\n    position: relative;\n    bottom: 0.08em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
