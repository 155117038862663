import "./style.css";
import { capitalize } from "@mui/material";
// import { user_permissions } from "../../../data/constants/permissions";
import { getPermission } from "../../../utils/getPermission";
import AddIcon from "../../../assets/icons/AddIcon";

import { useContext } from "react";
import { AuthContext } from "../../../store/context/AuthContext";

export default function NewPostButton({ type, postCollapse, setPostCollapse }) {
  const { user } = useContext(AuthContext);

  return (
    <button
      onClick={() => setPostCollapse(!postCollapse)}
      className={
        user?.group?.permissions.actions.includes(
          getPermission("actions", "create")
        )
          ? "add-button primary-button"
          : "add-button primary-button disabled"
      }
    >
      <span className="add-icon">
        <AddIcon color="#FFFFFF" size={1} />
      </span>
      New {capitalize(type)}
    </button>
  );
}
