// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.put-permissions-form li{
    display: flex;
    flex-direction: column;
    row-gap: 1em;   
}

.put-permissions-form li label{
    font-size: 0.96em;
 }

.put-permissions-form li .put-input{
    display: flex;
    align-items: flex-start;
    column-gap: 0.8em;
}

.put-permissions-form li .put-input .put-icon-field{
    position: relative;
    bottom: 0.15em;
    font-size: 1.1em;
}

.put-permissions-form li .put-input .put-input-permissions-field{
    display: flex;
    flex-direction: column;
}

.put-permissions-form li .put-input input, 
.put-permissions-form li .put-input select{
    width: 100%;
    font-size: 0.9em;
    padding: 0.2em 0em 0.2em 0.5em;
    background-color: inherit;
    border: none;
    border-bottom: 1px solid var(--line-color);
}

.put-permissions-form li .put-input input[type="checkbox"]{
    width: 1em;
    margin-left: 0.5em;
    margin-bottom: 0.2em;
}

.put-permissions-form li .put-input select{
    cursor: pointer;
}

.put-permissions-form li input:focus, 
.put-permissions-form li select:focus{
    outline: none;
    border-bottom: 1px solid var(--primary-purple-sensedia);
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modalSettings/putPermissionFields/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,iBAAiB;CACpB;;AAED;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;;IAEI,WAAW;IACX,gBAAgB;IAChB,8BAA8B;IAC9B,yBAAyB;IACzB,YAAY;IACZ,0CAA0C;AAC9C;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,aAAa;IACb,uDAAuD;AAC3D","sourcesContent":[".put-permissions-form li{\n    display: flex;\n    flex-direction: column;\n    row-gap: 1em;   \n}\n\n.put-permissions-form li label{\n    font-size: 0.96em;\n }\n\n.put-permissions-form li .put-input{\n    display: flex;\n    align-items: flex-start;\n    column-gap: 0.8em;\n}\n\n.put-permissions-form li .put-input .put-icon-field{\n    position: relative;\n    bottom: 0.15em;\n    font-size: 1.1em;\n}\n\n.put-permissions-form li .put-input .put-input-permissions-field{\n    display: flex;\n    flex-direction: column;\n}\n\n.put-permissions-form li .put-input input, \n.put-permissions-form li .put-input select{\n    width: 100%;\n    font-size: 0.9em;\n    padding: 0.2em 0em 0.2em 0.5em;\n    background-color: inherit;\n    border: none;\n    border-bottom: 1px solid var(--line-color);\n}\n\n.put-permissions-form li .put-input input[type=\"checkbox\"]{\n    width: 1em;\n    margin-left: 0.5em;\n    margin-bottom: 0.2em;\n}\n\n.put-permissions-form li .put-input select{\n    cursor: pointer;\n}\n\n.put-permissions-form li input:focus, \n.put-permissions-form li select:focus{\n    outline: none;\n    border-bottom: 1px solid var(--primary-purple-sensedia);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
