import "./style.css";
import { useContext } from "react";
import { capitalize } from "@mui/material";
import { types } from "../../../data/constants/types";
import { isUserSensedia } from "../../../utils/isUserSensedia";
import { user_permissions } from "../../../data/constants/permissions";
import { AuthContext } from "../../../store/context/AuthContext";
import { SettingsContext } from "../../../store/context/SettingsContext";
import { getPermission } from "../../../utils/getPermission";

export default function InputField({
  header,
  data,
  handleOnChange,
  index,
  permission,
  isDisabled,
}) {
  const { user } = useContext(AuthContext);
  const { selectOptions } = useContext(SettingsContext);

  var dataValue =
    index !== undefined ? data[index][header?.key] : data[header?.key];

  if (permission) {
    return user_permissions[header].map((key) =>
      !isUserSensedia(user) &&
      header === types.permissions.settings_menu &&
      (key.permission === getPermission("settings_menu", "role") ||
        key.permission ===
          getPermission("settings_menu", "organization")) ? undefined : (
        <div key={key.permission} className="checkbox-item">
          <input
            type="checkbox"
            name={key.name}
            id={key.permission}
            checked={data["permissions"][header]?.includes(
              getPermission(header, key.name)
            )}
            onChange={(e) => handleOnChange(e, header)}
            required
            disabled={header !== "pages" ? isDisabled : false}
          />
          <div>{capitalize(key.title)}</div>
        </div>
      )
    );
  }

  if (header?.inputType === "select") {
    return (
      <>
        <select
          name={header?.key}
          id={header?.key}
          value={dataValue?._id ?? dataValue ?? ""}
          onChange={(e) => handleOnChange(e, index)}
          required
        >
          {selectOptions[header?.selectName]?.map((option) => (
            <option key={option._id} value={option._id}>
              {option["name"]}
            </option>
          ))}
        </select>
      </>
    );
  }

  if (header?.inputType === "checkbox") {
    return (
      <div className="container-checkbox">
        {header?.key !== "allowed_roles" ? (
          <div className="checkbox-item">
            <input
              type={header?.inputType}
              name={header?.key}
              id={header?.key}
              checked={dataValue}
              onChange={(e) => handleOnChange(e, index)}
              required
            />
            <div key={header?.key}>{capitalize(header?.key)}</div>
          </div>
        ) : (
          selectOptions.roles?.map(
            (role) =>
              typeof dataValue === "object" && (
                <div key={role._id} className="checkbox-item">
                  {role._id && (
                    <>
                      <input
                        type={header?.inputType}
                        name={role?.name}
                        id={role?._id}
                        checked={dataValue?.includes(role._id)}
                        onChange={(e) => handleOnChange(e, index)}
                        required
                      />
                      <div>{capitalize(role?.name)}</div>
                    </>
                  )}
                </div>
              )
          )
        )}
      </div>
    );
  }

  return (
    <input
      type={header?.inputType}
      name={header?.key}
      id={header?.key}
      value={dataValue}
      onChange={(e) => handleOnChange(e, index)}
      maxLength={header?.maxLength}
      required
    />
  );
}
