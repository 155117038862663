// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Configuration Menu Styles */

.container-configuration{
    padding: 2em 4em 1.6em 3.8em;
    color: #4E4E4E;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-configuration .container-actions{
    display: flex;
    column-gap: 0.6em;
}

.ReactCollapse--collapse{
    transition: height 500ms;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/configuration/style.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,4BAA4B;IAC5B,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["/* Configuration Menu Styles */\n\n.container-configuration{\n    padding: 2em 4em 1.6em 3.8em;\n    color: #4E4E4E;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.container-configuration .container-actions{\n    display: flex;\n    column-gap: 0.6em;\n}\n\n.ReactCollapse--collapse{\n    transition: height 500ms;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
