import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IconContext } from "react-icons";

export default function TogglePasswordIcon({ showIcon, color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      {showIcon ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
    </IconContext.Provider>
  );
}
