// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-login .container-wrap-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3.5em;
    margin-bottom: 3.2em;
}

.form-login .container-remember-forgot{
    font-size: 1.35em;
    padding: 0 0.4em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6em;
    color: var(--grey);
}

.form-login .container-remember-forgot .remember-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.6em;
}

.form-login .container-remember-forgot .remember-wrap input{
    padding: 1em;
}

.form-login .container-remember-forgot .txt-forgot{
    color: #6a7dfe;
}

.form-login .container-remember-forgot a{
    text-decoration: none;
    color: var(--grey-light);
}

.form-login .register-text{
    font-size: 1.35em;
    padding-top: 1.4em;
    letter-spacing: 0.03em;
    color: var(--grey);
}

.form-login .register-text a{
    text-decoration: none;
    color: #6a7dfe;
}`, "",{"version":3,"sources":["webpack://./src/pages/authentication/pages/login/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".form-login .container-wrap-input{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 3.5em;\n    margin-bottom: 3.2em;\n}\n\n.form-login .container-remember-forgot{\n    font-size: 1.35em;\n    padding: 0 0.4em;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 1.6em;\n    color: var(--grey);\n}\n\n.form-login .container-remember-forgot .remember-wrap{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    column-gap: 0.6em;\n}\n\n.form-login .container-remember-forgot .remember-wrap input{\n    padding: 1em;\n}\n\n.form-login .container-remember-forgot .txt-forgot{\n    color: #6a7dfe;\n}\n\n.form-login .container-remember-forgot a{\n    text-decoration: none;\n    color: var(--grey-light);\n}\n\n.form-login .register-text{\n    font-size: 1.35em;\n    padding-top: 1.4em;\n    letter-spacing: 0.03em;\n    color: var(--grey);\n}\n\n.form-login .register-text a{\n    text-decoration: none;\n    color: #6a7dfe;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
