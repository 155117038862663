import "./style.css";
import { types } from "../../../../data/constants/types";
import { headers } from "../../../../data/constants/headers";
import { isUserSensedia } from "../../../../utils/isUserSensedia";
import useHandlePostInfo from "../../hooks/useHandlePostItem";
import AddPostButton from "../../../../components/buttons/addPost";
import SendPostButton from "../../../../components/buttons/sendpost";
import ClosePostButton from "../../../../components/buttons/closepost";
import InputField from "../../input";

import { useContext } from "react";
import { AuthContext } from "../../../../store/context/AuthContext";

export default function PostItem({ type, postCollapse, setPostCollapse }) {
  const { user } = useContext(AuthContext);

  const [
    postItem,
    selectOptionsLoading,
    handleAddRowItem,
    handleRemoveRowItem,
    handleOnChangeItem,
    handleOnChangeAllowedRoles,
    handlePostItem,
  ] = useHandlePostInfo(type, setPostCollapse);

  const allHeaders = headers.find((info) => info.type === type).headers;

  if (selectOptionsLoading) {
    return undefined;
  }

  return (
    <div className="container-post-info">
      {postItem.map((item, index) => (
        <div key={index} className="input-form">
          <div className="add-button-form">
            <AddPostButton {...{ index, postItem, handleAddRowItem }} />
          </div>
          {allHeaders.map((header) => {
            if (header.post) {
              if (header.onlySensedia && !isUserSensedia(user)) {
                return null;
              }

              var isDisabled =
                type === types.settings_menu.group &&
                header.key === "organization" &&
                item.isDefaultGroup;

              const content = (
                <div
                  key={header.key}
                  className={isDisabled ? "post-input disabled" : "post-input"}
                  style={{ width: header.postWidth }}
                >
                  <label htmlFor={header.key}>{header.label}</label>
                  <div className="post-input-field">
                    <InputField
                      {...{ header, index }}
                      data={postItem}
                      handleOnChange={
                        header?.key !== "allowed_roles"
                          ? handleOnChangeItem
                          : handleOnChangeAllowedRoles
                      }
                    />
                  </div>
                </div>
              );

              return content;
            }

            return null;
          })}
          <div className="send-button-form">
            <SendPostButton {...{ type, index, item, handlePostItem }} />
          </div>
          <div className="close-button-form">
            <ClosePostButton
              {...{
                index,
                postCollapse,
                setPostCollapse,
                handleRemoveRowItem,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
