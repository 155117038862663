// Images
import logo_support_premium from "../../assets/images/support-premium-logo.png";
import logo_ailos from "../../assets/images/ailos-logo.png";
import logo_alelo from "../../assets/images/alelo-logo.png";
import logo_ancar from "../../assets/images/ancar-logo.png";
import logo_arcoeducacao from "../../assets/images/arco-logo.png";
import logo_banestes from "../../assets/images/banestes-logo.png";
import logo_bnb from "../../assets/images/bnb-logo.png";
import logo_bndes from "../../assets/images/bndes-logo.png";
import logo_cielo from "../../assets/images/cielo-logo.png";
import logo_cnseg from "../../assets/images/cnseg-logo.png";
import logo_cea from "../../assets/images/cea-logo.png";
import logo_davita from "../../assets/images/davita-logo.png";
import logo_embracon from "../../assets/images/embracon-logo.png";
import logo_energisa from "../../assets/images/energisa-logo.png";
import logo_essilor from "../../assets/images/essilor-logo.png";
import logo_financiera_oh from "../../assets/images/financiera_oh-logo.png";
import logo_localiza from "../../assets/images/localiza-logo.png";
import logo_natura from "../../assets/images/natura-logo.png";
import logo_pagseguro from "../../assets/images/pagseguro-logo.png";
import logo_portofinance from "../../assets/images/porto-finance.png";
import logo_portoseg from "../../assets/images/portoseg-logo.png";
// import logo_sascar from "../../assets/images/sascar-logo.png";
import logo_sicoob from "../../assets/images/sicoob-logo.png";
import logo_sicredi from "../../assets/images/sicredi-logo.png";
import logo_stone from "../../assets/images/stone-logo.png";
import logo_sulamerica from "../../assets/images/sulamerica-logo.png";
import logo_taesa from "../../assets/images/taesa-logo.png";
// import logo_topazio from "../../assets/images/topazio-logo.png";
import logo_tribanco from "../../assets/images/tribanco-logo.png";
import logo_unicred from "../../assets/images/unicred-logo.png";
import logo_vrbeneficios from "../../assets/images/vrbeneficios-logo.png";

//valores de width e height em 'em' (necessario para responsividade)
export const customer_info = [
  {
    logo: logo_support_premium,
    width_mainLogo: 4.8,
    width_changeButton: 4.8,
    width_checkCard: 2.6,
    href: "sensedia",
    title: "Sensedia Support Premium",
    alt: "Logo Support Premium",
    customer_id: "all",
    // authHeader: "YWxsX2hhc2g6MTY2NzMwNDQ0NC1hbGxfaGFzaC00NDYx",
    authHeader: "YWxsX2hhc2g6MTcyNTQ4MDAxMC1hbGxfaGFzaC02NTg5",
  },
  {
    logo: logo_ailos,
    width_mainLogo: 6,
    width_changeButton: 8,
    height_table: 2,
    width_statusPage: 22,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "ailos_ob",
    title: "Ailos",
    alt: "Logo Ailos",
    customer_id: "0zuxteujtw",
    authHeader: "YWlsb3NfaGFzaDoxNzI1NTQ1ODU4LWFpbG9zX2hhc2gtODYzMA==",
  },
  {
    logo: logo_alelo,
    width_mainLogo: 6,
    width_changeButton: 7,
    height_table: 2.4,
    width_statusPage: 21,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "alelo",
    title: "Alelo",
    alt: "Logo Alelo",
    customer_id: "mx46v66lzo",
    authHeader: "YWxlbG9faGFzaDoxNzI1NTQ4NjQzLWFsZWxvX2hhc2gtNjk1Mg==",
  },
  {
    logo: logo_ancar,
    width_mainLogo: 6.4,
    width_changeButton: 8.4,
    height_table: 1.9,
    width_statusPage: 23,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "ancar",
    title: "Ancar",
    alt: "Logo Ancar",
    customer_id: "4ze0fey9re",
    authHeader: "YW5jYXJfaGFzaDoxNzI1NDgwMTEyLWFuY2FyX2hhc2gtNTQzMA==",
  },
  {
    logo: logo_arcoeducacao,
    width_mainLogo: 5.2,
    width_changeButton: 6.4,
    height_table: 2,
    width_statusPage: 18.5,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "arco_educacao",
    title: "Arco Educação",
    alt: "Logo Arco Educação",
    customer_id: "i782g647lt",
    authHeader: "YXJjb19lZHVjYWNhb19oYXNoOjE3MjU0ODAxMjgtYXJjb19lZHVjYWNhb19oYXNoLTY4NTU=",
  },
  {
    logo: logo_banestes,
    width_mainLogo: 6.4,
    width_changeButton: 7.8,
    height_table: 2,
    width_statusPage: 22,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "banestes",
    title: "Banestes",
    alt: "Logo Banestes",
    customer_id: "fb63noe0qu",
    authHeader: "YmFuZXN0ZXNfaGFzaDoxNzI1NTQ4NjQzLWJhbmVzdGVzX2hhc2gtMDgzOQ==",
  },
  {
    logo: logo_bnb,
    width_mainLogo: 6.2,
    width_changeButton: 8,
    height_table: 2,
    width_statusPage: 22,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "bnb_ob",
    title: "Banco do Nordeste",
    alt: "Logo Banco do Nordeste",
    customer_id: "xqox4xjshy",
    authHeader: "Ym5iX29iX2hhc2g6MTcyNTQ4MDE5OC1ibmJfb2JfaGFzaC0wNTI4",
  },
  {
    logo: logo_bndes,
    width_mainLogo: 6.2,
    width_changeButton: 8,
    height_table: 1.25,
    width_statusPage: 22,
    width_healthstatus: 13,
    width_checkCard: 5.4,
    href: "bndes",
    title: "BNDES",
    alt: "Logo BNDES",
    customer_id: "cjs2r2y76s",
    authHeader: "Ym5kZXNfaGFzaDoxNzI1NDgwMjE3LWJuZGVzX2hhc2gtNTMzMg==",
  },
  {
    logo: logo_cielo,
    width_mainLogo: 6,
    width_changeButton: 7.4,
    height_table: 1.8,
    width_statusPage: 20,
    width_healthstatus: 10,
    width_checkCard: 4.6,
    href: "cielo",
    title: "Cielo",
    alt: "Logo Cielo",
    customer_id: "4rhtp3iinv",
    authHeader: "Y2llbG9faGFzaDoxNzI1NDgwMzUwLWNpZWxvX2hhc2gtODE2Mw==",
  },
  {
    logo: logo_cnseg,
    width_mainLogo: 6,
    width_changeButton: 7.2,
    height_table: 2.2,
    width_statusPage: 21,
    width_healthstatus: 10,
    width_checkCard: 4.6,
    href: "cnseg",
    title: "CNSEG",
    alt: "Logo CNSEG",
    customer_id: "fbcyprd9k3",
    authHeader: "Y25zZWdfaGFzaDoxNzI1NDgwMzcwLWNuc2VnX2hhc2gtNzQ0OQ==",
  },
  {
    logo: logo_cea,
    width_mainLogo: 5.2,
    width_changeButton: 5.4,
    height_table: 2.6,
    width_statusPage: 18.5,
    width_healthstatus: 7,
    width_checkCard: 3.4,
    href: "c&a",
    title: "C&A",
    alt: "Logo C&A",
    customer_id: "zv53d0mwsj",
    authHeader: "Y2VhX2hhc2g6MTcyNTQ4MDM1MC1jZWFfaGFzaC00Njkz",
  },
  {
    logo: logo_davita,
    width_mainLogo: 5.6,
    width_changeButton: 6.2,
    height_table: 2.1,
    width_statusPage: 21,
    width_healthstatus: 7,
    width_checkCard: 3.6,
    href: "davita",
    title: "Davita",
    alt: "Logo Davita",
    customer_id: "3scju6ubxe",
    authHeader: "ZGF2aXRhX2hhc2g6MTcyNTQ4MDM5Ni1kYXZpdGFfaGFzaC0xOTU1",
  },
  {
    logo: logo_support_premium,
    width_mainLogo: 4.8,
    width_changeButton: 4.8,
    height_table: 2.4,
    width_statusPage: 16,
    width_healthstatus: 7,
    width_checkCard: 2.6,
    href: "demo",
    title: "Demo",
    alt: "Logo Support Premium",
    customer_id: "vsm1zc4h3c",
    authHeader: "ZGVtb19oYXNoOjE3MjU0ODA0MDktZGVtb19oYXNoLTE2NTM=",
  },
  {
    logo: logo_embracon,
    width_mainLogo: 6,
    width_changeButton: 8,
    height_table: 1.3,
    width_statusPage: 23.5,
    width_healthstatus: 7,
    width_checkCard: 3.6,
    href: "embracon",
    title: "Embracon",
    alt: "Logo Embracon",
    customer_id: "t3ozr00z9v",
    authHeader: "ZW1icmFjb25faGFzaDoxNzI1NDgwNTQ3LWVtYnJhY29uX2hhc2gtOTg3Mg==",
  },
  {
    logo: logo_energisa,
    width_mainLogo: 6.4,
    width_changeButton: 8.2,
    height_table: 2.3,
    width_statusPage: 23,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "energisa",
    title: "Energisa",
    alt: "Logo Energisa",
    customer_id: "v8pclthpws",
    authHeader: "ZW5lcmdpc2FfaGFzaDoxNzI1NDgwNTYyLWVuZXJnaXNhX2hhc2gtNDgzNQ==",
  },
  {
    logo: logo_essilor,
    width_mainLogo: 4.8,
    width_changeButton: 4.8,
    height_table: 3,
    width_statusPage: 17,
    width_healthstatus: 7.4,
    width_checkCard: 3,
    href: "essilor",
    title: "Essilor",
    alt: "Logo Essilor",
    customer_id: "6dx8yccglh",
    authHeader: "ZXNzaWxvcl9oYXNoOjE3MjU0ODA2MjktZXNzaWxvcl9oYXNoLTMzNDY=",
  },
  {
    logo: logo_financiera_oh,
    width_mainLogo: 6,
    width_changeButton: 8.4,
    height_table: 1,
    width_statusPage: 23.5,
    width_healthstatus: 7.4,
    width_checkCard: 3,
    href: "financiera_oh",
    title: "Financiera oh",
    alt: "Logo Financiera oh",
    customer_id: "3398fe19o3",
    authHeader: "ZmluYW5jaWVyYV9oYXNoOjE3MjU1NDg2NDMtZmluYW5jaWVyYV9oYXNoLTAxMDg=",
  },
  {
    logo: logo_localiza,
    width_mainLogo: 6,
    width_changeButton: 8.4,
    height_table: 1.3,
    width_statusPage: 23,
    width_healthstatus: 7.4,
    width_checkCard: 3,
    href: "localiza",
    title: "Localiza",
    alt: "Logo Localiza",
    customer_id: "uzipmwhsfr",
    authHeader: "bG9jYWxpemFfaGFzaDoxNzI1NDgwODE3LWxvY2FsaXphX2hhc2gtNjcxMA==",
  },
  {
    logo: logo_natura,
    width_mainLogo: 5.2,
    width_changeButton: 5.4,
    height_table: 2.7,
    width_statusPage: 18,
    width_healthstatus: 8.6,
    width_checkCard: 3.4,
    href: "natura",
    title: "Natura",
    alt: "Logo Natura",
    customer_id: "l5qfd6kn6p",
    authHeader: "bmF0dXJhX2hhc2g6MTcyNTQ4MDgyNi1uYXR1cmFfaGFzaC04NTMz",
  },
  {
    logo: logo_pagseguro,
    width_mainLogo: 6.4,
    width_changeButton: 8.4,
    height_table: 1.7,
    width_statusPage: 23,
    width_healthstatus: 8.6,
    width_checkCard: 3.4,
    href: "pagseguro",
    title: "Pagseguro",
    alt: "Logo Pagseguro",
    customer_id: "6d5v5i163z",
    authHeader: "cGFnc2VnX2hhc2g6MTcyNTU0ODY0My1wYWdzZWdfaGFzaC01MzQz",
  },
  {
    logo: logo_portofinance,
    width_mainLogo: 6.2,
    width_changeButton: 8.2,
    height_table: 1.45,
    width_statusPage: 23,
    width_healthstatus: 8.6,
    width_checkCard: 3.4,
    href: "portofinance",
    title: "Porto Finance",
    alt: "Logo Porto Finance",
    customer_id: "3ul2fwd8us",
    authHeader: "cG9ydG9maW5hbmNlX2hhc2g6MTcyNTQ4MDg1MC1wb3J0b2ZpbmFuY2VfaGFzaC0zMzQw",
  },
  {
    logo: logo_portoseg,
    width_mainLogo: 6,
    width_changeButton: 7,
    height_table: 1.8,
    width_statusPage: 21.6,
    width_healthstatus: 9.8,
    width_checkCard: 5.2,
    href: "portoseg",
    title: "Porto Seguro",
    alt: "Logo Porto Seguro",
    customer_id: "epig4qnule",
    authHeader: "cG9ydG9zZWdfaGFzaDoxNzI1NDgwODUwLXBvcnRvc2VnX2hhc2gtOTA1OQ==",
  },
  // {
  //   logo: logo_sascar,
  //   width_mainLogo: 7,
  //   width_changeButton: 8.4,
  //   height_table: 2.8,
  //   width_statusPage: 24,
  //   width_healthstatus: 10,
  //   width_checkCard: 5.4,
  //   href: "sascar",
  //   title: "Sascar",
  //   alt: "Logo Sascar",
  //   customer_id: "pj6rpuwfob",
  //   authHeader: "c2FzY2FyX2hhc2g6MTcyNTQ4MDkxMy1zYXNjYXJfaGFzaC0zODIy",
  // },
  {
    logo: logo_sicoob,
    width_mainLogo: 5.8,
    width_changeButton: 8,
    height_table: 1.3,
    width_statusPage: 22,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "sicoob",
    title: "Sicoob",
    alt: "Logo Sicoob",
    customer_id: "osja7gtbbu",
    authHeader: "b3BlbmZpbmFuY2VfaGFzaDoxNzI3ODA0NjM1LW9wZW5maW5hbmNlX2hhc2gtMzE1OQ==",
  },
  {
    logo: logo_sicredi,
    width_mainLogo: 6,
    width_changeButton: 8,
    height_table: 1.3,
    width_statusPage: 22.4,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "sicredi",
    title: "Sicredi",
    alt: "Logo Sicredi",
    customer_id: "diofs8e0pb",
    authHeader: "c2ljcmVkaV9oYXNoOjE3MjU0ODA5MTMtc2ljcmVkaV9oYXNoLTg4MDc=",
  },
  {
    logo: logo_stone,
    width_mainLogo: 5.4,
    width_changeButton: 7.2,
    height_table: 1.5,
    width_statusPage: 21.4,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "stone",
    title: "Stone",
    alt: "Logo Stone",
    customer_id: "2907aoms12",
    authHeader: "c3RvbmVfaGFzaDoxNzI1NDgwOTEzLXN0b25lX2hhc2gtNzcwOA==",
  },
  {
    logo: logo_sulamerica,
    width_mainLogo: 5.8,
    width_changeButton: 8,
    height_table: 1.5,
    width_statusPage: 22,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "sulamerica",
    title: "Sulamerica",
    alt: "Logo Sulamerica",
    customer_id: "70xvxwmdpk",
    authHeader: "c3VsYW1lcmljYV9oYXNoOjE3MjU0ODA5MzQtc3VsYW1lcmljYV9oYXNoLTIwODc=",
  },
  {
    logo: logo_taesa,
    width_mainLogo: 5.8,
    width_changeButton: 7.4,
    height_table: 1.8,
    width_statusPage: 21.4,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "taesa",
    title: "Taesa",
    alt: "Logo Taesa",
    customer_id: "dgiy9t9hux",
    authHeader: "dGFlc2FfaGFzaDoxNzI1NTQ4NjQzLXRhZXNhX2hhc2gtMDYxNA==",
  },
  // {
  //   logo: logo_topazio,
  //   width_mainLogo: 7,
  //   width_changeButton: 8,
  //   height_table: 2,
  //   width_statusPage: 24,
  //   width_healthstatus: 11,
  //   width_checkCard: 5,
  //   href: "topazio",
  //   title: "Banco Topazio",
  //   alt: "Logo Banco Topazio",
  //   customer_id: "3uy0cmex4i",
  //   authHeader: "dG9wYXppb19oYXNoOjE3MjU0ODA5MzQtdG9wYXppb19oYXNoLTg5NTA=",
  // },
  {
    logo: logo_tribanco,
    width_mainLogo: 5.8,
    width_changeButton: 7.4,
    height_table: 1.8,
    width_statusPage: 21,
    width_healthstatus: 10,
    width_checkCard: 5.4,
    href: "tribanco",
    title: "Tribanco",
    alt: "Logo Tribanco",
    customer_id: "43czdyw0nf",
    authHeader: "dHJpYmFuY29zYWFzX2hhc2g6MTcyOTU5OTUxMC10cmliYW5jb3NhYXNfaGFzaC0zNDkw",
  },
  {
    logo: logo_unicred,
    width_mainLogo: 6.2,
    width_changeButton: 8.2,
    height_table: 1.3,
    width_statusPage: 23,
    width_healthstatus: 12,
    width_checkCard: 5.6,
    href: "unicred",
    title: "Unicred",
    alt: "Logo Unicred",
    customer_id: "mf04iucpm1",
    authHeader: "dW5pY3JlZF9oYXNoOjE3MjU0ODA5MzQtdW5pY3JlZF9oYXNoLTI3MzM=",
  },
  {
    logo: logo_vrbeneficios,
    width_mainLogo: 7.2,
    width_changeButton: 8.4,
    height_table: 1.3,
    width_statusPage: 24.4,
    width_healthstatus: 12,
    width_checkCard: 5.6,
    href: "vr_beneficios",
    title: "VR Benefícios",
    alt: "Logo VR Benefícios",
    customer_id: "4yf82a4ani",
    authHeader: "dnJfYmVuZWZpY2lvc19oYXNoOjE3MjU0ODA5MzQtdnJfYmVuZWZpY2lvc19oYXNoLTI2NTk=",
  },
];

//labels_validation_dashboard
export const labels_validation_dashboard = [
  "id",
  "name",
  "alertname",
  "customer_name",
  "customer_id",
  "severity",
  "type",
];

//labels_validation_dashboard
export const labels_validation_healthstatus = [
  "customer_name",
  "customer_id",
  "unavailability",
];

//clientes que serão apresentados no DashBoard
export const support_premium_customers = [
  { customer_name: "ailos_ob", customer_id: "0zuxteujtw" },
  { customer_name: "alelo", customer_id: "mx46v66lzo" },
  { customer_name: "ancar", customer_id: "4ze0fey9re" },
  { customer_name: "arco_educacao", customer_id: "i782g647lt" },
  { customer_name: "banestes", customer_id: "fb63noe0qu" },
  { customer_name: "bnb_ob", customer_id: "xqox4xjshy" },
  { customer_name: "bndes", customer_id: "cjs2r2y76s" },
  { customer_name: "cielo", customer_id: "4rhtp3iinv" },
  { customer_name: "cnseg", customer_id: "fbcyprd9k3" },
  { customer_name: "c&a", customer_id: "zv53d0mwsj" },
  { customer_name: "davita", customer_id: "3scju6ubxe" },
  { customer_name: "demo", customer_id: "vsm1zc4h3c" },
  { customer_name: "embracon", customer_id: "t3ozr00z9v" },
  { customer_name: "energisa", customer_id: "v8pclthpws" },
  { customer_name: "essilor", customer_id: "6dx8yccglh" },
  { customer_name: "financiera_oh", customer_id: "3398fe19o3" },
  { customer_name: "localiza", customer_id: "uzipmwhsfr" },
  { customer_name: "natura", customer_id: "l5qfd6kn6p" },
  { customer_name: "pagseguro", customer_id: "6d5v5i163z" },
  { customer_name: "portofinance", customer_id: "3ul2fwd8us" },
  { customer_name: "portoseg", customer_id: "epig4qnule" },
  // { customer_name: "sascar", customer_id: "pj6rpuwfob" },
  { customer_name: "sicoob", customer_id: "osja7gtbbu" },
  { customer_name: "sicredi", customer_id: "diofs8e0pb" },
  { customer_name: "stone", customer_id: "2907aoms12" },
  { customer_name: "sulamerica", customer_id: "70xvxwmdpk" },
  { customer_name: "taesa", customer_id: "dgiy9t9hux" },
  // { customer_name: "topazio", customer_id: "3uy0cmex4i" },
  { customer_name: "tribanco", customer_id: "43czdyw0nf" },
  { customer_name: "unicred", customer_id: "mf04iucpm1" },
  { customer_name: "vr_beneficios", customer_id: "4yf82a4ani" },
];

export const select_clients = [
  "sensedia",
  ...support_premium_customers.map(
    (customer) => customer.customer_name
  ),
];
