// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Close Button */

.close-button{
    width: 1.6em;
    height: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    color: var(--grey)!important;
    background-color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/closepost/style.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,4BAA4B;IAC5B,yBAAyB;AAC7B","sourcesContent":["/* Close Button */\n\n.close-button{\n    width: 1.6em;\n    height: 1.6em;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    border: none;\n    color: var(--grey)!important;\n    background-color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
