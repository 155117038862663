// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-login .forgot-p{
    font-size: 1.4em;
    margin-bottom: 3em;
    color: #ADADAD;
}

.form-login .required-email{
    width: 100%;
    height: 0;
    font-size: 1.15em;
    padding-left: 0.5em;
    color: red;
    position: relative;
    bottom: 2em;
}

/* Email Sent */

.container-email-sent{
    width: 120%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    
    color: var(--grey-lighter);
}

.container-email-sent h2{
    font-size: 2em;
    margin-bottom: 1em;
}

.container-email-sent img{
    margin-bottom: 2em;
}

.container-email-sent p{
    font-size: 1.2em;
}

.container-email-sent .try-again-forgot{
    color: var(--tech-blue-sensedia);
    margin-bottom: 5em;
}

.container-email-sent .try-again-forgot:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/authentication/pages/forgotPassword/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,SAAS;IACT,iBAAiB;IACjB,mBAAmB;IACnB,UAAU;IACV,kBAAkB;IAClB,WAAW;AACf;;AAEA,eAAe;;AAEf;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;;IAElB,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".form-login .forgot-p{\n    font-size: 1.4em;\n    margin-bottom: 3em;\n    color: #ADADAD;\n}\n\n.form-login .required-email{\n    width: 100%;\n    height: 0;\n    font-size: 1.15em;\n    padding-left: 0.5em;\n    color: red;\n    position: relative;\n    bottom: 2em;\n}\n\n/* Email Sent */\n\n.container-email-sent{\n    width: 120%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 1em;\n    \n    color: var(--grey-lighter);\n}\n\n.container-email-sent h2{\n    font-size: 2em;\n    margin-bottom: 1em;\n}\n\n.container-email-sent img{\n    margin-bottom: 2em;\n}\n\n.container-email-sent p{\n    font-size: 1.2em;\n}\n\n.container-email-sent .try-again-forgot{\n    color: var(--tech-blue-sensedia);\n    margin-bottom: 5em;\n}\n\n.container-email-sent .try-again-forgot:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
