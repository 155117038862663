import "./style.css";
import { Link } from "react-router-dom";
import AuthSection from "../..";
import LoginLogo from "../../logo";
import LoginButton from "../../../../components/buttons/login";
import InputText from "../../input/InputText";
import InputPassword from "../../input/InputPassword";

import { useContext } from "react";
import { AuthContext } from "../../../../store/context/AuthContext";

export default function LoginForm() {
  const {
    userNameOrEmail,
    setUserNameOrEmail,
    password,
    setPassword,
    rememberMe,
    handleRememberMe,
    handleLogin,
  } = useContext(AuthContext);

  return (
    <AuthSection>
      <LoginLogo />
      <div className="container-wrap-input show-password">
        <InputText
          type="text"
          info={userNameOrEmail}
          setInfo={setUserNameOrEmail}
          placeHolder={"Username or Email"}
          handleSubmit={handleLogin}
        />
        <InputPassword
          info={password}
          setInfo={setPassword}
          placeHolder={"Password"}
          handleSubmit={handleLogin}
        />
      </div>

      <div className="container-remember-forgot">
        <div className="remember-wrap">
          <input
            type="checkbox"
            name="rememberMe"
            checked={rememberMe}
            onChange={handleRememberMe}
          />
          <span className="txt-remember">Remember me</span>
        </div>
        <Link to={"/forgot-password"}>
          <div className="txt-forgot">Forgot Password?</div>
        </Link>
      </div>

      <LoginButton text={"Login"} submit={handleLogin} />

      <span className="register-text">
        Don't have an account yet? <Link to="/register">Sign up</Link>
      </span>
    </AuthSection>
  );
}
