import "../style.css";
import { Link } from "react-router-dom";
import SectionException from "..";

import { useContext } from "react";
import { AuthContext } from "../../../store/context/AuthContext";

export default function PageNotFound() {
  const { authenticated } = useContext(AuthContext);

  return (
    <SectionException
      title={"404 - Page Not Found"}
      p={
        "We're sorry, the page you entered may be broken or the page may have been removed."
      }
    >
      {authenticated ? (
        <Link to="/">
          <button className="exception-btn">GO TO DASHBOARD</button>
        </Link>
      ) : undefined}
    </SectionException>
  );
}
