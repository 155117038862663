import "./style.css";
import { useState } from "react";
import { Collapse } from "react-collapse";
import FilterSettings from "./filter";
import NewPostButton from "../../../components/buttons/newPost";
import RefreshSettingsButton from "../../../components/buttons/refreshSettings";
import PostItem from "./post";

export default function ConfigurationSettings({
  type,
  data,
  tryToGetData,
  search,
  setSearch,
  setLoading,
  checkedInputValue,
}) {
  const [postCollapse, setPostCollapse] = useState(false);

  function handleRefresh() {
    setLoading(true);
    tryToGetData();
  }

  return (
    <>
      <div className="container-configuration">
        <FilterSettings
          {...{ type, data, search, setSearch, checkedInputValue }}
        />
        <div className="container-actions">
          <NewPostButton {...{ type, postCollapse, setPostCollapse }} />
          <RefreshSettingsButton {...{ handleRefresh }} />
        </div>
      </div>

      <Collapse isOpened={postCollapse}>
        <PostItem {...{ type, postCollapse, setPostCollapse }} />
      </Collapse>
    </>
  );
}
