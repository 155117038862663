// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-body-statuspage{
    width: 100%;
    max-width: 82vw;
    padding-top: 6rem;
    margin: auto;
    font-size: 1rem;
}

.statuspage-customer{
    position: relative;
    margin: 2em auto 0;
    width: 100%;
    padding-bottom: 5em;
    display: grid;
    grid-template-columns: 5fr 20fr;
    grid-column-gap: 3rem;
    font-size: 1rem;
}

.container-loading-icon{
    display: flex;
    justify-content: center;
    align-items: center; 
    padding-bottom: 30em;
}

.centering-client{
    position: relative;
    right: 3em;
}

/* Responsividade para distância do navbar e os diferentes section */

@media screen and (min-width:2200px){
    .section-body-statuspage{
        padding-top: 8.2rem;
    }
}

@media screen and (min-width:1921px) and (max-width: 2200px){
    .section-body-statuspage{
        padding-top: 7.4rem;
    }
}

`, "",{"version":3,"sources":["webpack://./src/pages/customerstatus/section/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,+BAA+B;IAC/B,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA,oEAAoE;;AAEpE;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".section-body-statuspage{\n    width: 100%;\n    max-width: 82vw;\n    padding-top: 6rem;\n    margin: auto;\n    font-size: 1rem;\n}\n\n.statuspage-customer{\n    position: relative;\n    margin: 2em auto 0;\n    width: 100%;\n    padding-bottom: 5em;\n    display: grid;\n    grid-template-columns: 5fr 20fr;\n    grid-column-gap: 3rem;\n    font-size: 1rem;\n}\n\n.container-loading-icon{\n    display: flex;\n    justify-content: center;\n    align-items: center; \n    padding-bottom: 30em;\n}\n\n.centering-client{\n    position: relative;\n    right: 3em;\n}\n\n/* Responsividade para distância do navbar e os diferentes section */\n\n@media screen and (min-width:2200px){\n    .section-body-statuspage{\n        padding-top: 8.2rem;\n    }\n}\n\n@media screen and (min-width:1921px) and (max-width: 2200px){\n    .section-body-statuspage{\n        padding-top: 7.4rem;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
