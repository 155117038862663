import "./style.css";
import Toast from "../../components/toastNotification";
import logo_sensedia_white from "../../assets/images/sensedia-logo-white-tm.png";

import { useContext, useEffect } from "react";
import { AuthContext } from "../../store/context/AuthContext";

const AuthSection = ({ children }) => {
  const {
    setUserNameOrEmail,
    setName,
    setEmail,
    setPassword,
    setEmailSent,
    setRequiredField,
  } = useContext(AuthContext);

  useEffect(() => {
    setUserNameOrEmail("");
    setName("");
    setEmail("");
    setPassword("");
    setEmailSent(false);
    setRequiredField(false);
  }, [
    setUserNameOrEmail,
    setName,
    setEmail,
    setPassword,
    setEmailSent,
    setRequiredField,
  ]);

  return (
    <div className="section-login">
      <div className="container-login">
        <form className="form-login">{children}</form>
      </div>
      <footer>
        <img
          className="footer-img"
          src={logo_sensedia_white}
          alt="Logo Sensedia"
        />
        <span className="footer-text"></span>
      </footer>
      <Toast />
    </div>
  );
};

export default AuthSection;
