import "./style.css";
import Noresultfound from "../../assets/images/noresultsfound.svg";

export default function NoResultsFound({ size }) {
  return (
    <div className="container-noresult">
      <img
        className="img-noresultfound"
        src={Noresultfound}
        alt="No Result Found"
        style={{ width: `${size}em` }}
      />
      <h2 className="noresultfound-p" style={{ fontSize: `${size / 14}em` }}>
        No Results Found
      </h2>
    </div>
  );
}
