import React, { createContext } from "react";
import useSettings from "./hooks/useSettings";

const SettingsContext = createContext();

function SettingsProvider({ children }) {
  const type = children?.props?.type;
  const {
    data,
    setData,
    updateData,
    loading,
    setLoading,
    selectOptions,
    selectOptionsLoading,
    tryToGetData,
    adminRegisterItem,
    updateItemById,
    deleteItemById,
    deleteAllItemsSelected,
  } = useSettings(type);

  return (
    <SettingsContext.Provider
      value={{
        data,
        setData,
        updateData,
        loading,
        setLoading,
        selectOptions,
        selectOptionsLoading,
        tryToGetData,
        adminRegisterItem,
        updateItemById,
        deleteItemById,
        deleteAllItemsSelected,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider };
