// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Filter Styles */

.container-filter-settings .filter-form{
    display: flex;
    align-items: flex-end;
    column-gap: 1em;
}

.container-filter-settings .filter-form input[type=search]{
    width: 18em;
    border: none;
    outline: 0;
    padding: 0 0.6em;
    font-size: 0.9em;
    background: inherit;
    border-bottom: 1px solid var(--line-color-light);
}

.container-filter-settings .filter-form input[type=search]::-webkit-search-cancel-button{
    margin-left: 1em;
    cursor: pointer;
}

.container-filter-settings .filter-form input[type=search]:focus,
.container-filter-settings .filter-form input[type=search]:valid{
    border-bottom: #673AB7 2px solid;
    transition: border-color ease-in-out .10s,box-shadow ease-in-out .10s;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/configuration/filter/search/style.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,aAAa;IACb,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,gDAAgD;AACpD;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;IAEI,gCAAgC;IAChC,qEAAqE;AACzE","sourcesContent":["/* Filter Styles */\n\n.container-filter-settings .filter-form{\n    display: flex;\n    align-items: flex-end;\n    column-gap: 1em;\n}\n\n.container-filter-settings .filter-form input[type=search]{\n    width: 18em;\n    border: none;\n    outline: 0;\n    padding: 0 0.6em;\n    font-size: 0.9em;\n    background: inherit;\n    border-bottom: 1px solid var(--line-color-light);\n}\n\n.container-filter-settings .filter-form input[type=search]::-webkit-search-cancel-button{\n    margin-left: 1em;\n    cursor: pointer;\n}\n\n.container-filter-settings .filter-form input[type=search]:focus,\n.container-filter-settings .filter-form input[type=search]:valid{\n    border-bottom: #673AB7 2px solid;\n    transition: border-color ease-in-out .10s,box-shadow ease-in-out .10s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
