// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Info Checked Container */

.container-info-checked{
    width: 100%;
    margin: 2em auto 1em;
    background-color: inherit;
    box-shadow: none;
    font-size: 1.6em;
}

.container-info-checked .container-selected-length{
    font-size: 1.05em;
    padding: 1em 3em;
    margin-bottom: 1em;
    background-color: var(--white);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-info-checked .infochecked-actions{
    display: flex;
    align-items: center;
    gap: 1em;
}

.container-info-checked ul{
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
}

/* Checked Cards */

.container-info-checked li{
    background-color: var(--white);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    transition: all 0.1s;
}

.container-info-checked li:hover{
    border: 1px solid var(--grey);
    transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/infoChecked/style.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;IACI,WAAW;IACX,oBAAoB;IACpB,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,8BAA8B;IAC9B,sEAAsE;IACtE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;;IAEV,aAAa;IACb,kCAAkC;IAClC,QAAQ;AACZ;;AAEA,kBAAkB;;AAElB;IACI,8BAA8B;IAC9B,sEAAsE;IACtE,oBAAoB;AACxB;;AAEA;IACI,6BAA6B;IAC7B,sBAAsB;AAC1B","sourcesContent":["/* Info Checked Container */\n\n.container-info-checked{\n    width: 100%;\n    margin: 2em auto 1em;\n    background-color: inherit;\n    box-shadow: none;\n    font-size: 1.6em;\n}\n\n.container-info-checked .container-selected-length{\n    font-size: 1.05em;\n    padding: 1em 3em;\n    margin-bottom: 1em;\n    background-color: var(--white);\n    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 5px 0 rgb(0 0 0 / 12%);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.container-info-checked .infochecked-actions{\n    display: flex;\n    align-items: center;\n    gap: 1em;\n}\n\n.container-info-checked ul{\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 2em;\n}\n\n/* Checked Cards */\n\n.container-info-checked li{\n    background-color: var(--white);\n    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 5px 0 rgb(0 0 0 / 12%);\n    transition: all 0.1s;\n}\n\n.container-info-checked li:hover{\n    border: 1px solid var(--grey);\n    transform: scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
