// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-timer{
    background-color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.56em;
}

.current-date, .timer-count{
    font-weight: bold;
    font-size: 1.0em;
}

/* Informacoes do botao de timer */

.css-1lwbda4-MuiStack-root, .MuiFormControl-root, .MuiTextField-root, .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100%!important;
    height: 100%;
}

.MuiTextField-root, .MuiInputLabel-root{
    background: inherit!important;
}

.MuiInputLabel-root, .MuiInputBase-root{
    font-size: 0.58em!important;
    height: 100%;
}

.MuiInputBase-root{
    background: #FFFFFF!important;
    padding-left: 0.2em;
}

`, "",{"version":3,"sources":["webpack://./src/components/timer/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA,kCAAkC;;AAElC;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".box-timer{\n    background-color: inherit;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-size: 0.56em;\n}\n\n.current-date, .timer-count{\n    font-weight: bold;\n    font-size: 1.0em;\n}\n\n/* Informacoes do botao de timer */\n\n.css-1lwbda4-MuiStack-root, .MuiFormControl-root, .MuiTextField-root, .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{\n    width: 100%!important;\n    height: 100%;\n}\n\n.MuiTextField-root, .MuiInputLabel-root{\n    background: inherit!important;\n}\n\n.MuiInputLabel-root, .MuiInputBase-root{\n    font-size: 0.58em!important;\n    height: 100%;\n}\n\n.MuiInputBase-root{\n    background: #FFFFFF!important;\n    padding-left: 0.2em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
