import "./style.css";
import "../../../responsive/Settings-responsive.css";
import Toast from "../../../components/toastNotification";
import useHandleSettingsPage from "../hooks/useHandleSettingsPage";
import { capitalize } from "@mui/material";

import { SettingsProvider } from "../../../store/context/SettingsContext";
import InfoSettings from "../infoSettings";

export default function Settings() {
  const [menus, currentMenu, setCurrentMenu, handleMenuPermission] =
    useHandleSettingsPage();

  return (
    <div className="section-body-settings">
      <div className="container-settings">
        <div className="nav-settings">
          {menus.map(
            (menu) =>
              handleMenuPermission(menu) && (
                <li
                  key={menu.name}
                  className={currentMenu === menu.name ? "active" : null}
                  onClick={() => setCurrentMenu(menu.name)}
                >
                  <span className="nav-settings-icon">{menu.icon}</span>{" "}
                  {capitalize(menu.name)}s
                </li>
              )
          )}
        </div>
        {menus.map(
          (menu) =>
            handleMenuPermission(menu) && (
              <div
                key={menu.name}
                className={currentMenu !== menu.name ? "hidden" : null}
              >
                <SettingsProvider>
                  <InfoSettings type={menu.name} />
                </SettingsProvider>
              </div>
            )
        )}
      </div>
      <Toast />
    </div>
  );
}
