import { useEffect, useState } from "react";
import { headers } from "../../../../data/constants/headers";
import { handleFilter } from "../../../../pages/settings/configuration/filter/hooks/useFilterSearch";

export default function useModalRef(type, selectedItem, refKey, showRef) {
  const [refHeaders, setRefHeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const refData = selectedItem[refKey];
  const allHeaders = headers.find((header) => header.type === type)?.headers;
  const icon = allHeaders.find((header) => header.key === "name")?.icon;

  useEffect(() => {
    setFilteredData(handleFilter(type, refData, search));
  }, [type, refData, search, setFilteredData, loading]);

  useEffect(() => {
    if (refData.length !== 0) {
      var headers = Object.keys(refData[0]);
      setRefHeaders(headers);
    }
    setSearch("");
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRef, selectedItem]);

  return [refHeaders, filteredData, search, setSearch, icon, loading];
}
