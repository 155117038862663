import "./style.css";
import CloseIcon from "../../../assets/icons/CloseIcon";

export default function CloseModalButton({ size, handleClose }) {
  return (
    <button className="close-modal-button secondary-button" onClick={handleClose}>
      <CloseIcon size={size} color={"var(--grey)"} />
    </button>
  );
}
