import { useState } from "react";
import TogglePasswordIcon from "../../../assets/icons/TogglePasswordIcon";

export default function usePasswordToggle() {
  const [visible, setVisible] = useState(false);

  const Icon = (
    <TogglePasswordIcon showIcon={visible} color="#ADADAD" size={2.2} />
  );

  const InputType = visible ? "text" : "password";

  return [InputType, Icon, visible, setVisible];
}
