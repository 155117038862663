import { headers } from "../../../../../data/constants/headers";
import { formatDate } from "../../../../../utils/formatDate";

//incluir as keys que serao filtradas ao digitar
function keysToFilter(type) {
  const keys = [];
  const allHeaders = headers.find((header) => header.type === type)?.headers;
  allHeaders?.map((header) =>
    header.filter ? keys.push(header.key) : undefined
  );
  return keys;
}

//Funcao que retorna os items filtrados a partir do array vindo da API (all items)
function filterAllItems(type, data, search) {
  return data.filter((item) =>
    keysToFilter(type).some((key) =>
      item[key]
        ? typeof item[key] !== "object"
          ? key !== "createdAt"
            ? item[key].toLowerCase().includes(search.toLowerCase())
            : formatDate(item[key]).includes(search.toLowerCase())
          : item[key]
          ? item[key]["name"].toLowerCase().includes(search.toLowerCase())
          : undefined
        : undefined
    )
  );
}

//Funcao que verifica os items ja marcados com o array filtrado anteriormente
function handleCheckedValues(allItemsFiltered, checkedInputValues) {
  const newFilteredArray = [];

  // eslint-disable-next-line array-callback-return
  allItemsFiltered?.map((item) => {
    const itemIsChecked = checkedInputValues?.find(
      (itemChecked) => itemChecked._id === item._id
    );
    if (itemIsChecked) {
      newFilteredArray.push(itemIsChecked);
    } else {
      newFilteredArray.push(item);
    }
  });
  return newFilteredArray;
}

//funcao principal que retorna os items filtrados
export const handleFilter = (type, data, search, checkedInputValue) => {
  const allItemsFiltered = filterAllItems(type, data, search);
  if (checkedInputValue?.length !== 0) {
    const itemsFilteredChecked = handleCheckedValues(
      allItemsFiltered,
      checkedInputValue
    );
    return itemsFilteredChecked;
  }
  return allItemsFiltered;
};
