// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Botão para mudar cliente */

.change-customer-button{
    background: inherit;
    border: none;
    cursor: pointer;
    position: relative;
}

.change-customer-button:hover{
    transform: scale(0.95);
    transition: 0.5s;
}

/* Dropdown para escolha de cliente */

.box-dropdown-menu{
    position: relative;
    width: 14em;
    left: -10em!important;
    border-radius: 1em;
    padding: 1em 0em;
    z-index: 1;
}

.box-dropdown-menu li:hover{
    transition: 0.2s;
    transform: scale(1.05);
    background-color: var(--low-light);
}

.box-dropdown-menu li:active{
    transition: 0.2s;
    background-color: var(--high-dark);
}

.scroll-bar-dropdown{
   height: 61vh;
   overflow-x: hidden;
   overflow-y: auto;
}

/* Itens do dropdown para escolha do cliente */

.dropdown-item{
    padding-right: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7.6em;
}

.dropdown-item:hover{
    background-color: initial;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/changecustomer/style.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;IACI,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA,qCAAqC;;AAErC;IACI,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;GACG,YAAY;GACZ,kBAAkB;GAClB,gBAAgB;AACnB;;AAEA,8CAA8C;;AAE9C;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* Botão para mudar cliente */\n\n.change-customer-button{\n    background: inherit;\n    border: none;\n    cursor: pointer;\n    position: relative;\n}\n\n.change-customer-button:hover{\n    transform: scale(0.95);\n    transition: 0.5s;\n}\n\n/* Dropdown para escolha de cliente */\n\n.box-dropdown-menu{\n    position: relative;\n    width: 14em;\n    left: -10em!important;\n    border-radius: 1em;\n    padding: 1em 0em;\n    z-index: 1;\n}\n\n.box-dropdown-menu li:hover{\n    transition: 0.2s;\n    transform: scale(1.05);\n    background-color: var(--low-light);\n}\n\n.box-dropdown-menu li:active{\n    transition: 0.2s;\n    background-color: var(--high-dark);\n}\n\n.scroll-bar-dropdown{\n   height: 61vh;\n   overflow-x: hidden;\n   overflow-y: auto;\n}\n\n/* Itens do dropdown para escolha do cliente */\n\n.dropdown-item{\n    padding-right: 12%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 7.6em;\n}\n\n.dropdown-item:hover{\n    background-color: initial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
