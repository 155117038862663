import "./style.css";
import usePasswordToggle from "../hooks/usePasswordToggle";

export default function InputPassword({
  info,
  setInfo,
  placeHolder,
  handleSubmit,
}) {
  const [passwordInputType, toggleIcon, visible, setVisible] =
    usePasswordToggle();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="wrap-input">
      <input
        type={passwordInputType}
        value={info}
        onChange={({ target }) => setInfo(target.value)}
        className={info ? "has-value input" : "input"}
        onKeyDown={handleKeyDown}
      />
      <span className="focus-input" data-placeholder={placeHolder}></span>
      <span
        className="password-toggle-icon"
        onClick={() => setVisible(!visible)}
      >
        {toggleIcon}
      </span>
    </div>
  );
}
