import "./style.css";
import { isUserSensedia } from "../../../utils/isUserSensedia";

export default function AccordionItem({
  children,
  user,
  id,
  text,
  onlySensedia,
}) {
  return onlySensedia && !isUserSensedia(user) ? undefined : (
    <>
      <h2 className="accordion-header" id={id}>
        <button
          className="accordion-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${id}`}
          aria-expanded="true"
          aria-controls={`collapse${id}`}
        >
          {text}
        </button>
      </h2>
      <div
        id={`collapse${id}`} //data-bs-target
        className="collapse"
        aria-labelledby={id}
        data-bs-parent="#accordionSupport"
      >
        <div className="accordion-body">{children}</div>
      </div>
    </>
  );
}
