import { useEffect, useState } from "react";
import { headers } from "../../../../data/constants/headers";

export default function useModalPermission(
  type,
  selectedItem,
  refKey,
  showPermission
) {
  const [permissionKeys, setPermissionKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  const permissionData = selectedItem[refKey];
  const allHeaders = headers.find((header) => header.type === type)?.headers;
  const icon = allHeaders.find((header) => header.key === "name")?.icon;

  useEffect(() => {
    if (permissionData.length !== 0) {
      var keys = Object?.keys(permissionData);
      setPermissionKeys(keys);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPermission, selectedItem]);

  return [permissionData, permissionKeys, icon, loading];
}
