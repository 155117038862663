import "./style.css";
import { headers } from "../../../../data/constants/headers";
import { types } from "../../../../data/constants/types";
import { isUserSensedia } from "../../../../utils/isUserSensedia";
import InputField from "../../../../pages/settings/input";

import { useContext } from "react";
import { AuthContext } from "../../../../store/context/AuthContext";

export default function DataPutFields({
  type,
  selectedItem,
  putItem,
  handleOnChangeItem,
  handleOnChangeAllowedRoles,
}) {
  const { user } = useContext(AuthContext);

  const allHeaders = headers.find((info) => info.type === type).headers;
  return (
    <>
      {allHeaders.map((header) => {
        if (header.editable) {
          if (header.onlySensedia && !isUserSensedia(user)) {
            return null;
          }

          const isDisabled =
            (type === types.settings_menu.user && header.key === "email") ||
            (type === types.settings_menu.user &&
              header.key === "group" &&
              !selectedItem?.organization) ||
            (type === types.settings_menu.group &&
              header.key === "organization" &&
              !selectedItem?.organization);

          const content = (
            <li key={header.key} className={isDisabled ? "disabled" : ""}>
              <label htmlFor={`${header.key}`}>{header.label}</label>
              <div className="put-input">
                <span className="put-icon-field">{header.icon}</span>
                <div className="put-input-field">
                  <InputField
                    {...{ header }}
                    data={putItem}
                    handleOnChange={
                      header?.key !== "allowed_roles"
                        ? handleOnChangeItem
                        : handleOnChangeAllowedRoles
                    }
                  />
                </div>
              </div>
            </li>
          );

          return content;
        }

        return null;
      })}
    </>
  );
}
