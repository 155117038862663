import "./style.css";
import ReactPaginate from "react-paginate";

export default function Pagination({ data, setItemOffset, itemsPerPage }) {
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="box-pagination"
        pageLinkClassName="pagination-item"
        previousLinkClassName="pagination-previous"
        nextLinkClassName="pagination-next"
        activeClassName="active-page"
      />
    </>
  );
}
