import "./style.css";
import CloseIcon from "../../../assets/icons/CloseIcon";

export default function ClosePostButton({
  index,
  postCollapse,
  setPostCollapse,
  handleRemoveRowItem,
}) {
  return (
    <>
      <button
        className="close-button secondary-button"
        onClick={() =>
          handleRemoveRowItem(index, postCollapse, setPostCollapse)
        }
        title="Close"
      >
        <CloseIcon size={0.8} />
      </button>
    </>
  );
}
