import "./style.css";
import { capitalize } from "@mui/material";

export default function HandleModalButton({
  text,
  color,
  id,
  handleAction,
  handleClose,
}) {
  return (
    <button
      className={
        text === "confirm"
          ? "confirm-modal-button primary-button"
          : "cancel-modal-button primary-button"
      }
      style={
        text === "confirm"
          ? { backgroundColor: color, border: `1px solid ${color}` }
          : undefined
      }
      onClick={() => handleAction(id, handleClose)}
    >
      {capitalize(text)}
    </button>
  );
}
