// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.css */
.section-body-reports {
  width: 100%;
  padding-top: 12em;
  margin: auto;
  font-size: 1rem;
}

.input-select {
  height: 50px;
}

.table {
  font-size: 1em;
}

.table th {
  font-size: 1.5em;
}

.table td {
  font-size: 1.4em;
}

.MuiPickersCalendarHeader-label {
  font-size: 1.4em;
}`, "",{"version":3,"sources":["webpack://./src/pages/reports/section/style.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* style.css */\n.section-body-reports {\n  width: 100%;\n  padding-top: 12em;\n  margin: auto;\n  font-size: 1rem;\n}\n\n.input-select {\n  height: 50px;\n}\n\n.table {\n  font-size: 1em;\n}\n\n.table th {\n  font-size: 1.5em;\n}\n\n.table td {\n  font-size: 1.4em;\n}\n\n.MuiPickersCalendarHeader-label {\n  font-size: 1.4em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
