import React, { createContext } from "react";
import useAuth from "./hooks/useAuth";
import LoadingPage from "../../pages/loading";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const {
    userNameOrEmail,
    setUserNameOrEmail,
    name,
    setName,
    email,
    setEmail,
    emailReset,
    setEmailReset,
    emailSent,
    setEmailSent,
    password,
    setPassword,
    rememberMe,
    handleRememberMe,
    requiredField,
    setRequiredField,
    user,
    authenticated,
    loading,
    loadingPage,
    registerUser,
    handleLogin,
    handleLogOut,
    handleForgotPassword,
    handleResetPassword,
    handleEmailConfirmation,
    getEmailByToken,
  } = useAuth();

  if (loadingPage) {
    return <LoadingPage loading={loading} loadingPage={loadingPage} />;
  }

  return (
    <AuthContext.Provider
      value={{
        userNameOrEmail,
        setUserNameOrEmail,
        name,
        setName,
        email,
        setEmail,
        emailReset,
        setEmailReset,
        emailSent,
        setEmailSent,
        password,
        setPassword,
        rememberMe,
        handleRememberMe,
        requiredField,
        setRequiredField,
        user,
        authenticated,
        loading,
        loadingPage,
        registerUser,
        handleLogin,
        handleLogOut,
        handleForgotPassword,
        handleResetPassword,
        handleEmailConfirmation,
        getEmailByToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
