// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal API Info */

.header-modal-customerstatus{
    width: 100%;
    font-size: 0.45em;
    display: flex;
    column-gap: 3em;
    letter-spacing: 0.08em;
    border-bottom: 1px solid var(--line-color-light);
    padding: 3em 2em 5em 11em;
}

.header-modal-customerstatus .header-severity-icon{
    font-size: 1.8em;
    text-align: center;
    margin: auto;
}

.header-modal-customerstatus .header-api-informations{
    width: 100%;
    font-size: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}   

.body-modal-customerstatus{
    font-size: 0.8em;
    max-height: 520px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.8em;
    border-top: 1px solid var(--line-color-light);
    padding: 1.8em 2em 1.8em 4em;
    background-color: var(--white-smoke);
    overflow-y: auto;
}

.body-severity-icon{
    width: 3.4em;
    text-align: center;
    color: var(--color);
}

.body-api-informations{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5em 1em;
}

.important-infos{
    font-weight: bold;
    font-size: 1.1em;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modalCustomerStatus/modalInfo/style.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,gDAAgD;IAChD,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,6CAA6C;IAC7C,4BAA4B;IAC5B,oCAAoC;IACpC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["/* Modal API Info */\n\n.header-modal-customerstatus{\n    width: 100%;\n    font-size: 0.45em;\n    display: flex;\n    column-gap: 3em;\n    letter-spacing: 0.08em;\n    border-bottom: 1px solid var(--line-color-light);\n    padding: 3em 2em 5em 11em;\n}\n\n.header-modal-customerstatus .header-severity-icon{\n    font-size: 1.8em;\n    text-align: center;\n    margin: auto;\n}\n\n.header-modal-customerstatus .header-api-informations{\n    width: 100%;\n    font-size: 2em;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}   \n\n.body-modal-customerstatus{\n    font-size: 0.8em;\n    max-height: 520px;\n    margin: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    column-gap: 1.8em;\n    border-top: 1px solid var(--line-color-light);\n    padding: 1.8em 2em 1.8em 4em;\n    background-color: var(--white-smoke);\n    overflow-y: auto;\n}\n\n.body-severity-icon{\n    width: 3.4em;\n    text-align: center;\n    color: var(--color);\n}\n\n.body-api-informations{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 0.5em 1em;\n}\n\n.important-infos{\n    font-weight: bold;\n    font-size: 1.1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
