// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 2500px){
    .box-dropdown-menu{
        width: 20em;
        left: -15em!important;
    }
}

@media screen and (max-width: 2500px){
    .box-dropdown-menu{
        width: 18em;
        left: -13em!important;
    }
}

@media screen and (max-width: 2200px){
    .box-dropdown-menu{
        width: 17em;
        left: -12em!important;
    }
}

@media screen and (max-width: 1920px){
    .box-dropdown-menu{
        width: 140px;
        left: -100px!important;
    }
}

@media screen and (max-width: 1613px){
    .box-dropdown-menu{
        width: 130px;
        left: -90px!important;
        font-size: 0.38em;
    }
}

@media screen and (max-width: 1472px){
    .box-dropdown-menu{
        width: 120px;
        left: -84px!important;
        font-size: 0.38em;
    }
}


@media screen and (max-width: 1400px){
    .box-dropdown-menu{
        width: 110px;
        left: -76px!important;
        font-size: 0.38em;
    }
}

@media screen and (max-width: 1296px){
    .box-dropdown-menu{
        width: 100px;
        left: -68px!important;
        font-size: 0.36em;
    }
}

@media screen and (max-width: 1020px){
    .box-dropdown-menu{
        width: 84px;
        left: -66px!important;
    }
}`, "",{"version":3,"sources":["webpack://./src/responsive/Buttons-responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;QACX,qBAAqB;IACzB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,qBAAqB;IACzB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,qBAAqB;IACzB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,qBAAqB;QACrB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,qBAAqB;QACrB,iBAAiB;IACrB;AACJ;;;AAGA;IACI;QACI,YAAY;QACZ,qBAAqB;QACrB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,qBAAqB;QACrB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,qBAAqB;IACzB;AACJ","sourcesContent":["@media screen and (min-width: 2500px){\n    .box-dropdown-menu{\n        width: 20em;\n        left: -15em!important;\n    }\n}\n\n@media screen and (max-width: 2500px){\n    .box-dropdown-menu{\n        width: 18em;\n        left: -13em!important;\n    }\n}\n\n@media screen and (max-width: 2200px){\n    .box-dropdown-menu{\n        width: 17em;\n        left: -12em!important;\n    }\n}\n\n@media screen and (max-width: 1920px){\n    .box-dropdown-menu{\n        width: 140px;\n        left: -100px!important;\n    }\n}\n\n@media screen and (max-width: 1613px){\n    .box-dropdown-menu{\n        width: 130px;\n        left: -90px!important;\n        font-size: 0.38em;\n    }\n}\n\n@media screen and (max-width: 1472px){\n    .box-dropdown-menu{\n        width: 120px;\n        left: -84px!important;\n        font-size: 0.38em;\n    }\n}\n\n\n@media screen and (max-width: 1400px){\n    .box-dropdown-menu{\n        width: 110px;\n        left: -76px!important;\n        font-size: 0.38em;\n    }\n}\n\n@media screen and (max-width: 1296px){\n    .box-dropdown-menu{\n        width: 100px;\n        left: -68px!important;\n        font-size: 0.36em;\n    }\n}\n\n@media screen and (max-width: 1020px){\n    .box-dropdown-menu{\n        width: 84px;\n        left: -66px!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
