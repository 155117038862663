import { types } from "./types";
import UserIcon from "../../assets/icons/UserIcon";
import EmailIcon from "../../assets/icons/EmailIcon";
import PasswordIcon from "../../assets/icons/PasswordIcon";
import RoleIcon from "../../assets/icons/RoleIcon";
import OrganizationIcon from "../../assets/icons/OrganizationIcon";
import DomainIcon from "../../assets/icons/DomainIcon";
import GroupIcon from "../../assets/icons/GroupIcon";
import IsActiveIcon from "../../assets/icons/IsActiveIcon"

/* ============================ 
            SETTINGS           
   ============================ */

export const headers = [
  {
    type: types.settings_menu.user,
    headers: [
      {
        key: "name",
        label: "Username",
        width: "18%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "25%",
        editable: true,
        inputType: types.inputTypes.text,
        maxLength: 36,
        icon: <UserIcon size={1}/>
      },
      {
        key: "email",
        label: "Email",
        width: "20%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
        post: true,
        postWidth: "25%",
        editable: true,
        inputType: types.inputTypes.email,
        maxLength: 36,
        icon: <EmailIcon size={1}/>
      },
      {
        key: "password",
        label: "Password",
        hiddenTable: true, //sempre true
        post: true,
        postWidth: "25%",
        editable: false,
        inputType: types.inputTypes.text,
        icon: <PasswordIcon size={1}/>
      },
      {
        key: "role",
        label: "Role",
        width: "8%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
        post: true,
        postWidth: "25%",
        editable: true,
        inputType: types.inputTypes.select,
        selectName: types.select.roles,
        icon: <RoleIcon size={1}/>
      },
      {
        key: "organization",
        label: "Organization",
        width: "10%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: false,
        editable: false,
        onlySensedia: true,
        inputType: types.inputTypes.select,
        icon: <OrganizationIcon size={1}/>
      },
      {
        key: "group",
        label: "Group",
        width: "10%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
        post: false,
        editable: true,
        inputType: types.inputTypes.select,
        selectName: "groups",
        icon: <GroupIcon size={1}/>
      },
      {
        key: "createdAt",
        label: "Creation",
        width: "14%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
      },
      {
        key: "_id",
        label: "id",
        width: "20%",
        filter: true,
        sort: false,
        hiddenTable: true,
        hiddenCard: false,
        hiddenModal: false,
      },
      {
        key: "isActive",
        label: "Active",
        width: "5%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
        post: false,
        editable: true,
        inputType: types.inputTypes.checkbox,
        icon: <IsActiveIcon size={1}/>
      },
      {
        key: "isEmailConfirmed",
        label: "Email Confirmed",
        width: "5%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
      },
      {
        key: "isFirstAccess",
        label: "First Access",
        width: "5%",
        filter: false,
        sort: false,
        hiddenTable: true,
        hiddenCard: true,
        hiddenModal: true,
      },
    ],
  },
  {
    type: types.settings_menu.role,
    headers: [
      {
        key: "name",
        label: "Name",
        width: "30%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "50%",
        editable: true,
        onlySensedia: true,
        inputType: types.inputTypes.text,
        maxLength: 36,
        icon: <RoleIcon size={1}/>
      },
      {
        key: "users",
        ref: true,
        label: "Users",
        width: "15%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
        post: false,
        editable: false,
      },
      {
        key: "defaultGroup",
        label: "Default Group",
        width: "25%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
        post: true,
        postWidth: "50%",
        editable: true,
        onlySensedia: true,
        inputType: types.inputTypes.select,
        selectName: types.select.defaultGroups,
        icon: <GroupIcon size={1}/>
      },
      {
        key: "createdAt",
        label: "Creation",
        width: "30%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
      },
      {
        key: "_id",
        label: "id",
        width: "20%",
        filter: true,
        sort: false,
        hiddenTable: true,
        hiddenCard: false,
        hiddenModal: false,
      },
    ],
  },
  {
    type: types.settings_menu.organization,
    headers: [
      {
        key: "name",
        label: "Name",
        width: "25%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "50%",
        editable: true,
        onlySensedia: true,
        inputType: types.inputTypes.text,
        maxLength: 36,
        icon: <OrganizationIcon size={1}/>
      },
      {
        key: "customer_id",
        label: "Customer Id",
        width: "25%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "50%",
        editable: true,
        onlySensedia: true,
        inputType: types.inputTypes.text,
        maxLength: 36,
        icon: <PasswordIcon size={1}/>
      },
      {
        key: "users",
        ref: true,
        label: "Users",
        width: "10%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
        post: false,
        editable: false,
      },
      {
        key: "domains",
        ref: true,
        label: "Domains",
        width: "10%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
        post: false,
        editable: false,
      },
      {
        key: "groups",
        ref: true,
        label: "Groups",
        width: "10%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
        post: false,
        editable: false,
      },
      {
        key: "createdAt",
        label: "Creation",
        width: "20%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
      },
      {
        key: "_id",
        label: "id",
        width: "20%",
        filter: true,
        sort: false,
        hiddenTable: true,
        hiddenCard: false,
        hiddenModal: false,
      },
    ],
  },
  {
    type: types.settings_menu.domain,
    headers: [
      {
        key: "name",
        label: "Name",
        width: "30%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "100%",
        editable: true,
        inputType: types.inputTypes.text,
        maxLength: 36,
        icon: <DomainIcon size={1}/>
      },
      {
        key: "organization",
        label: "Organization",
        width: "30%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "100%",
        editable: true,
        onlySensedia: true,
        inputType: types.inputTypes.select,
        selectName: types.select.organizations,
        icon: <OrganizationIcon size={1}/>
      },
      {
        key: "createdAt",
        label: "Creation",
        width: "30%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
      },
      {
        key: "_id",
        label: "id",
        width: "20%",
        filter: true,
        sort: false,
        hiddenTable: true,
        hiddenCard: false,
        hiddenModal: false,
      },
    ],
  },
  {
    type: types.settings_menu.group,
    headers: [
      {
        key: "name",
        label: "Name",
        width: "30%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "75%",
        editable: true,
        inputType: types.inputTypes.text,
        maxLength: 36,
        icon: <GroupIcon size={1}/>
      },
      {
        key: "organization",
        label: "Organization",
        width: "25%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: false,
        post: true,
        postWidth: "75%",
        editable: true,
        onlySensedia: true,
        inputType: types.inputTypes.select,
        selectName: types.select.organizations,
        icon: <OrganizationIcon size={1}/>
      },
      {
        key: "users",
        ref: true,
        label: "Users",
        width: "10%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
        post: false,
        editable: false,
      },
      {
        key: "allowed_roles",
        label: "Allowed Roles",
        filter: false,
        sort: false,
        hiddenTable: true,
        hiddenCard: true,
        hiddenModal: true,
        post: true,
        postWidth: "25%",
        editable: true,
        inputType: types.inputTypes.checkbox,
        icon: <RoleIcon size={1}/>
      },
      {
        key: "isDefaultGroup",
        label: "isDefault",
        filter: false,
        sort: false,
        hiddenTable: true,
        hiddenCard: true,
        hiddenModal: true,
        post: true,
        postWidth: "20%",
        editable: false,
        onlySensedia: true,
        inputType: types.inputTypes.checkbox,
      },
      {
        key: "permissions",
        permissions: true,
        label: "Permissions",
        width: "10%",
        filter: false,
        sort: false,
        hiddenTable: false,
        hiddenCard: true,
        hiddenModal: true,
        post: false,
        editable: false,
      },
      {
        key: "createdAt",
        label: "Creation",
        width: "25%",
        filter: true,
        sort: true,
        hiddenTable: false,
        hiddenCard: false,
        hiddenModal: false,
      },
      {
        key: "_id",
        label: "id",
        width: "20%",
        filter: true,
        sort: false,
        hiddenTable: true,
        hiddenCard: false,
        hiddenModal: false,
      },
    ],
  },
];
