// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Botão de atualizar */

.box-refresh-button{
    font-size: 0.6em;
}

.box-refresh-button .refresh-button{
    width: 2.4em;
    height: 2.4em;
    background: #FFF;
    border-radius: 0.4em;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px var(--box-shadow-light);

    position: relative;
    top: 0.1em;
}

.box-refresh-button .refresh-button:hover{
    border: 1px solid rgb(78, 78, 155);
    transition: 0.2s;
}

.box-refresh-button .refresh-button:active{
    transform: scale(0.98);
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/refresh/style.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,eAAe;IACf,mDAAmD;;IAEnD,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/* Botão de atualizar */\n\n.box-refresh-button{\n    font-size: 0.6em;\n}\n\n.box-refresh-button .refresh-button{\n    width: 2.4em;\n    height: 2.4em;\n    background: #FFF;\n    border-radius: 0.4em;\n    border: none;\n    cursor: pointer;\n    box-shadow: 0px 0px 5px 1px var(--box-shadow-light);\n\n    position: relative;\n    top: 0.1em;\n}\n\n.box-refresh-button .refresh-button:hover{\n    border: 1px solid rgb(78, 78, 155);\n    transition: 0.2s;\n}\n\n.box-refresh-button .refresh-button:active{\n    transform: scale(0.98);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
