// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 2500px) {
    .section-body-support{
        font-size: 1.4rem!important;
    }  
}

@media screen and (max-width: 2500px) {
    .section-body-support{
        font-size: 1.3rem!important;
    }  
}

@media screen and (max-width: 2200px) {
    .section-body-support{
        font-size: 1.2rem!important;
    }  
}

@media screen and (max-width: 1920px) {
    .section-body-support{
        font-size: 1rem!important;
    }  
}

@media screen and (max-width: 1300px) {
    .section-body-support{
        font-size: 0.8rem!important;
    }  
}

@media screen and (max-width: 965px) {
    .section-body-support{
        font-size: 0.7rem!important;
    }   
}

@media screen and (max-width: 730px) {
    .section-body-support{
        font-size: 0.65rem!important;
    }   
}`, "",{"version":3,"sources":["webpack://./src/responsive/Support-responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["@media screen and (min-width: 2500px) {\n    .section-body-support{\n        font-size: 1.4rem!important;\n    }  \n}\n\n@media screen and (max-width: 2500px) {\n    .section-body-support{\n        font-size: 1.3rem!important;\n    }  \n}\n\n@media screen and (max-width: 2200px) {\n    .section-body-support{\n        font-size: 1.2rem!important;\n    }  \n}\n\n@media screen and (max-width: 1920px) {\n    .section-body-support{\n        font-size: 1rem!important;\n    }  \n}\n\n@media screen and (max-width: 1300px) {\n    .section-body-support{\n        font-size: 0.8rem!important;\n    }  \n}\n\n@media screen and (max-width: 965px) {\n    .section-body-support{\n        font-size: 0.7rem!important;\n    }   \n}\n\n@media screen and (max-width: 730px) {\n    .section-body-support{\n        font-size: 0.65rem!important;\n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
