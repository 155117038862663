// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infochecked-actions .clearAll-button{
    padding: 0.1em 0.6em;
    border-radius: 0.2em;
    background-color: var(--white);
    color: var(--grey-lighter);
    border: 1px solid var(--grey-lighter);
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/clearAll/style.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,8BAA8B;IAC9B,0BAA0B;IAC1B,qCAAqC;AACzC","sourcesContent":[".infochecked-actions .clearAll-button{\n    padding: 0.1em 0.6em;\n    border-radius: 0.2em;\n    background-color: var(--white);\n    color: var(--grey-lighter);\n    border: 1px solid var(--grey-lighter);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
