import "./style.css";
import "../../../responsive/StatusPage-responsive.css";
import { useState } from "react";
import { severity_informations } from "../../../data/constants/severity";
import ModalCustomerStatus from "../../../components/modal/modalCustomerStatus/modalInfo";

export default function BoxRuleCard({
  ruleId,
  ruleName,
  firing_date,
  description,
  severity,
  severity_color,
  severity_icon,
}) {
  const [showModal, setShowModal] = useState(false);

  function getMessageByColor(severity_color) {
    const message_color = severity_informations.find(
      (info) => info.color === severity_color
    );
    return message_color;
  }

  return (
    <div>
      <button
        onClick={() => setShowModal(true)}
        className="button-card-box"
        data-mdb-toggle="modal"
        data-mdb-target="#exampleModal"
      >
        <div
          className="box-api-info"
          style={{ border: `1px outset ${severity_color}` }}
        >
          <div className="header-api-info">
            <div className="api-rulename">
              <h3>{ruleName}</h3>
            </div>
            {getMessageByColor(severity_color).severity !== "operational" ? (
              <div className="api-id">ID: {ruleId}</div>
            ) : undefined}
          </div>
          <div className="body-api-info">
            <div
              className="api-status-message"
              style={{
                background: `${severity_color}`,
                color: getMessageByColor(severity_color).message_color,
              }}
            >
              <span className="icon-inMessage">{severity_icon}</span>
              <span className="alert-message">
                {getMessageByColor(severity_color).message}
              </span>
            </div>
          </div>
        </div>
      </button>

      <ModalCustomerStatus
        {...{
          ruleId,
          ruleName,
          firing_date,
          description,
          severity,
          severity_color,
          severity_icon,
          showModal,
          setShowModal,
        }}
      />
    </div>
  );
}
