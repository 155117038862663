// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-configuration .refresh-button{
    padding: 0.1em 0.7em 0.1em 0.65em;
    border-radius: 0.2em;
    background-color: var(--white);
    color: var(--grey-lighter);
    border: 1px solid var(--grey-lighter);

    display: flex;
    column-gap: 0.4em;
}

.container-configuration .refresh-button .refresh-icon{
    position: relative;
    bottom: 0.05em;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/refreshSettings/style.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,oBAAoB;IACpB,8BAA8B;IAC9B,0BAA0B;IAC1B,qCAAqC;;IAErC,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".container-configuration .refresh-button{\n    padding: 0.1em 0.7em 0.1em 0.65em;\n    border-radius: 0.2em;\n    background-color: var(--white);\n    color: var(--grey-lighter);\n    border: 1px solid var(--grey-lighter);\n\n    display: flex;\n    column-gap: 0.4em;\n}\n\n.container-configuration .refresh-button .refresh-icon{\n    position: relative;\n    bottom: 0.05em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
