import "./style.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_support_premium_white from "../../assets/images/support-premium-logo-white.png";

export default function SimpleBackdrop({ loading, loadingPage, logo }) {
  return (
    <Backdrop
      sx={{ color: "#FFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
      transitionDuration={1000}
      invisible={loadingPage}
    >
      <div className={logo ? "container-backdrop" : undefined}>
        {logo ? (
          <img src={logo_support_premium_white} alt="Logo Support Premium" />
        ) : undefined}
        <CircularProgress color="inherit" />
      </div>
    </Backdrop>
  );
}
