// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @media screen and (max-width: 1360px){
    .MuiInputLabel-root, .MuiInputBase-root{
        font-size: 1.3em!important;
    }
}

@media screen and (max-width: 1120px){
    .MuiInputLabel-root, .MuiInputBase-root{
        font-size: 1.5em!important;
    }
}

@media screen and (max-width: 965px){
    .MuiInputLabel-root, .MuiInputBase-root{
        font-size: 1.6em!important;
    }
} */`, "",{"version":3,"sources":["webpack://./src/responsive/Timer-responsive.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;GAgBG","sourcesContent":["/* @media screen and (max-width: 1360px){\n    .MuiInputLabel-root, .MuiInputBase-root{\n        font-size: 1.3em!important;\n    }\n}\n\n@media screen and (max-width: 1120px){\n    .MuiInputLabel-root, .MuiInputBase-root{\n        font-size: 1.5em!important;\n    }\n}\n\n@media screen and (max-width: 965px){\n    .MuiInputLabel-root, .MuiInputBase-root{\n        font-size: 1.6em!important;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
