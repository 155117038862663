// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-modal-settings .confirm-modal-button, 
.footer-modal-settings .cancel-modal-button{
    font-size: 0.86em;
    width: 12em;
    padding: 0.4em;
    border-radius: 0.2em;
    box-shadow: none;
    color: var(--white);
}

.footer-modal-settings .cancel-modal-button{
    background-color: inherit;
    border: 1px solid var(--grey-lighter);
    color: var(--grey-lighter);
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/handlemodal/style.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,WAAW;IACX,cAAc;IACd,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,qCAAqC;IACrC,0BAA0B;AAC9B","sourcesContent":[".footer-modal-settings .confirm-modal-button, \n.footer-modal-settings .cancel-modal-button{\n    font-size: 0.86em;\n    width: 12em;\n    padding: 0.4em;\n    border-radius: 0.2em;\n    box-shadow: none;\n    color: var(--white);\n}\n\n.footer-modal-settings .cancel-modal-button{\n    background-color: inherit;\n    border: 1px solid var(--grey-lighter);\n    color: var(--grey-lighter);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
