// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-status-page{
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    gap: 3em;
    padding-bottom: 5em;
    font-size: 0.6em;
}

.container-status-page li{
    list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/customerstatus/display/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,QAAQ;IACR,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container-status-page{\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n    flex-wrap: wrap;\n    gap: 3em;\n    padding-bottom: 5em;\n    font-size: 0.6em;\n}\n\n.container-status-page li{\n    list-style: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
