import "./style.css";
import { capitalize } from "@mui/material";
import ModalTemplate from "../..";
import AlertHigh from "../../../../assets/icons/AlertHighIcon";
import HandleModalButton from "../../../buttons/handlemodal";

import { useContext } from "react";
import { SettingsContext } from "../../../../store/context/SettingsContext";

export default function ModalDeleteAll({
  type,
  selectedInfos,
  showDeleteAll,
  setShowDeleteAll,
}) {
  const { deleteAllItemsSelected } = useContext(SettingsContext);

  const handleClose = () => setShowDeleteAll(false);

  function handleDeleteAll() {
    deleteAllItemsSelected(selectedInfos, handleClose);
  }

  return (
    <ModalTemplate
      color={"var(--delete-color)"}
      showModal={showDeleteAll}
      {...{ handleClose }}
    >
      <div
        className="header-modal-settings"
        style={{ color: "var(--delete-color)" }}
      >
        <span style={{ position: "relative", top: "0.12em" }}>
          <AlertHigh size={2} />
        </span>{" "}
        <p>Delete {capitalize(type)}</p>
      </div>

      <div className="body-modal-settings" style={{ padding: "2em 3em" }}>
        <span>
          Are you sure you want to delete all the selected {type}s?
          <br />
          Total: <strong>{selectedInfos.length}</strong>
        </span>
      </div>

      <div className="footer-modal-settings">
        <HandleModalButton
          text="confirm"
          color="var(--delete-color)"
          handleAction={handleDeleteAll}
        />
        <HandleModalButton text="cancel" handleAction={handleClose} />
      </div>
    </ModalTemplate>
  );
}
