import "./style.css";
import "../../../responsive/Buttons-responsive.css";
import { Link } from "react-router-dom";
import { customer_info } from "../../../data/constants/clients";
import ChangeCustomerIcon from "../../../assets/icons/ChangeCustomerIcon.jsx";

export default function ChangeCustomerButton() {
  return (
    <div className="nav-item active dropdown">
      <Link
        className="nav-link change-customer-button"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        title="Change Customer"
      >
        <ChangeCustomerIcon color={"#5C2E7D"} size={1} />
      </Link>
      <ul
        className="dropdown-menu box-dropdown-menu scroll-bar-dropdown"
        aria-labelledby="navbarDropdown"
      >
        {customer_info.map((customer, index) => (
          <li key={index}>
            <Link
              to={`./../${customer.href}`}
              className="dropdown-item dropdown-item"
            >
              <img
                src={customer.logo}
                title={customer.title}
                alt={customer.alt}
                style={{ width: `${customer.width_changeButton}em` }}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
