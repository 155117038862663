import "./style.css";
import { capitalize } from "@mui/material";
import { formatDate } from "../../../../utils/formatDate";
import { severity_informations } from "../../../../data/constants/severity";
import ModalTemplate from "../..";
import BellIcon from "../../../../assets/icons/BellIcon";

export default function ModalInfo({
  ruleId,
  ruleName,
  firing_date,
  description,
  severity,
  severity_color,
  severity_icon,
  showModal,
  setShowModal,
}) {
  function getIconBySeverity(severity) {
    return severity_informations.find((info) => info.severity === severity)
      .icon;
  }

  const handleClose = () => setShowModal(false);

  return (
    <ModalTemplate
      color={severity_color}
      size="lg"
      {...{ showModal, handleClose }}
    >
      <div className="header-modal-customerstatus">
        <div className="header-severity-icon">
          <BellIcon color={"rgb(122, 113, 113)"} size={"3.6"} />
        </div>
        <div className="header-api-informations">
          <div>
            Id: <span className="important-infos">{ruleId}</span>{" "}
          </div>
          <div>
            Name: <span className="important-infos">{ruleName}</span>{" "}
          </div>
        </div>
      </div>

      {firing_date &&
        firing_date.map((alert, index) => (
          <section key={index} className="body-modal-customerstatus">
            <div
              className="body-severity-icon"
              style={{ "--color": severity_color }}
            >
              {getIconBySeverity(severity[index])}
            </div>
            <div className="body-api-informations">
              <div>
                Alert {index + 1}:{" "}
                <span className="important-infos">
                  {capitalize(severity[index])}
                </span>
              </div>
              <div>
                Firing Date:{" "}
                <span className="important-infos">{formatDate(alert)}</span>
              </div>
              <div>
                Description:{" "}
                <span className="important-infos">{description[index]}</span>
              </div>
            </div>
          </section>
        ))}
    </ModalTemplate>
  );
}
