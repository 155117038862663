import "./style.css";
import { useEffect } from "react";
import ArrowSort from "../../../assets/icons/ArrowSortIcon";

export default function SortTableButton({
  columnKey,
  isSortable,
  sortOrder,
  setSortOrder,
  sortKey,
  setSortKey,
}) {
  useEffect(() => {
    setSortOrder("asc");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  function newOrder(key, sortOrder) {
    if (key === sortKey) {
      if (sortOrder === "asc") {
        return "desc";
      } else if (sortOrder === "desc") {
        return "asc";
      }
    }
  }

  function changeSort(key) {
    setSortKey(key);
    setSortOrder(newOrder(key, sortOrder));
  }

  function arrowDirection(key) {
    if (sortKey === key) {
      if (sortOrder === "desc") {
        return "sort-button sort-reverse-down";
      } else if (sortOrder === "asc") {
        return "sort-button sort-reverse-up";
      }
    } else {
      return "sort-button";
    }
  }

  return (
    <div
      className={`${
        isSortable
          ? "container-sort-button secondary-button"
          : "container-sort-button invisible"
      }`}
    >
      <button
        onClick={() => changeSort(columnKey)}
        className={arrowDirection(columnKey)}
      >
        <ArrowSort size={1.3} />
      </button>
    </div>
  );
}
