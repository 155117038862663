import { showNotification } from "../components/toastNotification";
import { isUserSensedia } from "./isUserSensedia";
import { types } from "../data/constants/types";
import api_authentication from "../services/api/api-authentication";

export async function handleSelectOptions(user) {
  const selectOptions = [
    { name: types.select.roles, url: "roles", select: types.select.roles },
    {
      name: types.select.organizations,
      url: "organizations",
      select: types.select.organizations,
    },
    {
      name: types.select.domains,
      url: isUserSensedia(user)
        ? "domains"
        : `domains/organization/${user.organization?._id}`,
      select: types.select.domains,
    },
    {
      name: types.select.groups,
      url: isUserSensedia(user)
        ? "groups"
        : `groups/organization/${user.organization?._id}`,
      select: types.select.groups,
    },
    {
      name: types.select.groups,
      url: "groups/default-groups",
      select: types.select.defaultGroups,
    },
  ];

  var options = {};

  await Promise.all(
    selectOptions.map(
      async (selectOption) =>
        await api_authentication
          .get(`/${selectOption.url}`)
          .then((response) => {
            options[selectOption.select] = [{ _id: "", name: "-" }];
            response.data[`${selectOption.name}`].map((item) =>
              options[selectOption.select].push(item)
            );
          })
          .catch((error) => {
            showNotification(error.response.data.error);
          })
    )
  );

  return options;
}
