import { Navigate, Route, Routes } from "react-router-dom";
import { user_permissions } from "../../data/constants/permissions";
import history from "./history";
import PrivateRoutes from "./PrivateRoutes";
import LoginForm from "../../pages/authentication/pages/login";
import RegisterForm from "../../pages/authentication/pages/register";
import ForgotPasswordForm from "../../pages/authentication/pages/forgotPassword";
import ResetPasswordForm from "../../pages/authentication/pages/resetPassword";
import ConfirmationPage from "../../pages/authentication/pages/confirmation";
import PageSupport from "../../pages/support";
import PageNotFound from "../../pages/exceptions/pages/notFound";
import Backdrop from "../../components/backdrop";

import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default function RoutesPages() {
  const { user, authenticated, loading, loadingPage } = useContext(AuthContext);
  const organization = user ? user.organization?.name : undefined;

  const pages = user_permissions.pages.map((page) => ({
    path: `/${page.url}/:customerParam`,
    element: page.element,
    permission: page.permission,
  }));

  const handlePagePermission = (page, index) => {
    const userPermissionPages = user
      ? user.group?.permissions.pages
      : undefined;
    if (userPermissionPages?.includes(page.permission)) {
      return <Route key={index} path={page.path} element={page.element} />;
    }
  };

  const handlePageRedirect = () => {
    const userPermissionPages = user
      ? user.group?.permissions.pages
      : undefined;

    if (userPermissionPages) {
      for (const page of pages) {
        if (userPermissionPages.includes(page.permission)) {
          const newPath = page.path.replace(/[^/]+$/, organization);
          return newPath;
        }
      }
    }
    return "/support";
  };

  return (
    <>
      <Routes history={history}>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordForm />} />
        <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
        <Route
          path="/email-confirmation/:token"
          element={<ConfirmationPage />}
        />
        <Route element={<PrivateRoutes {...{ user, authenticated }} />}>
          <Route
            path="/"
            element={<Navigate replace to={handlePageRedirect()} />}
          />
          {user_permissions.pages.map((page) => (
            <Route
              key={page.url}
              path={`/${page.url}`}
              element={<Navigate replace to={`/${page.url}/${organization}`} />}
            />
          ))}
          {pages.map((page, index) => handlePagePermission(page, index))}
          <Route path="/support" element={<PageSupport {...{ user }} />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Backdrop loading={loading} loadingPage={loadingPage} />
    </>
  );
}
