import "./style.css";
import "../../responsive/NavBar-responsive.css";
import { Link } from "react-router-dom";
import { user_permissions } from "../../data/constants/permissions";
import logo_sensedia from "../../assets/images/sensedia-logo.png";
import logo_support_premium from "../../assets/images/support-premium-logo.png";

import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthContext";

export default function NavBar() {
  const { user, authenticated, handleLogOut } = useContext(AuthContext);
  const organization = user ? user.organization?.name : undefined;

  const pages = user_permissions.pages.map((page) => ({
    to: `/${page.url}/${organization}`,
    title: page.title,
    permission: page.permission,
  }));

  const handlePagePermission = (page, index) => {
    const permissionPages = user ? user.group?.permissions.pages : undefined;
    if (permissionPages?.includes(page.permission)) {
      return (
        <Link key={index} to={page.to} className="navbar-items">
          {page.title}
        </Link>
      );
    }
  };

  return (
    <header>
      <nav className="nav-bar">
        <div className="nav-links">
          <div className="navbar-logos">
            <Link to={`/`}>
              <img
                className="logo-sensedia"
                src={logo_sensedia}
                alt="logo-sensedia"
                title="Sensedia"
              />
            </Link>
            <Link to={`/`}>
              <img
                className="logo-support-premium"
                src={logo_support_premium}
                alt="Logo Support Premium"
                title="Sensedia Support Premium"
              />
            </Link>
          </div>

          {authenticated &&
            user.organization &&
            user.isActive &&
            user.isEmailConfirmed && (
              <div className="menu-section">
                <ul>
                  {pages.map((page, index) =>
                    handlePagePermission(page, index)
                  )}
                  <li>
                    <Link to="/support" className="navbar-items">
                      Support
                    </Link>
                  </li>
                </ul>
              </div>
            )}
        </div>
        {authenticated && (
          <div className="container-user-info">
            <span className="user-role">{user.role.name}</span>
            <button className="button-logout" onClick={() => handleLogOut()}>
              LogOut
            </button>
          </div>
        )}
      </nav>
    </header>
  );
}
