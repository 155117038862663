import { HiOutlineMail } from "react-icons/hi";
import { IconContext } from "react-icons";

export default function EmailIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <HiOutlineMail />
      </div>
    </IconContext.Provider>
  );
}
