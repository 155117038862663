import { useParams } from "react-router-dom";
import { customer_info } from "../../data/constants/clients";
import { isUserSensedia } from "../../utils/isUserSensedia";
import NavBar from "../../components/navbar";
import SectionStatusPage from "./section";
import PageNotFound from "../exceptions/pages/notFound";

import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthContext";

export default function PageStatusPage() {
  const { user } = useContext(AuthContext);

  var { customerParam } = useParams();

  //verifica o useParams de acordo com a organization do user
  function handleUseParams() {
    if (isUserSensedia(user)) {
      return customer_info.find((info) => info.href === customerParam);
    } else {
      return customerParam === user.organization?.name
        ? customer_info.find((info) => info.href === customerParam)
        : undefined;
    }
  }

  const customer = handleUseParams();

  if (!customer) {
    return <PageNotFound />;
  }

  return (
    <>
      <NavBar />
      <SectionStatusPage {...{ user, customer }} />
    </>
  );
}
