// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-apis{
    border-radius: 1em;
    width: 100%;
    font-size: 1.5em; 
}

.table-apis th{
    position: sticky;
    top: 0px;
    z-index: 1;
    padding: 1em 1.8em;
    text-align: center;
    color: var(--white);
    background-color: var(--primary-purple-sensedia);
}

.collapse-th{
    border-radius: 0.6em 0 0 0;
}

.severity-th{
    border-radius: 0 0.6em 0 0;
}

.collapse-td, .apiId-td, .apiName-td, .alert-td, .firing-date-td, .customer-td, .severity-td, .description-td{
    height: 3em;
    padding: 12px 10px;
    font-size: 1em;
    text-align: center;
    border-bottom: 1px solid var(--line-color);
}

.apiId-td, .customer-td, .collapse-td{
    text-align: center;
    font-weight: bold;
}

.button-collapse-td{
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: var(--primary-purple-sensedia);
}

.description-td{
    padding: 1.4em;
    font-weight: bold;
    font-size: 1em;
    border-bottom: 1px solid var(--primary-purple-sensedia);
}

.severity-td{
   position: relative;
   right: 0.4em;
   font-size: 1.5em;
}

.foot-table{
    width: 100%;
    height: 1.6px;
    border-radius: 0 0 1em 1em;
    background-color: var(--primary-purple-sensedia);
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/table/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,gDAAgD;AACpD;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,0CAA0C;AAC9C;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,uDAAuD;AAC3D;;AAEA;GACG,kBAAkB;GAClB,YAAY;GACZ,gBAAgB;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,0BAA0B;IAC1B,gDAAgD;AACpD","sourcesContent":[".table-apis{\n    border-radius: 1em;\n    width: 100%;\n    font-size: 1.5em; \n}\n\n.table-apis th{\n    position: sticky;\n    top: 0px;\n    z-index: 1;\n    padding: 1em 1.8em;\n    text-align: center;\n    color: var(--white);\n    background-color: var(--primary-purple-sensedia);\n}\n\n.collapse-th{\n    border-radius: 0.6em 0 0 0;\n}\n\n.severity-th{\n    border-radius: 0 0.6em 0 0;\n}\n\n.collapse-td, .apiId-td, .apiName-td, .alert-td, .firing-date-td, .customer-td, .severity-td, .description-td{\n    height: 3em;\n    padding: 12px 10px;\n    font-size: 1em;\n    text-align: center;\n    border-bottom: 1px solid var(--line-color);\n}\n\n.apiId-td, .customer-td, .collapse-td{\n    text-align: center;\n    font-weight: bold;\n}\n\n.button-collapse-td{\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    font-weight: bold;\n    color: var(--primary-purple-sensedia);\n}\n\n.description-td{\n    padding: 1.4em;\n    font-weight: bold;\n    font-size: 1em;\n    border-bottom: 1px solid var(--primary-purple-sensedia);\n}\n\n.severity-td{\n   position: relative;\n   right: 0.4em;\n   font-size: 1.5em;\n}\n\n.foot-table{\n    width: 100%;\n    height: 1.6px;\n    border-radius: 0 0 1em 1em;\n    background-color: var(--primary-purple-sensedia);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
