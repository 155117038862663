import "./style.css";
import AddIcon from "../../../assets/icons/AddIcon";

export default function AddPostButton({ index, postItem, handleAddRowItem }) {
  return (
    <button
      className={
        postItem.length - 1 === index
          ? "btn-addMore secondary-button"
          : "btn-addMore invisible"
      }
      onClick={handleAddRowItem}
      title="Add more"
    >
      <span className="icon-addMore">
        <AddIcon size={1} />
      </span>
    </button>
  );
}
