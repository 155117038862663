import "./style.css";
import FilterTotalMatch from "./totalMatch";
import FilterSearch from "./search";

export default function FilterSettings({
  type,
  data,
  search,
  setSearch,
  checkedInputValue,
}) {
  return (
    <div className="container-filter-settings">
      <FilterTotalMatch {...{ type, data, search, checkedInputValue }} />
      <FilterSearch {...{ search, setSearch }} />
    </div>
  );
}
