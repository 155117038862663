import PageDashBoard from "../../pages/dashboard";
import PageStatusPage from "../../pages/customerstatus";
import PageSettings from "../../pages/settings";
import PageReport from "../../pages/reports"
import UserIcon from "../../assets/icons/UserIcon";
import RoleIcon from "../../assets/icons/RoleIcon";
import OrganizationIcon from "../../assets/icons/OrganizationIcon";
import DomainIcon from "../../assets/icons/DomainIcon";
import GroupIcon from "../../assets/icons/GroupIcon";

//permissions
export const user_permissions = {
  pages: [
    {
      name: "alerts",
      url: "alerts",
      title: "Alerts",
      permission: "cGFnZTFfaGFzaDoxNzExOTkwODkxLXBhZ2UxX2hhc2gtMjkwMQ==",
      element: <PageDashBoard />,
    },
    {
      name: "apistatus",
      url: "api-status",
      title: "API Status",
      permission: "cGFnZTJfaGFzaDoxNzExOTkxMTk3LXBhZ2UyX2hhc2gtNDgxMw==",
      element: <PageStatusPage />,
    },
    // {
    //   name: "healthstatus",
    //   url: "health-status",
    //   title: "Health Status",
    //   permission: "cGFnZTNfaGFzaDoxNzExOTkxMjY3LXBhZ2UzX2hhc2gtMTAzOQ==",
    // },
    {
      name: "reports",
      url: "executive-report",
      title: "Reports",
      permission: "cGFnZTZfaGFzaDoxNzI2NzQ3MDQ3LXBhZ2U2X2hhc2gtNDkxMA==",
      element: <PageReport />,
    },
    {
      name: "adminsettings",
      url: "admin-settings",
      title: "Admin Settings",
      permission: "cGFnZTRfaGFzaDoxNzExOTkxNDIzLXBhZ2U0X2hhc2gtNTMyMQ==",
      element: <PageSettings />,
    },
    // {
    //   name: "charts",
    //   url: "charts",
    //   title: "Charts",
    //   permission: "cGFnZTVfaGFzaDoxNzExOTkxMjk2LXBhZ2U1X2hhc2gtNzkxMA==",
    // },
  ],
  settings_menu: [
    {
      name: "user",
      title: "User",
      icon: <UserIcon size={1} />,
      permission: "bWVudTFfaGFzaDoxNzExOTkxNzM0LW1lbnUxX2hhc2gtMjAxNg==",
    },
    {
      name: "role",
      title: "Role",
      icon: <RoleIcon size={1} />,
      onlySensedia: true,
      permission: "bWVudTJfaGFzaDoxNzExOTkxNzY5LW1lbnUyX2hhc2gtNjQyOQ==",
    },
    {
      name: "organization",
      title: "Organization",
      icon: <OrganizationIcon size={1} />,
      onlySensedia: true,
      permission: "bWVudTNfaGFzaDoxNzExOTkxODI1LW1lbnUzX2hhc2gtMzYyNA==",
    },
    {
      name: "domain",
      title: "Domain",
      icon: <DomainIcon size={1} />,
      permission: "bWVudTRfaGFzaDoxNzExOTkxODc0LW1lbnU0X2hhc2gtNjU5Mg==",
    },
    {
      name: "group",
      title: "Group",
      icon: <GroupIcon size={1} />,
      permission: "bWVudTVfaGFzaDoxNzExOTkxOTA4LW1lbnU1X2hhc2gtOTMyNw==",
    },
  ],
  actions: [
    {
      name: "create",
      title: "Create",
      permission: "YWN0aW9uMV9oYXNoOjE3MTE5OTE5OTAtYWN0aW9uMV9oYXNoLTY5MjE=",
    },
    {
      name: "update",
      title: "Update",
      permission: "YWN0aW9uMl9oYXNoOjE3MTE5OTIwMTUtYWN0aW9uMl9oYXNoLTM1Nzg=",
    },
    {
      name: "delete",
      title: "Delete",
      permission: "YWN0aW9uM19oYXNoOjE3MTE5OTIwNDMtYWN0aW9uM19oYXNoLTg1NDI=",
    },
  ],
};
