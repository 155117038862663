// import { GoPrimitiveDot } from "react-icons/go";
import { GoDotFill } from "react-icons/go";
import { IconContext } from "react-icons";
import { severity_informations } from "../../data/constants/severity";

export default function SeverityDotIcon({ severity, size }) {
  const color = severity_informations.find(
    (info) => info.severity === severity
  ).color;

  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <GoDotFill />
      </div>
    </IconContext.Provider>
  );
}
