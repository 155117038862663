import { formatDate } from "../../../../utils/formatDate";
import { headers } from "../../../../data/constants/headers";
import { useEffect, useState } from "react";

export default function DataInformations({ type, selectedItem }) {
  const [dataInfo, setDataInfo] = useState(selectedItem);

  useEffect(() => {
    setDataInfo(selectedItem);
  }, [selectedItem]);

  const allHeaders = headers.find((header) => header.type === type).headers;
  return (
    <ul>
      {allHeaders.map((header) => {
        return !header.hiddenModal ? (
          typeof dataInfo[header.key] !== "object" ? (
            typeof dataInfo[header.key] !== "boolean" ? (
              header.key !== "createdAt" ? (
                dataInfo[header.key] ? (
                  <li key={header.key}>
                    {header.label}: <strong>{dataInfo[header.key]}</strong>
                  </li>
                ) : undefined
              ) : (
                <li key={header.key}>
                  {header.label}:{" "}
                  <strong>{formatDate(dataInfo[header.key])}</strong>
                </li>
              )
            ) : (
              <li key={header.key}>
                {header.label}:{" "}
                <strong>{dataInfo[header.key] ? "true" : "false"}</strong>
              </li>
            )
          ) : dataInfo[header.key] ? (
            <li key={header.key}>
              {header.label}: <strong>{dataInfo[header.key]["name"]}</strong>
            </li>
          ) : undefined
        ) : undefined;
      })}
    </ul>
  );
}
