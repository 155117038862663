import "./style.css";

export default function InputText({
  type,
  info,
  setInfo,
  placeHolder,
  handleSubmit,
}) {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="wrap-input">
      <input
        type={type}
        value={info}
        onChange={({ target }) => setInfo(target.value)}
        className={info ? "has-value input" : "input"}
        onKeyDown={handleKeyDown}
      />
      <span className="focus-input" data-placeholder={placeHolder}></span>
    </div>
  );
}
