import "./style.css";
import { capitalize } from "@mui/material";
import { user_permissions } from "../../../../data/constants/permissions";
import { getPermission } from "../../../../utils/getPermission";
import { formatTitle } from "../../../../utils/formatTitle";
import ModalTemplate from "../..";
import useModalPermission from "../hooks/useModalPermission";
import IsCheckSuccessIcon from "../../../../assets/icons/IsCheckSuccessIcon";
import IsCheckFailIcon from "../../../../assets/icons/IsCheckFailIcon";

export default function ModalPermission({
  type,
  refKey,
  selectedItem,
  showPermission,
  setShowPermission,
  setLoadingPermission,
}) {
  const [permissionData, permissionKeys, icon, loading] = useModalPermission(
    type,
    selectedItem,
    refKey,
    showPermission
  );

  const handleClose = () => {
    setShowPermission(false);
    setTimeout(() => {
      setLoadingPermission(true);
    }, 200);
  };

  return loading ? undefined : (
    <ModalTemplate
      size={"lg"}
      color={"var(--light-purple-sensedia)"}
      showModal={showPermission}
      {...{ handleClose }}
    >
      <div className="header-modal-settings">
        <span style={{ fontSize: "2em" }}>{icon}</span>
        <p>{`${selectedItem["name"]}'s ${capitalize(refKey)}`}</p>
      </div>
      <div className="body-modal-settings">
        {permissionKeys.map((key) => (
          <div key={key} className="container-permissions">
            <div className="header-permission">{formatTitle(key)}</div>
            <div className="body-permission">
              {user_permissions[key].map((item) => (
                <div key={item.permission} className="body-item">
                  <span>
                    {permissionData[key].includes(
                      getPermission(key, item.name)
                    ) ? (
                      <IsCheckSuccessIcon
                        color={"rgb(110, 199, 110)"}
                        size={1.2}
                      />
                    ) : (
                      <IsCheckFailIcon color={"#D9534F"} size={1.3} />
                    )}
                  </span>
                  <span style={{ fontStyle: "italic" }}>
                    {capitalize(item.name)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ModalTemplate>
  );
}
