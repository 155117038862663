import "./style.css";

export default function FilterSearch({ search, setSearch }) {
  return (
    <form className="filter-form">
      <label htmlFor="filter">Filter:</label>
      <input
        type="search"
        name="filter"
        id="filter"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        required
      />
    </form>
  );
}
