import { Navigate, Outlet } from "react-router-dom";
import PageRequireOrg from "../../pages/exceptions/pages/requireOrg";
import PageNotConfirmed from "../../pages/exceptions/pages/notConfirmed";
import PageNotActive from "../../pages/exceptions/pages/notActive";

export default function PrivateRoutes({ user, authenticated }) {
  return authenticated ? (
    user.isActive ? (
      user.isEmailConfirmed ? (
        user.organization ? (
          <Outlet />
        ) : (
          <PageRequireOrg />
        )
      ) : (
        <PageNotConfirmed />
      )
    ) : (
      <PageNotActive />
    )
  ) : (
    <Navigate replace to={"/login"} />
  );
}
