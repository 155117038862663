import "./style.css";
import AuthSection from "../..";
import LoginLogo from "../../logo";
import LoginButton from "../../../../components/buttons/login";
import InputText from "../../input/InputText";
import email_sent from "../../../../assets/images/sent-email.png";

import { useContext } from "react";
import { AuthContext } from "../../../../store/context/AuthContext";

export default function ForgotPasswordForm() {
  const {
    email,
    setEmail,
    emailSent,
    setEmailSent,
    requiredField,
    setRequiredField,
    handleForgotPassword,
  } = useContext(AuthContext);

  function handleTryAgain() {
    setEmail("");
    setRequiredField(false);
    setEmailSent(false);
  }

  return (
    <AuthSection>
      <LoginLogo />
      {!emailSent ? (
        <>
          <p className="forgot-p">Reset your password</p>
          <div className="container-wrap-input">
            <InputText
              type="email"
              info={email}
              setInfo={setEmail}
              placeHolder={"Email"}
              handleSubmit={handleForgotPassword}
            />
          </div>
          <p
            className={
              requiredField ? "required-email" : "required-email invisible"
            }
          >
            Email is required.
          </p>

          <LoginButton text={"Send Email"} submit={handleForgotPassword} />
        </>
      ) : (
        <div className="container-email-sent">
          <h2>We have sent the instructions.</h2>
          <img src={email_sent} alt="Email Sent" />
          <p>Check your email to see how to reset your password.</p>
          <p>
            Haven't received the email?{" "}
            <span className="try-again-forgot" onClick={() => handleTryAgain()}>
              Try again
            </span>
          </p>
        </div>
      )}
    </AuthSection>
  );
}
