export function removeEmptyIdFields(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (obj[key]._id === "") {
        delete obj[key];
      } else if (obj[key]._id){
        obj[key] = obj[key]._id
      }
    }
  }
}
