// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-filter-settings{
    display: flex;
    column-gap: 2.6em;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/configuration/filter/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".container-filter-settings{\n    display: flex;\n    column-gap: 2.6em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
