import NavBar from "../../components/navbar";
import Report from "./section/index";

import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthContext";

export default function PageReport() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <NavBar />
      <Report {...{ user }} />
    </>
  );
}

