import { useParams } from "react-router-dom";
import AuthSection from "../..";
import LoginLogo from "../../logo";
import LoginButton from "../../../../components/buttons/login";
import InputPassword from "../../input/InputPassword";
import PageNotFound from "../../../exceptions/pages/notFound";

import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../store/context/AuthContext";

export default function ResetPassword() {
  var { token } = useParams();

  const {
    emailReset,
    password,
    setPassword,
    loading,
    handleResetPassword,
    getEmailByToken,
  } = useContext(AuthContext);

  // Validacao do token
  useEffect(() => {
    getEmailByToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!emailReset && !loading) {
    return <PageNotFound />;
  }

  return (
    <AuthSection>
      <LoginLogo />

      <p className="forgot-p">{emailReset}</p>
      <div className="container-wrap-input">
        <InputPassword
          info={password}
          setInfo={setPassword}
          placeHolder={"New Password"}
          handleSubmit={() => handleResetPassword(token)}
        />
      </div>

      <LoginButton
        text={"Update Password"}
        submit={() => handleResetPassword(token)}
      />
    </AuthSection>
  );
}
