import "./style.css";
import Backdrop from "../../components/backdrop";

export default function LoadingPage({ loading, loadingPage }) {
  return (
    <>
      <div className="container-loading-page"></div>
      <Backdrop loading={loading} loadingPage={loadingPage} logo={true} />
    </>
  );
}
