import { useState, useEffect, useCallback } from "react";
import api_executive_report from "../../../services/api/api-executive-report";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Grid,
  ThemeProvider,
  CircularProgress,
  Box,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "./theme";
import "./style.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customer_info } from "../../../data/constants/clients";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import Toast, { showNotification } from "../../../components/toastNotification";

const apiKey = process.env.REACT_APP_API_EXECUTIVE_REPORT_API_KEY;

// Estilos personalizados usando MUI System
const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const TableStyled = styled(Table)(({ theme }) => ({
  minWidth: 650,
  "& th, & td": {
    borderBottom: "1px solid #ddd",
    padding: theme.spacing(2),
  },
  "& th": {
    backgroundColor: "#f2f2f2",
    textAlign: "left",
  },
  "& tr:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const DownloadIconStyled = styled(DownloadIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function Report({ user }) {
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(
    user.organization.customer_id === "all" ? "" : user.organization.customer_id
  );
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [openDialog, setOpenDialog] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  // Função para calcular o intervalo dos últimos 3 meses
  const calculateInitialDates = () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    return { start: threeMonthsAgo, end: today };
  };

  const fetchReports = useCallback(
    async (start, end) => {
      setLoading(true); // Iniciar o carregamento
      try {
        const customer_id = selectedCustomer;
        const response = await api_executive_report.get(`/reports`, {
          params: {
            customer_id,
            start_date: start,
            end_date: end,
          },
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
        });

        if (response.status === 200) {
          if (Array.isArray(response.data.body)) {
            setReports(response.data.body);
          } else {
            setReports([]);
            showNotification("Erro: Dados inesperados recebidos.");
          }
        } else {
          const errorMessage = response.data.message || "Erro desconhecido";
          showNotification(`Erro: ${errorMessage}`);
          setReports([]); // Limpa a lista de relatórios em caso de erro
        }
      } catch (error) {
        showNotification("Erro ao buscar relatórios: " + error.message);
        setReports([]); // Limpa a lista de relatórios em caso de erro
      } finally {
        setLoading(false); // Finalizar o carregamento
      }
    },
    [selectedCustomer]
  );

  useEffect(() => {
    const { start, end } = calculateInitialDates();
    setStartDate(start);
    setEndDate(end);
    if (selectedCustomer) {
      fetchReports(format(start, "yyyy-MM"), format(end, "yyyy-MM"));
    }
  }, [user, fetchReports, selectedCustomer]);

  const handleFilter = () => {
    if (!startDate || !endDate) {
      showNotification("Por favor, selecione as datas de início e fim.");
      return;
    }

    if (endDate < startDate) {
      showNotification("A data final não pode ser menor que a data inicial.");
      return;
    }

    if (user.organization.customer_id === "all" && !selectedCustomer) {
      showNotification("Por favor, selecione um cliente.");
      return;
    }

    const start = startDate ? format(startDate, "yyyy-MM") : null;
    const end = endDate ? format(endDate, "yyyy-MM") : null;
    fetchReports(start, end);
  };

  const sanitizeFileName = (fileName) => {
    return fileName.replace(/[^a-z0-9]/gi, "_").toLowerCase();
  };

  const handleDownload = async (report_id, report_description) => {
    setLoading(true); // Iniciar o carregamento
    try {
      const response = await api_executive_report.get(`/report-data`, {
        params: {
          report_id,
          customer_id: selectedCustomer,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const sanitizedFileName = sanitizeFileName(report_description);
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${response.data.body}`;
        link.download = `${sanitizedFileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        showNotification("Relatório baixado com sucesso!");
      } else {
        const errorMessage = response.data.message || "Erro desconhecido";
        showNotification(`Erro: ${errorMessage}`);
      }
    } catch (error) {
      showNotification("Erro ao baixar relatório: " + error.message);
    } finally {
      setLoading(false); // Finalizar o carregamento
    }
  };

  const handleDeleteConfirmation = (report) => {
    setReportToDelete(report);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    if (!reportToDelete) return;
    setLoading(true); // Iniciar o carregamento
    try {
      const response = await api_executive_report.delete(`/reports`, {
        params: {
          report_id: reportToDelete.report_id,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        showNotification("Relatório excluído com sucesso!");
        setReports(
          reports.filter(
            (report) => report.report_id !== reportToDelete.report_id
          )
        );
      } else {
        const errorMessage = response.data.message || "Erro desconhecido";
        showNotification(`Erro: ${errorMessage}`);
      }
    } catch (error) {
      showNotification("Erro ao excluir relatório: " + error.message);
    } finally {
      setLoading(false); // Finalizar o carregamento
      setOpenDialog(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" className="section-body-reports">
        <Typography variant="h4" gutterBottom>
          Reports
        </Typography>

        <Toast />

        <Grid container spacing={2} alignItems="center" marginBottom={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <Grid item sm={3}>
              {user.organization.customer_id === "all" && (
                <FormControl>
                  <FormLabel sx={{ fontSize: "1.5em", pb: "0.7em" }}>
                    Customer
                  </FormLabel>
                  <Select
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.target.value)}
                    inputProps={{ sx: { fontSize: "2.7em", marginTop: "1em" } }}
                    className="input-select"
                  >
                    {customer_info.map((customer) => (
                      <MenuItem
                        key={customer.customer_id}
                        value={customer.customer_id}
                        sx={{ fontSize: "2.2em" }}
                      >
                        {customer.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item sm={3}>
              <FormControl>
                <FormLabel sx={{ fontSize: "1.5em", pb: "0.7em" }}>
                  Start Date
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: "2em" }}>
                        Este filtro serve para capturar relatórios com base na
                        data inicial utilizada para a geração do relatório.
                      </Typography>
                    }
                    placement="top"
                    arrow
                  >
                    <HelpOutlineIcon
                      sx={{ color: "grey.500", fontSize: "1em", ml: 1 }}
                    />
                  </Tooltip>
                </FormLabel>
                <DatePicker
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ fontSize: "2.6em" }} />
                  )}
                  inputFormat="dd/MM/yyyy"
                  mask="__/__/____"
                  className="input-select"
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl>
                <FormLabel sx={{ fontSize: "1.5em", pb: "0.7em" }}>
                  End Date
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: "2em" }}>
                        Este filtro serve para capturar relatórios com base na
                        data inicial utilizada para a geração do relatório.
                      </Typography>
                    }
                    placement="top"
                    arrow
                  >
                    <HelpOutlineIcon
                      sx={{ color: "grey.500", fontSize: "1em", ml: 1 }}
                    />
                  </Tooltip>
                </FormLabel>
                <DatePicker
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ fontSize: "2.6em" }} />
                  )}
                  inputFormat="dd/MM/yyyy"
                  mask="__/__/____"
                  className="input-select"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleFilter}
                style={{ marginTop: 8 }}
                sx={{
                  backgroundColor: "#673AB7",
                  color: "#ffffff",
                  border: "1px solid #673AB7",
                  "&:hover": {
                    backgroundColor: "#5e35b1",
                    borderColor: "#5e35b1",
                  },
                }}
              >
                Filter
              </Button>
            </Grid>
          </LocalizationProvider>
        </Grid>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainerStyled component={Paper} className="table">
            <TableStyled>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Report Description</TableCell>
                  {user.organization.customer_id === "all" && (
                    <TableCell>Generation Date</TableCell>
                  )}
                  <TableCell>Reference Month</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(reports) && reports.length > 0 ? (
                  reports.map((report, index) => (
                    <TableRow key={index}>
                      <TableCell>{report.report_description}</TableCell>
                      {user.organization.customer_id === "all" && (
                        <TableCell>
                          {new Date(
                            report.generation_date
                          ).toLocaleDateString()}
                        </TableCell>
                      )}
                      <TableCell>
                        {report.reference_month.split("-").reverse().join("/")}
                      </TableCell>
                      <TableCell>{report.details}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleDownload(
                              report.report_id,
                              report.report_description
                            )
                          }
                        >
                          <DownloadIconStyled />
                        </IconButton>
                        {user.organization.customer_id === "all" && (
                          <IconButton
                            onClick={() => handleDeleteConfirmation(report)}
                          >
                            <DeleteIconStyled />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      Nenhum relatório encontrado.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </TableStyled>
          </TableContainerStyled>
        )}

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle sx={{ fontSize: "3em" }}>Confirmação</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "2.2em" }}>
              Tem certeza de que deseja excluir este relatório? Esta ação não
              pode ser desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
              color="primary"
              sx={{ fontSize: "2.2em" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleDelete}
              color="secondary"
              autoFocus
              sx={{ fontSize: "2.2em" }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
