import "./style.css";
import "../../../responsive/Support-responsive.css";
import { customer_info } from "../../../data/constants/clients";
import { severity_informations } from "../../../data/constants/severity";
import AccordionItem from "../item";
import SeverityDot from "../../../assets/icons/SeverityDotIcon.jsx";

export default function Support({ user }) {
  return (
    <div className="section-body-support">
      <h1 className="support-title">Support</h1>
      <div className="container-accordion" id="accordionSupport">
        <AccordionItem
          {...{ user }}
          id={1}
          text="How it works?"
          onlySensedia={false}
        >
          <p>
            The following documentation is essential reading for those who want
            to use the <strong>Support Premium Dashboard</strong>. To access the
            complete documentation and deepen your knowledge about how the
            application operates,{" "}
            <a
              className="link-style"
              href="https://api-care.zendesk.com/hc/pt-br/articles/17865061314715-API-Care-Dashboard-Documenta%C3%A7%C3%A3o-do-Usu%C3%A1rio"
              rel="noreferrer"
              target="_blank"
            >
              click here
            </a>
            .
          </p>
          <p>
            For a better understanding of entity relationships on the Settings
            page, access the following{" "}
            <a
              className="link-style"
              href="https://api-care.zendesk.com/hc/pt-br/articles/17871379179803-API-Care-Dashboard-Acessos-e-Permiss%C3%B5es"
              rel="noreferrer"
              target="_blank"
            >
              documentation
            </a>{" "}
            about access and permissions in the application.
          </p>
        </AccordionItem>

        <AccordionItem
          {...{ user }}
          id={2}
          text="Which customers are monitored?"
          onlySensedia={true}
        >
          <ul className="list-clients">
            {customer_info.map((customer, index) => (
              <li className="clients-support" key={index}>
                <img
                  src={customer.logo}
                  alt={customer.alt}
                  title={customer.title}
                  width="130px"
                />
              </li>
            ))}
          </ul>
        </AccordionItem>

        <AccordionItem
          {...{ user }}
          id={3}
          text="What kind of alerts are triggered?"
          onlySensedia={false}
        >
          <p>Currently, there are 4 types:</p>
          <ul className="list-firing-alerts">
            {severity_informations.map((severity, index) => (
              <li key={index} className="li-type-alerts">
                <SeverityDot severity={severity.severity} size={1} />
                <span className="p-type-alerts">
                  {severity.severity[0].toUpperCase() +
                    severity.severity.substring(1)}
                </span>
              </li>
            ))}
          </ul>
        </AccordionItem>

        <AccordionItem
          {...{ user }}
          id={4}
          text="Want to know more about technical settings?"
          onlySensedia={true}
        >
          <p>
            To learn more about how the{" "}
            <strong>Support Premium Dashboard</strong> works, showing the
            current scenario at the code level and the step-by-step for any
            configurations and changes,{" "}
            <a
              className="link-style"
              href="https://sensedia.atlassian.net/wiki/spaces/ACE/pages/3306422317/Documenta+o+T+cnica"
              rel="noreferrer"
              target="_blank"
            >
              click here
            </a>
            . In it you will learn more about the technologies, dependencies,
            structure and possible configurations to be carried out.
          </p>
          <p>
            The application's main dependency is the{" "}
            <strong>Support Premium SSO Authentication</strong>, the api
            responsible for controlling access and permissions, in addition to
            consuming the database for use by the platform. To learn more about
            its structure,{" "}
            <a
              className="link-style"
              href="https://sensedia.atlassian.net/wiki/spaces/ACE/pages/3296886785/API+Care+SSO+Authentication"
              rel="noreferrer"
              target="_blank"
            >
              click here
            </a>
            .
          </p>
        </AccordionItem>
      </div>
    </div>
  );
}
