import "./style.css";
import Modal from "react-bootstrap/Modal";
import CloseModalButton from "../buttons/closemodal";

const ModalTemplate = ({
  children,
  size,
  color,
  showModal,
  centered,
  handleClose,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size={size} //sm (300px), none (500px), lg (800px), xl (1140px)
      keyboard={true}
      backdrop={true}
      centered={centered}
    >
      <div
        className="container-modal-template"
        style={{ border: `1px solid ${color}` }}
      >
        <CloseModalButton {...{ handleClose }} size={1} />
        {children}
      </div>
    </Modal>
  );
};

export default ModalTemplate;
