import "./style.css";
import { Link } from "react-router-dom";
import logo_support_services_vertical from "../../../assets/images/support-services-logo-vertical.png";

export default function LoginLogo({ unlink }) {
  return (
    <>
      {unlink ? (
        <img
          className="login-logo"
          src={logo_support_services_vertical}
          alt="Logo Support Premium"
        />
      ) : (
        <Link to={"/login"}>
          <img
            className="login-logo"
            src={logo_support_services_vertical}
            alt="Logo Support Premium"
          />
        </Link>
      )}
    </>
  );
}
