import "./style.css";
import { capitalize } from "@mui/material";
import ModalTemplate from "../..";
import useModalRef from "../hooks/useModalRef";
import FilterSettings from "../../../../pages/settings/configuration/filter";

export default function ModalRef({
  type,
  refKey,
  selectedItem,
  showRef,
  setShowRef,
  setLoadingRef,
}) {
  const [refHeaders, filteredData, search, setSearch, icon, loading] =
    useModalRef(type, selectedItem, refKey, showRef);

  const handleClose = () => {
    setShowRef(false);
    setTimeout(() => {
      setLoadingRef(true);
    }, 200);
  };

  return loading ? undefined : (
    <ModalTemplate
      size={"lg"}
      color={"var(--light-purple-sensedia)"}
      showModal={showRef}
      {...{ handleClose }}
    >
      <div className="header-modal-settings">
        <span style={{ fontSize: "2em" }}>{icon}</span>
        <p>{`${selectedItem["name"]}'s ${capitalize(refKey)}`}</p>
      </div>
      <div className="body-modal-settings">
        <div className="container-filter">
          <FilterSettings
            data={filteredData}
            {...{ type, search, setSearch }}
          />
        </div>
        {filteredData.length === 0 ? (
          <span className="noresults-ref">There is no results to show!</span>
        ) : (
          <div className="container-ref-table">
            <table className="ref-table">
              <thead className="fixed">
                <tr>
                  {refHeaders?.map((header) =>
                    header === "_id" ? undefined : (
                      <th
                        key={header}
                        style={{
                          width: `${100 / refHeaders.length - 1}%`,
                        }}
                      >
                        {capitalize(header)}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((info, index) => (
                  <tr
                    key={info._id}
                    className={index % 2 === 0 ? "bg-dif" : null}
                  >
                    {refHeaders?.map((header) =>
                      header === "_id" ? undefined : (
                        <td key={header}>
                          {info[header] ? info[header] : "-"}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </ModalTemplate>
  );
}
