import "./style.css";

export default function RowsPerPage({
  setItemOffset,
  itemsPerPage,
  setItemsPerPage,
}) {
  const rowsPerPage_values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];

  const changeRowsValue = (e) => {
    const { value } = e.target;
    setItemsPerPage(value);
    setItemOffset(0);
  };

  return (
    <div className="rows-per-page">
      <p>Rows per page:</p>
      <select
        name="rows"
        id="rows"
        value={itemsPerPage}
        onChange={changeRowsValue}
      >
        {rowsPerPage_values.map((rows, index) => (
          <option key={index} value={rows}>
            {rows}
          </option>
        ))}
      </select>
    </div>
  );
}
