import { useContext } from "react";
import { Link } from "react-router-dom";
import AuthSection from "../..";
import LoginLogo from "../../logo";
import LoginButton from "../../../../components/buttons/login";
import InputText from "../../input/InputText";
import InputPassword from "../../input/InputPassword";

import { AuthContext } from "../../../../store/context/AuthContext";

export default function RegisterForm() {
  const {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    registerUser,
  } = useContext(AuthContext);

  return (
    <AuthSection>
      <LoginLogo />

      <div className="container-wrap-input">
        <InputText
          type="text"
          info={name}
          setInfo={setName}
          placeHolder={"Username"}
          handleSubmit={registerUser}
        />
        <InputText
          type="email"
          info={email}
          setInfo={setEmail}
          placeHolder={"Email"}
          handleSubmit={registerUser}
        />
        <InputPassword
          info={password}
          setInfo={setPassword}
          placeHolder={"Password"}
          handleSubmit={registerUser}
        />
      </div>

      <LoginButton text={"Sign up"} submit={registerUser} />

      <span className="register-text">
        Already have an account? <Link to="/login">Sign in</Link>
      </span>
    </AuthSection>
  );
}
