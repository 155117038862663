// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infochecked-actions .deleteAll-button{
    padding: 0.1em 0.75em 0.1em 0.6em;
    border-radius: 0.2em;
    background-color: var(--delete-color);
    color: var(--white);
    border: 1px solid var(--delete-color);
    
    display: flex;
    column-gap: 0.4em
}

.infochecked-actions .deleteAll-button .deleteAll-icon{
    position: relative;
    bottom: 0.05em;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/deleteAll/style.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,oBAAoB;IACpB,qCAAqC;IACrC,mBAAmB;IACnB,qCAAqC;;IAErC,aAAa;IACb;AACJ;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".infochecked-actions .deleteAll-button{\n    padding: 0.1em 0.75em 0.1em 0.6em;\n    border-radius: 0.2em;\n    background-color: var(--delete-color);\n    color: var(--white);\n    border: 1px solid var(--delete-color);\n    \n    display: flex;\n    column-gap: 0.4em\n}\n\n.infochecked-actions .deleteAll-button .deleteAll-icon{\n    position: relative;\n    bottom: 0.05em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
