import { useParams } from "react-router-dom";
import { select_clients } from "../../data/constants/clients";
import PageNotFound from "../exceptions/pages/notFound";
import NavBar from "../../components/navbar";
import Settings from "./section";

import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthContext";

export default function PageSettings() {
  const { user } = useContext(AuthContext);

  var { customerParam } = useParams();

  //verifica o useParams de acordo com a organization do user
  function isOrganization() {
    if (
      customerParam === user.organization?.name &&
      select_clients.includes(user.organization?.name)
    ) {
      return true;
    }
  }

  if (!isOrganization()) {
    return <PageNotFound />;
  }

  return (
    <>
      <NavBar />
      <Settings />
    </>
  );
}
