import { useContext, useState } from "react";
import { user_permissions } from "../../../data/constants/permissions";

import { AuthContext } from "../../../store/context/AuthContext";
import { isUserSensedia } from "../../../utils/isUserSensedia";

export default function useHandleSettingsPage() {
  const { user } = useContext(AuthContext); //usuario atual da sessao

  const menus = user_permissions.settings_menu.map((menu) => ({
    name: menu.name,
    icon: menu.icon,
    onlySensedia: menu.onlySensedia,
    permission: menu.permission,
  }));

  const handleMenuPermission = (menu) => {
    const permissionMenu = user
      ? user.group?.permissions.settings_menu
      : undefined;

    if (menu.onlySensedia && !isUserSensedia(user)) {
      return false;
    }

    if (permissionMenu?.includes(menu.permission)) {
      return true;
    }
  };

  const [currentMenu, setCurrentMenu] = useState(menus[0].name); //User default

  return [menus, currentMenu, setCurrentMenu, handleMenuPermission];
}
