import { useContext, useEffect, useState } from "react";
import { showNotification } from "../../../components/toastNotification";
import { removeEmptyIdFields } from "../../../utils/removeEmptyIdFields";
import { types } from "../../../data/constants/types";
import { handleSelectOptions } from "../../../utils/handleSelectOptions";
import { isUserSensedia } from "../../../utils/isUserSensedia";
import { user_permissions } from "../../../data/constants/permissions";
import api_authentication from "../../../services/api/api-authentication";

import { AuthContext } from "../AuthContext";

export default function useSettings(type) {
  const { user } = useContext(AuthContext); //usuario atual da sessao

  const [data, setData] = useState([]); //array de dados vindo do backend
  const [updateData, setUpdateData] = useState(false); //state que atualiza a tabela com qualquer mudança
  const [selectOptions, setSelectOptions] = useState([]); //state de options para uso em select post e update
  const [selectOptionsLoading, setSelectOptionsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSelectOptions = async () => {
      if (user?.organization) {
        setSelectOptionsLoading(true);
        try {
          const options = await handleSelectOptions(user);
          setSelectOptions(options);
        } catch (error) {
          showNotification(error);
        } finally {
          setSelectOptionsLoading(false);
        }
      }
    };

    if (user?.organization) {
      fetchSelectOptions();
    }
  }, [user, data]);

  async function getData() {
    await api_authentication
      .get(
        isUserSensedia(user)
          ? `/${type}s`
          : type === "role" || type === "organization"
          ? `/${type}s`
          : `/${type}s/organization/${user.organization._id}`
      )
      .then((response) => {
        setData(response.data[`${type}s`]);
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function tryToGetData() {
    if (user?.organization) {
      const permission = user_permissions.settings_menu.find(
        (menu) => menu.name === type
      ).permission;
      if (user.group?.permissions.settings_menu.includes(permission)) {
        getData();
      } else {
        showNotification(`Don't have permissions to get ${type}s`);
      }
    }
  }

  async function adminRegisterItem(index, item, handleRemoveRowItem) {
    await api_authentication
      .post(
        type === types.settings_menu?.user
          ? "/auth/register-admin"
          : `/${type}s`,
        item
      )
      .then((response) => {
        setUpdateData(!updateData);
        handleRemoveRowItem(index, item);
        showNotification(response.data.message);
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      });
  }

  async function updateItemById(id, item, handleClose) {
    await removeEmptyIdFields(item);
    if (type === "user") {
      delete item.organization;
    }
    await api_authentication
      .put(`/${type}s/${id}`, item)
      .then((response) => {
        showNotification(response.data.message);
        setUpdateData(!updateData);
        handleClose();
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      });
  }

  async function deleteItemById(id, handleClose, allItemsSelected) {
    await api_authentication
      .delete(`/${type}s/${id}`)
      .then((response) => {
        !allItemsSelected && showNotification(response.data.message);
        setUpdateData(!updateData);
        handleClose();
      })
      .catch((error) => {
        !allItemsSelected && showNotification(error.response.data.error);
        throw error; //capturado em deleteAllItemsSelected
      });
  }

  async function deleteAllItemsSelected(selectedItems, handleClose) {
    const allItemsSelected = true;
    var successCount = 0;

    const hasSelectedItemWithUser = selectedItems.some(
      (item) => item._id === user._id
    );

    if (hasSelectedItemWithUser) {
      return showNotification("Can not delete your own user");
    } else {
      try {
        await Promise.all(
          selectedItems.map(async (item) => {
            try {
              await deleteItemById(item._id, handleClose, allItemsSelected);
              successCount++;
            } catch {}
          })
        );

        if (successCount > 0) {
          showNotification(`${successCount} ${type}(s) deleted`);
        }

        if (successCount < selectedItems.length) {
          showNotification(`Error deleting some ${type}`);
        }
      } catch (error) {
        showNotification(`Error deleting selected ${type}s`);
      }
    }
  }

  return {
    data,
    setData,
    updateData,
    loading,
    setLoading,
    selectOptions,
    selectOptionsLoading,
    tryToGetData,
    adminRegisterItem,
    updateItemById,
    deleteItemById,
    deleteAllItemsSelected,
  };
}
