function checkLabels(log, validations) {
  for (var i = 0; i < validations.length; i++) {
    if (!(validations[i] in log.labels)) {
      return false;
    }
  }
  return true;
}

export const checkFieldsInResponse = (log, validations) => {
  return (
    "activeAt" in log &&
    "description" in log.annotations &&
    checkLabels(log, validations)
  );
};
