// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast_container{
    font-size: 1.6rem;
}

.toast-body-message{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8em;
}

.icon-info{
    padding-bottom: 4px;
}

.toast-message{
    font-size: 0.8em;
}

.toast-progress-bar{
    background: #8b68c9!important;
    height: 3px!important;
}`, "",{"version":3,"sources":["webpack://./src/components/toastNotification/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,qBAAqB;AACzB","sourcesContent":[".toast_container{\n    font-size: 1.6rem;\n}\n\n.toast-body-message{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 0.8em;\n}\n\n.icon-info{\n    padding-bottom: 4px;\n}\n\n.toast-message{\n    font-size: 0.8em;\n}\n\n.toast-progress-bar{\n    background: #8b68c9!important;\n    height: 3px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
