import { TbExchange } from "react-icons/tb";
import { IconContext } from "react-icons";

export default function ChangeCustomerIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <TbExchange />
      </div>
    </IconContext.Provider>
  );
}
