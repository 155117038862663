// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-menu-box{
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3em;
    font-size: 2.6em;
}

.box-data-configuration{
    display: flex;
    min-width: 10em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.refresh-config-box{
    width: 100%;
    font-size: 1em;
    margin-top: 2em;
    margin-bottom: 2.6em;
    padding: 0px 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/components/leftmenu/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,eAAe;IACf,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".left-menu-box{\n    display: flex;\n    height: 100%;\n    flex-direction: row;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 0.3em;\n    font-size: 2.6em;\n}\n\n.box-data-configuration{\n    display: flex;\n    min-width: 10em;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.refresh-config-box{\n    width: 100%;\n    font-size: 1em;\n    margin-top: 2em;\n    margin-bottom: 2.6em;\n    padding: 0px 0.8em;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 0.8em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
