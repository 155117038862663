import "./style.css";
import { user_permissions } from "../../../../data/constants/permissions";
import { types } from "../../../../data/constants/types";
import { formatTitle } from "../../../../utils/formatTitle";
import InputField from "../../../../pages/settings/input";
import PagesIcon from "../../../../assets/icons/PagesIcon";
import CollectionsIcon from "../../../../assets/icons/CollectionsIcon";
import CrudIcon from "../../../../assets/icons/CrudIcon";

const permissions_icons = [
  { type: types.permissions.pages, icon: <PagesIcon size={0.98} /> },
  {
    type: types.permissions.settings_menu,
    icon: <CollectionsIcon size={0.98} />,
  },
  { type: types.permissions.actions, icon: <CrudIcon size={0.98} /> },
];

export default function PermissionPutFields({
  putItem,
  handleOnChangePermission,
  isDisabled,
}) {
  const permissions = Object.keys(user_permissions);
  return (
    <>
      {permissions.map((header) => (
        <li
          key={header}
          className={header !== "pages" ? (isDisabled ? "disabled" : "") : ""}
        >
          <label htmlFor={`${header}`}>{formatTitle(header)}</label>
          <div className="put-input">
            <span className="put-icon-field">
              {permissions_icons.find((info) => info.type === header)?.icon}
            </span>
            <div className="put-input-permissions-field">
              <InputField
                {...{ header, isDisabled }}
                data={putItem}
                handleOnChange={handleOnChangePermission}
                permission={true}
              />
            </div>
          </div>
        </li>
      ))}
    </>
  );
}
