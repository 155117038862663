import "./style.css";
import { useEffect, useState, useRef } from "react";
import { onClickReset, lastUpdate } from "../../../components/timer";
import LeftMenu from "../../../components/leftmenu";
import FadeLoader from "react-spinners/FadeLoader";
import StatusPage from "../display";
import Toast, { showNotification } from "../../../components/toastNotification";
import api_threshold_rules from "../../../services/api/api-threshold-rules";

export default function SectionStatusPage({ user, customer }) {
  const Ref = useRef(null);

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState("");
  const [configTime, setConfigTime] = useState("01:00"); //default 1 minute
  const [timer, setTimer] = useState("");

  const severities = ["critical", "high", "warning"];

  //API Threshold
  async function getThreshold(customer) {
    setLogs([]);
    setLoading(true);

    var logsToSet = [];

    if (customer.customer_id !== "all") {
      for (var severity of severities) {
        setLoading(true);
        await api_threshold_rules
          .get(
            `/api/thresholdsfiring/${customer.customer_id}?severity=${severity}&page=1&page_size=100`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "auth-header": customer.authHeader,
              },
            }
          )
          .then((response) => {
            if (response.data !== 401) {
              for (const log of response.data.results) {
                logsToSet.push({
                  id: log.labels.id,
                  name: log.labels.name,
                  severity: log.labels.severity,
                  firing_date: log.activeAt,
                  description: log.annotations.description,
                });
              }
            } else {
              logsToSet.push();
            }
          })
          .catch((error) => {
            showNotification("Error in request to backend");
          })
          .finally(() => {
            setLoading(false);
            lastUpdate(setCurrentDate);
            onClickReset(setTimer, Ref, configTime);
          });
      }
      setLogs(logsToSet);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getThreshold(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getThreshold(customer);
  //   }, buildConfigTime(configTime) - 100); //atraso de 100 ms para evitar conflito entre o outro useEffect
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [configTime, customer]);

  useEffect(() => {
    if (timer === "00:00:00") {
      getThreshold(customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <div className="section-body-statuspage">
      <div className="statuspage-customer">
        <LeftMenu
          {...{
            user,
            customer,
            logs,
            configTime,
            setConfigTime,
            timer,
            currentDate,
          }}
          hiddenTimer={true}
          getThreshold={() => getThreshold(customer)}
        />

        {loading ? (
          <div className="container-loading-icon">
            <FadeLoader color="#673ab7" loading={loading} radius={50} />
          </div>
        ) : (
          <div className="statuspage-alerts">
            <StatusPage {...{ customer, logs }} />
          </div>
        )}
      </div>
      <Toast />
    </div>
  );
}
