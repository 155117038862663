import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../components/toastNotification";
import api_authentication from "../../../services/api/api-authentication";

export default function useAuth() {
  const navigate = useNavigate();
  //Form States
  const [userNameOrEmail, setUserNameOrEmail] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailReset, setEmailReset] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [requiredField, setRequiredField] = useState(false);
  //User Authentication States
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  //API Calling states
  const [timeout] = useState(10000); //Duração limite em ms das request para API Authentication (10 seg)
  const [loading, setLoading] = useState(true); //Usado para consultas a API
  const [loadingPage, setLoadingPage] = useState(true); //true enquanto token esta sendo validado ao refresh page

  //Verifica se ha um token no localStorage. Setta como default o token sendo passado no header
  useEffect(() => {
    api_authentication.defaults.headers.authheader =
      process.env.REACT_APP_AUTH_HEADER;
    const token = localStorage.getItem("token");
    if (token) {
      api_authentication.defaults.headers.authorization = `Bearer ${JSON.parse(
        token
      )}`;
      verifyUserByToken(token);
    } else {
      setUser(null);
      setAuthenticated(false);
      setLoading(false);
      setLoadingPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Funcao auxiliar para timeout das requests
  function newAbortSignal(timeoutMs) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
    return abortController.signal;
  }

  //Valida token em localStorage, persistindo o user
  async function verifyUserByToken(token) {
    const userId = JSON.parse(atob(token.split(".")[1])).id; //atob() function to decode token into a string. Parse to JSON
    await api_authentication
      .get(`/users/${userId}`, { signal: newAbortSignal(timeout) })
      .then((response) => {
        setUser(response.data.user);
        setAuthenticated(true);
      })
      .catch((error) => {
        setUser(null);
        setAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
        setLoadingPage(false);
      });
  }

  async function registerUser() {
    setLoading(true);
    await api_authentication
      .post("/auth/register", {
        name,
        email,
        password,
        role: "64c1887f63b7fd78f3dae5e6", //user role
      })
      .then((response) => {
        navigate("/login");
        setTimeout(() => showNotification(response.data.message), 500);
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //Funcao de login authentication do user
  async function handleLogin() {
    setLoading(true);
    await api_authentication
      .post("/auth/login", {
        userNameOrEmail,
        password,
        rememberMe,
      })
      .then((response) => {
        const user = response.data.user;
        const token = response.data.token;
        if (user.isFirstAccess) {
          navigate(`/reset-password/${token}`);
        } else {
          localStorage.setItem("token", JSON.stringify(token));
          api_authentication.defaults.headers.authorization = `Bearer ${token}`;
          setUser(user);
          setAuthenticated(true);
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          showNotification("Error 503 - Service Unavailable");
        } else {
          showNotification(error.response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //Funcao de logout
  function handleLogOut() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    api_authentication.defaults.headers.authorization = undefined;
    navigate("/login");
  }

  //Funcao handle checkbox de rememberMe
  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  //Funcao de ForgotPassword
  async function handleForgotPassword() {
    if (email === "") {
      setRequiredField(true);
    } else {
      setLoading(true);
      await api_authentication
        .post("/email/forgot-password", {
          email,
        })
        .then((response) => {
          setEmailSent(true);
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            showNotification("Error 503 - Service Unavailable");
          } else {
            showNotification(error.response.data.error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  //Funcao de ResetPassword (need the new password)
  async function handleResetPassword(token) {
    setLoading(true);
    const params = {
      token,
      password,
    };
    await api_authentication
      .post("/email/reset-password", params)
      .then((response) => {
        navigate("/login");
        setTimeout(() => showNotification(response.data.message), 500);
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      })
      .finally((response) => {
        setLoading(false);
      });
  }

  //Confirma o email do user
  function handleEmailConfirmation(token) {
    setLoading(true);
    api_authentication
      .get(`/email/confirm/${token}`)
      .then((response) => {
        showNotification(response.data.message);
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      })
      .finally(() => {
        setTimeout(() => navigate("/login"), 5000);
        setLoading(false);
      });
  }

  //Retorna o email de acordo com o token passado como query param
  function getEmailByToken(token) {
    setLoading(true);
    api_authentication
      .get(`/email/get-email/${token}`)
      .then((response) => {
        setEmailReset(response.data.email);
      })
      .catch((error) => {
        showNotification(error.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    userNameOrEmail,
    setUserNameOrEmail,
    name,
    setName,
    email,
    setEmail,
    emailReset,
    setEmailReset,
    emailSent,
    setEmailSent,
    password,
    setPassword,
    rememberMe,
    handleRememberMe,
    requiredField,
    setRequiredField,
    user,
    authenticated,
    loading,
    loadingPage,
    registerUser,
    handleLogin,
    handleLogOut,
    handleForgotPassword,
    handleResetPassword,
    handleEmailConfirmation,
    getEmailByToken,
  };
}
