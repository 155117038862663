// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-logo-cliente{
    width: 100%;
    height: 8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1.3em;
    background: var(--white);
    box-shadow: 0px 0px 5px 1px var(--box-shadow-light);
}`, "",{"version":3,"sources":["webpack://./src/components/leftmenu/logo/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,wBAAwB;IACxB,mDAAmD;AACvD","sourcesContent":[".box-logo-cliente{\n    width: 100%;\n    height: 8em;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-radius: 1.3em;\n    background: var(--white);\n    box-shadow: 0px 0px 5px 1px var(--box-shadow-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
