import "./style.css";
import TableSettings from "../table";
import ConfigurationMenu from "../configuration";
import InfoChecked from "../infoChecked";
import { handleFilter } from "../configuration/filter/hooks/useFilterSearch";

import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../../store/context/SettingsContext";

export default function InfoSettings({ type }) {
  const { data, updateData, tryToGetData, loading, setLoading } =
    useContext(SettingsContext);

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]); //usuarios filtrados pelo filterSettings
  const [checkedInputValue, setCheckedInputValue] = useState([]); //usuarios selecionados na tabela
  const [checkedInputId, setCheckedInputId] = useState([]); //salva os ids com checked para alterar o array de infos (isChecked === true)

  useEffect(() => {
    tryToGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    //espera a requisicao ser realizada para settar o novo filtro
    if (!loading) {
      setFilteredData(handleFilter(type, data, search, checkedInputValue));
    }
  }, [type, data, search, checkedInputValue, loading, setFilteredData]);

  return (
    <>
      {!loading ? (
        <div className="settings-informations">
          <ConfigurationMenu
            {...{
              type,
              data,
              tryToGetData,
              search,
              setSearch,
              setLoading,
              checkedInputValue,
            }}
          />
          <div className="container-table">
            <TableSettings
              {...{
                type,
                search,
                filteredData,
                checkedInputId,
                setCheckedInputId,
                setCheckedInputValue,
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {checkedInputValue.length !== 0 && !loading ? (
        <InfoChecked
          {...{ type, checkedInputValue, checkedInputId, setCheckedInputId }}
        />
      ) : null}
    </>
  );
}
