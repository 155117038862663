// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-checkbox{
    display: flex;
    column-gap: 0.6em;
}

.checkbox-item{
    display: flex;
    justify-content: flex-start;
    column-gap: 0.6em;
}

.checkbox-item div{
    font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/input/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container-checkbox{\n    display: flex;\n    column-gap: 0.6em;\n}\n\n.checkbox-item{\n    display: flex;\n    justify-content: flex-start;\n    column-gap: 0.6em;\n}\n\n.checkbox-item div{\n    font-size: 0.9em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
