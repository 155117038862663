// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-addMore{
    width: 1em;
    height: 1em;
    border: none;
    outline: 0;
    background-color: inherit;
    color: var(--primary-purple-sensedia)!important;
    position: relative;
    top: 0.6em;
}

.btn-addMore .icon-addMore{
    position: relative;
    bottom: 0.3em;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/addPost/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,+CAA+C;IAC/C,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".btn-addMore{\n    width: 1em;\n    height: 1em;\n    border: none;\n    outline: 0;\n    background-color: inherit;\n    color: var(--primary-purple-sensedia)!important;\n    position: relative;\n    top: 0.6em;\n}\n\n.btn-addMore .icon-addMore{\n    position: relative;\n    bottom: 0.3em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
