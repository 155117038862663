// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-configtimer{
    width: 100%;
    height: 2em;
    max-height: 2em;
}`, "",{"version":3,"sources":["webpack://./src/components/configtimer/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,eAAe;AACnB","sourcesContent":[".container-configtimer{\n    width: 100%;\n    height: 2em;\n    max-height: 2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
