import { BiCollection } from "react-icons/bi";
import { IconContext } from "react-icons";

export default function CollectionsIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <BiCollection />
      </div>
    </IconContext.Provider>
  );
}
