// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1300px){
    .table-apis{
        font-size: 1.6em; 
    }
}`, "",{"version":3,"sources":["webpack://./src/responsive/Dashboard-responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media screen and (max-width: 1300px){\n    .table-apis{\n        font-size: 1.6em; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
