import "./style.css";
import { capitalize } from "@mui/material";
import ModalTemplate from "../..";
import AlertHigh from "../../../../assets/icons/AlertHighIcon";
import DataInformations from "../datainfo";
import HandleModalButton from "../../../buttons/handlemodal";

import { useContext } from "react";
import { SettingsContext } from "../../../../store/context/SettingsContext";

export default function ModalDelete({
  type,
  selectedItem,
  showDelete,
  setShowDelete,
}) {
  const { deleteItemById } = useContext(SettingsContext);

  const handleClose = () => setShowDelete(false);

  function handleDelete(id) {
    deleteItemById(id, handleClose);
  }

  return (
    <ModalTemplate
      color={"var(--delete-color)"}
      showModal={showDelete}
      {...{ handleClose }}
    >
      <div
        className="header-modal-settings"
        style={{ color: "var(--delete-color)" }}
      >
        <span style={{ position: "relative", top: "0em" }}>
          <AlertHigh size={2} />
        </span>
        <p>Delete {capitalize(type)}</p>
      </div>

      <div className="body-modal-settings" style={{ padding: "2em 3em" }}>
        <span>
          Are you sure you want to delete?
          <br />
          {capitalize(type)} Informations:
        </span>
        <DataInformations {...{ type, selectedItem }} />
      </div>

      <div className="footer-modal-settings">
        <HandleModalButton
          text="confirm"
          color="var(--delete-color)"
          id={selectedItem._id}
          handleAction={handleDelete}
        />
        <HandleModalButton text="cancel" handleAction={handleClose} />
      </div>
    </ModalTemplate>
  );
}
