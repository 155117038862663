// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal Close Button */

.close-modal-button{
    width: 2em;
    height: 2em;
    background-color: inherit;
    border: none;
    color: var(--grey);

    position: absolute;
    right: 16px;
    top: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/closemodal/style.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;;IAElB,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb","sourcesContent":["/* Modal Close Button */\n\n.close-modal-button{\n    width: 2em;\n    height: 2em;\n    background-color: inherit;\n    border: none;\n    color: var(--grey);\n\n    position: absolute;\n    right: 16px;\n    top: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
