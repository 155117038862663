// Icons
import AlertCritical from "../../assets/icons/AlertCriticalIcon";
import AlertHigh from "../../assets/icons/AlertHighIcon.jsx";
import AlertWarning from "../../assets/icons/AlertWarningIcon";
import CheckSuccess from "../../assets/icons/CheckSuccessIcon";

//info das severities
export const severity_informations = [
  {
    severity: "critical",
    color: "red",
    message: "API Major Outage",
    message_color: "#FFF",
    icon: <AlertCritical />,
  },
  {
    severity: "high",
    color: "orange",
    message: "API Minor Outage",
    message_color: "#FFF",
    icon: <AlertHigh />,
  },
  {
    severity: "warning",
    color: "yellow",
    message: "API Warning Outage",
    message_color: "#000",
    icon: <AlertWarning />,
  },
  {
    severity: "operational",
    color: "rgb(110, 199, 110)",
    message: "API Operational",
    message_color: "#FFF",
    icon: <CheckSuccess />,
  },
];
