import "./style.css";
import { customer_info } from "../../../../data/constants/clients";
import { headers } from "../../../../data/constants/headers";
import { formatDate } from "../../../../utils/formatDate";
import { getPermission } from "../../../../utils/getPermission";
import ActionSettingsButton from "../../../../components/buttons/actionSettings";
import EditIcon from "../../../../assets/icons/EditIcon";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import UnpinIcon from "../../../../assets/icons/UnpinIcon";

export default function checkedCard({
  user,
  type,
  checkedValue,
  checkedInputId,
  setCheckedInputId,
  setSelectedItem,
  setLoadingPut,
  setShowPut,
  setShowDelete,
}) {
  function handleUnpin(checkedValue) {
    const newCheckedIds = [...checkedInputId];
    let index = newCheckedIds.indexOf(checkedValue._id);
    newCheckedIds.splice(index, 1);
    setCheckedInputId(newCheckedIds);
  }

  function handlePut(checkedValue) {
    setSelectedItem(checkedValue);
    setShowPut(true);
    setLoadingPut(false);
  }

  function handleDelete(checkedValue) {
    setSelectedItem(checkedValue);
    setShowDelete(true);
  }

  const organization = checkedValue.organization;
  const allHeaders = headers.find((header) => header.type === type).headers;
  const currentClient = customer_info.find(
    (info) =>
      info.href === (organization ? organization.name : checkedValue.name)
  );

  return (
    <>
      <div className="header-info-checkcard">
        <span>{checkedValue.name}</span>
        <img
          src={currentClient?.logo}
          alt={currentClient?.alt}
          style={{ width: `${currentClient?.width_checkCard}em` }}
        />
      </div>
      <div className="body-info-checkcard">
        {allHeaders.map((header) => {
          return !header.hiddenCard ? (
            typeof checkedValue[header.key] !== "object" ? (
              typeof checkedValue[header.key] !== "boolean" ? (
                header.key !== "createdAt" ? (
                  checkedValue[header.key] ? (
                    <div key={header.key}>
                      {header.label}: {checkedValue[header.key]}
                    </div>
                  ) : undefined
                ) : (
                  <div key={header.key}>
                    {header.label}: {formatDate(checkedValue[header.key])}
                  </div>
                )
              ) : (
                <div key={header.key}>
                  {header.label}: {checkedValue[header.key] ? "true" : "false"}
                </div>
              )
            ) : checkedValue[header.key] ? (
              <div key={header.key}>
                {header.label}: {checkedValue[header.key]["name"]}
              </div>
            ) : undefined
          ) : undefined;
        })}
      </div>
      <div className="footer-info-checkcard">
        <ActionSettingsButton
          icon={<UnpinIcon size={1.3} />}
          item={checkedValue}
          color={"#000000"}
          handleAction={handleUnpin}
        />
        <span
          className={
            !user?.group?.permissions.actions.includes(
              getPermission("actions", "update")
            )
              ? "disabled"
              : undefined
          }
        >
          <ActionSettingsButton
            icon={<EditIcon size={1.3} />}
            item={checkedValue}
            color={"#000000"}
            handleAction={handlePut}
          />
        </span>
        <span
          className={
            !user?.group?.permissions.actions.includes(
              getPermission("actions", "delete")
            ) || checkedValue._id === user._id
              ? "disabled"
              : undefined
          }
        >
          <ActionSettingsButton
            icon={<DeleteIcon size={1.3} />}
            item={checkedValue}
            color={"#000000"}
            handleAction={handleDelete}
          />
        </span>
      </div>
    </>
  );
}
