// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 2501px) {
    .toast_container{
        font-size: 1.9rem;
    }
}

@media screen and (min-width: 2201px) and (max-width: 2500px) {
    .toast_container{
        font-size: 1.8rem;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2200px) {
    .toast_container{
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 1360px){
    .toast_container{
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1120px){
    .toast_container{
        font-size: 1.45rem;
    }
}

@media screen and (max-width: 965px){
    .toast_container{
        font-size: 1.35rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/responsive/Toast-responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["@media screen and (min-width: 2501px) {\n    .toast_container{\n        font-size: 1.9rem;\n    }\n}\n\n@media screen and (min-width: 2201px) and (max-width: 2500px) {\n    .toast_container{\n        font-size: 1.8rem;\n    }\n}\n\n@media screen and (min-width: 1921px) and (max-width: 2200px) {\n    .toast_container{\n        font-size: 1.7rem;\n    }\n}\n\n@media screen and (max-width: 1360px){\n    .toast_container{\n        font-size: 1.5rem;\n    }\n}\n\n@media screen and (max-width: 1120px){\n    .toast_container{\n        font-size: 1.45rem;\n    }\n}\n\n@media screen and (max-width: 965px){\n    .toast_container{\n        font-size: 1.35rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
