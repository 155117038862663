// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Send Button */

.send-button{
    width: 2.4em;
    height: 2.4em;
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    color: var(--primary-purple-sensedia)!important;
    background-color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/sendpost/style.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,+CAA+C;IAC/C,yBAAyB;AAC7B","sourcesContent":["/* Send Button */\n\n.send-button{\n    width: 2.4em;\n    height: 2.4em;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    border: none;\n    color: var(--primary-purple-sensedia)!important;\n    background-color: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
