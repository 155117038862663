import NavBar from "../../components/navbar";
import Support from "./section";

export default function PageSupport({ user }) {
  return (
    <>
      <NavBar />
      <Support {...{ user }} />
    </>
  );
}
