import { useParams } from "react-router-dom";
import AuthSection from "../..";
import LoginLogo from "../../logo";
import CircularProgress from "@mui/material/CircularProgress";

import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../store/context/AuthContext";

export default function ConfirmationPage() {
  var { token } = useParams();

  const { handleEmailConfirmation } = useContext(AuthContext);

  useEffect(() => {
    handleEmailConfirmation(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthSection>
      <LoginLogo unlink={true} />
      <CircularProgress color="inherit" />
    </AuthSection>
  );
}
