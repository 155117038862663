import "./style.css";
import { handleFilter } from "../hooks/useFilterSearch";

export default function FilterTotalMatch({
  type,
  data,
  search,
  checkedInputValue,
}) {
  return (
    <div>
      Total:{" "}
      <span className="total-matches-text">
        {handleFilter(type, data, search, checkedInputValue).length}
      </span>
    </div>
  );
}
