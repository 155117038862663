// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-exception{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10em;
}

.page-exception{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 30em;
}

.exception-btn{
    font-size: 1.8em;
    padding: 1em 5em;
    border: none;
    color: var(--white);
    background: var(--primary-purple-sensedia);
}

.exception-btn:hover{
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/pages/exceptions/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,0CAA0C;AAC9C;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container-exception{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10em;\n}\n\n.page-exception{\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    bottom: 30em;\n}\n\n.exception-btn{\n    font-size: 1.8em;\n    padding: 1em 5em;\n    border: none;\n    color: var(--white);\n    background: var(--primary-purple-sensedia);\n}\n\n.exception-btn:hover{\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
