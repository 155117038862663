import "./style.css";

export default function ClearAllButton({ handleClearAll }) {
  return (
    <button
      className="clearAll-button primary-button"
      onClick={() => handleClearAll()}
    >
      Clear
    </button>
  );
}
