import "./style.css";

export default function FilterSeverity({ severity, setSeverity }) {
  //Função useFilters retorna o severity escolhido (padrão será Critical) e uma div com os select

  function changeSeverity(event) {
    setSeverity(event.target.value);
  }

  return (
    <div className="box-filters">
      <label htmlFor="severity">Severity Alerts:</label>
      <div className="container-select">
        <select
          name="select"
          id="select"
          value={severity}
          onChange={(event) => changeSeverity(event)}
        >
          <option value="critical">Critical</option>
          <option value="high">High</option>
          {/* <option value="warning">Warning</option> */}
        </select>
      </div>
    </div>
  );
}
