// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login Button */

.container-form-login-button{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 1.2em;
}

.form-login-button{
    font-size: 1.4em;
    border: none;
    border-radius: 0em;
    color: #fff;

    line-height: 1.2em;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 50px;
    margin-top: 0.6em;
    background: linear-gradient(to left, var(--primary-purple-sensedia), var(--primary-purple-sensedia));
}

.form-login-button:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/login/style.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,WAAW;;IAEX,kBAAkB;IAClB,yBAAyB;;IAEzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,WAAW;IACX,YAAY;IACZ,iBAAiB;IAKjB,oGAAoG;AACxG;;AAEA;IACI,eAAe;AACnB","sourcesContent":["/* Login Button */\n\n.container-form-login-button{\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    padding-bottom: 1.2em;\n}\n\n.form-login-button{\n    font-size: 1.4em;\n    border: none;\n    border-radius: 0em;\n    color: #fff;\n\n    line-height: 1.2em;\n    text-transform: uppercase;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    width: 100%;\n    height: 50px;\n    margin-top: 0.6em;\n\n    background: -webkit-linear-gradient(to left, var(--primary-purple-sensedia), var(--primary-purple-sensedia));\n    background: -o-linear-gradient(to left, var(--primary-purple-sensedia), var(--primary-purple-sensedia));\n    background: -moz-linear-gradient(to left, var(--primary-purple-sensedia), var(--primary-purple-sensedia));\n    background: linear-gradient(to left, var(--primary-purple-sensedia), var(--primary-purple-sensedia));\n}\n\n.form-login-button:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
