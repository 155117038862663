// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-informations{
    width: 100%;
    margin: auto;
    background: var(--white);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    
    font-size: 1.6em;
}

/* Table */

.container-table{
    width: 100%;
    padding: 1.6em 2em 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/infoSettings/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,sEAAsE;;IAEtE,gBAAgB;AACpB;;AAEA,UAAU;;AAEV;IACI,WAAW;IACX,wBAAwB;AAC5B","sourcesContent":[".settings-informations{\n    width: 100%;\n    margin: auto;\n    background: var(--white);\n    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 5px 0 rgb(0 0 0 / 12%);\n    \n    font-size: 1.6em;\n}\n\n/* Table */\n\n.container-table{\n    width: 100%;\n    padding: 1.6em 2em 1.2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
