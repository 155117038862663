// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-header{
    font-size: 2.2em;
    margin-bottom: 0.6em;
}

.accordion-btn{
    text-align: justify;
    padding: 1.2em 3em;
    width: 100%;
    border-radius: 0.8em;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.192);
    background-color: #FFFFFF;
    font-size: 0.82em;
    border: none;
    color: #7B7B7B;
}

.accordion-body{
    font-size: 1.6em;
    margin-bottom: 1.8em;
    padding: 2.4em 5em 1.4em 5em;
    border-radius: 0.8em;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/support/item/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,oBAAoB;IACpB,gDAAgD;IAChD,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,4BAA4B;IAC5B,oBAAoB;IACpB,uBAAuB;AAC3B","sourcesContent":[".accordion-header{\n    font-size: 2.2em;\n    margin-bottom: 0.6em;\n}\n\n.accordion-btn{\n    text-align: justify;\n    padding: 1.2em 3em;\n    width: 100%;\n    border-radius: 0.8em;\n    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.192);\n    background-color: #FFFFFF;\n    font-size: 0.82em;\n    border: none;\n    color: #7B7B7B;\n}\n\n.accordion-body{\n    font-size: 1.6em;\n    margin-bottom: 1.8em;\n    padding: 2.4em 5em 1.4em 5em;\n    border-radius: 0.8em;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
