export function formatDate(date) {
  if (date) {
    var hour_utc0 = Number(
      date.substring(date.indexOf("T") + 1, date.indexOf("T") + 3)
    );

    var hour = String(
      Number(date.substring(date.indexOf("T") + 1, date.indexOf("T") + 3) - 3)
    );

    if (hour_utc0 > 12) {
      hour = String(hour_utc0 - 3);
    } else if (hour_utc0 >= 3) {
      hour = "0" + String(hour_utc0 - 3);
    } else {
      hour = String(hour_utc0 + 21);
    }

    return `${hour}${date.substring(
      date.indexOf("T") + 3,
      date.indexOf("T") + 9
    )} ${date.substring(
      date.indexOf("-", 5) + 1,
      date.indexOf("-", 5) + 3
    )}/${date.substring(
      date.indexOf("-") + 1,
      date.indexOf("-") + 3
    )}/${date.substring(0, 4)}`;
  }
}
