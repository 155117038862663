import { RiLockPasswordLine } from "react-icons/ri";
import { IconContext } from "react-icons";

export default function PasswordIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <RiLockPasswordLine />
      </div>
    </IconContext.Provider>
  );
}
