import "./style.css";
import Refresh from "../../../assets/icons/RefreshIcon.jsx";

export default function RefreshButton({ getThreshold }) {
  return (
    <div className="box-refresh-button">
      <button
        title="Refresh"
        className="refresh-button"
        onClick={() => getThreshold()}
      >
        <Refresh color="#5C2E7D" size={1.2} />
      </button>
    </div>
  );
}
