import { AiOutlineInfoCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

export default function InfoIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <AiOutlineInfoCircle />
      </div>
    </IconContext.Provider>
  );
}
