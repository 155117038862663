import { headers } from "../../../data/constants/headers";
import { types } from "../../../data/constants/types";
import { user_permissions } from "../../../data/constants/permissions";
import { isUserSensedia } from "../../../utils/isUserSensedia";

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../store/context/AuthContext";
import { SettingsContext } from "../../../store/context/SettingsContext";

export default function useHandlePostItem(type, setPostCollapse) {
  const { user } = useContext(AuthContext);
  const { adminRegisterItem, selectOptions, selectOptionsLoading } =
    useContext(SettingsContext);

  const [postItem, setPostItem] = useState([]);
  const [updatePostItem, setUpdatePostItem] = useState(true);

  const allHeaders = headers.find((info) => info.type === type).headers;

  //Select dinamico
  useEffect(() => {
    if (postItem.length === 0) {
      handleAddRowItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions]);

  useEffect(() => {
    const updatedPostItem = [...postItem];
    for (let i = 0; i < updatedPostItem.length; i++) {
      if (updatedPostItem[i]?.isDefaultGroup) {
        delete updatedPostItem[i].organization;
      }
    }
    setPostItem(updatedPostItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePostItem]);

  //Monta os campos de input para preenchimento
  const handlePostFields = () => {
    var fields = {};
    allHeaders.map(
      (header) =>
        header.post &&
        (fields[header.key] =
          header.inputType === types.inputTypes.select
            ? ""
            : header.inputType === types.inputTypes.checkbox
            ? header.key === "allowed_roles"
              ? []
              : false
            : "")
    );
    return fields;
  };

  //Funcao para modificar informacoes do item atual do form
  const handleOnChangeItem = (e, i) => {
    const { name, value, type, checked } = e.target;
    let newForm = [...postItem];
    newForm[i][name] = type === "checkbox" ? checked : value;
    setPostItem(newForm);
    setUpdatePostItem(!updatePostItem);
  };

  //Funcao para modificar allowed roles de groups
  const handleOnChangeAllowedRoles = (e, i) => {
    const { id } = e.target;
    var allowedRoles = postItem[i]["allowed_roles"];
    if (allowedRoles.includes(id)) {
      var index = allowedRoles.indexOf(id);
      allowedRoles.splice(index, 1);
    } else {
      allowedRoles.push(id);
    }
    let newForm = [...postItem];
    newForm[i]["allowed_roles"] = allowedRoles;
    setPostItem(newForm);
  };

  //Funcao que adiciona linha
  const handleAddRowItem = () => {
    setPostItem([...postItem, handlePostFields()]);
  };

  //Funcao que remove linha
  const handleRemoveRowItem = (index, postCollapse) => {
    if (postItem.length !== 1) {
      var newForm = [...postItem];
      newForm.splice(index, 1);
      setPostItem(newForm);
    } else {
      setTimeout(() => {
        setPostItem([handlePostFields()]);
      }, 300); //delay to reset postItem (0.3 seg)
      setPostCollapse(!postCollapse);
    }
  };

  async function handlePostItem(type, index, postItem) {
    if (type === types.settings_menu?.group) {
      const permissions = {};
      Object.keys(user_permissions).map(
        (permission) => (permissions[permission] = [])
      );
      postItem["permissions"] = permissions;
    }

    if (
      !isUserSensedia(user) &&
      (type === types.settings_menu?.domain ||
        type === types.settings_menu?.group)
    ) {
      postItem["organization"] = user.organization;
    }

    await adminRegisterItem(index, postItem, handleRemoveRowItem);
  }

  return [
    postItem,
    selectOptionsLoading,
    handleAddRowItem,
    handleRemoveRowItem,
    handleOnChangeItem,
    handleOnChangeAllowedRoles,
    handlePostItem,
  ];
}
