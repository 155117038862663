// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sort Button */

.container-sort-button{
    line-height: 1;
}

.sort-button{
    background-color: transparent;
    border: none;
    color: var(--black);
    cursor: pointer;
    transition: transform .05s;
}

.sort-reverse-down{
    transform: rotate(-90deg);
}

.sort-reverse-up{
    transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/sortTable/style.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["/* Sort Button */\n\n.container-sort-button{\n    line-height: 1;\n}\n\n.sort-button{\n    background-color: transparent;\n    border: none;\n    color: var(--black);\n    cursor: pointer;\n    transition: transform .05s;\n}\n\n.sort-reverse-down{\n    transform: rotate(-90deg);\n}\n\n.sort-reverse-up{\n    transform: rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
