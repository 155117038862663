import { useState, useContext, useEffect } from "react";
import { SettingsContext } from "../../../../store/context/SettingsContext";
import { types } from "../../../../data/constants/types";
import { getPermission } from "../../../../utils/getPermission";

export default function useModalPut(type, selectedItem, showPut) {
  const { updateItemById, selectOptions } = useContext(SettingsContext);

  const [putItem, setPutItem] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectChange, setSelectChange] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  //Funcao que modifica o campo de select para o objeto selecionado
  useEffect(() => {
    let newPutItem = putItem;
    const keys = Object.keys(newPutItem);
    for (let i = 0; i < keys.length; i++) {
      if (typeof newPutItem[keys[i]] === "string") {
        if (selectOptions[`${keys[i]}s`]) {
          const selectedOption = selectOptions[`${keys[i]}s`].find(
            (info) => info._id === newPutItem[keys[i]]
          );
          newPutItem[keys[i]] = selectedOption;
        }
      }
    }
    setPutItem(newPutItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectChange]);

  useEffect(() => {
    setPutItem({ ...selectedItem });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      putItem?.permissions?.pages.includes(
        getPermission("pages", "adminsettings")
      )
    ) {
      setIsDisabled(false);
    } else {
      if (putItem?.permissions) {
        var newItem = putItem;
        newItem.permissions.settings_menu = [];
        newItem.permissions.actions = [];
        setPutItem(newItem);
        setIsDisabled(true);
      }
    }
    delete putItem.users;
    delete putItem.domains;
    delete putItem.groups;
  }, [putItem]);

  const handleOnChangeItem = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "select-one") setSelectChange(!selectChange);
    setPutItem((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //groups allowed_roles
  const handleOnChangeAllowedRoles = (e) => {
    const { id } = e.target;
    var allowedRoles = [...putItem["allowed_roles"]];

    if (allowedRoles.includes(id)) {
      var index = allowedRoles.indexOf(id);
      allowedRoles.splice(index, 1);
    } else {
      allowedRoles.push(id);
    }

    setPutItem((prevState) => ({
      ...prevState,
      allowed_roles: allowedRoles,
    }));
  };

  //groups permissions
  const handleOnChangePermission = (e, header) => {
    const { id } = e.target;
    var newPermissions = { ...putItem["permissions"] };

    if (newPermissions[header].includes(id)) {
      var i = newPermissions[header].indexOf(id);
      newPermissions[header] = [
        ...newPermissions[header].slice(0, i),
        ...newPermissions[header].slice(i + 1),
      ];
    } else {
      newPermissions[header] = [...newPermissions[header], id];
    }

    setPutItem((prevState) => ({
      ...prevState,
      permissions: newPermissions,
    }));
  };

  async function handleUpdateItem(id, handleClose) {
    if (type === types.settings_menu?.group && !selectedItem.organization) {
      putItem["isDefaultGroup"] = true;
    }
    let updatedPutItem = { ...putItem };
    await updateItemById(id, updatedPutItem, handleClose);
  }

  return [
    putItem,
    setPutItem,
    loading,
    isDisabled,
    handleOnChangeItem,
    handleOnChangeAllowedRoles,
    handleOnChangePermission,
    handleUpdateItem,
  ];
}
