import "./style.css";
import { formatDate } from "../../../utils/formatDate";
import { isUserSensedia } from "../../../utils/isUserSensedia";
import { headers } from "../../../data/constants/headers";
import { getPermission } from "../../../utils/getPermission";
import ActionSettingsButton from "../../../components/buttons/actionSettings";
import ShowIcon from "../../../assets/icons/ShowIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import IsCheckSuccessIcon from "../../../assets/icons/IsCheckSuccessIcon";
import IsCheckFailIcon from "../../../assets/icons/IsCheckFailIcon";
import SortTableButton from "../../../components/buttons/sortTable";
import ModalRef from "../../../components/modal/modalSettings/modalCRUD/ModalRef";
import ModalPermission from "../../../components/modal/modalSettings/modalCRUD/ModalPermission";
import ModalPut from "../../../components/modal/modalSettings/modalCRUD/ModalPut";
import ModalDelete from "../../../components/modal/modalSettings/modalCRUD/ModalDelete";
import Pagination from "../../../components/pagination";
import RowsPerPage from "../../../components/pagination/rowsperpage";

import useHandleSortTable from "../../../components/sortTable/hooks/useHandleSortTable";
import useHandleTableData from "../hooks/useHandleTableData";

export default function TableSettings({
  type,
  search,
  filteredData,
  checkedInputId,
  setCheckedInputId,
  setCheckedInputValue,
}) {
  const [
    user,
    selectedItem,
    setSelectedItem,
    refKey,
    loadingRef,
    setLoadingRef,
    loadingPermission,
    setLoadingPermission,
    loadingPut,
    setLoadingPut,
    itemsPerPage,
    setItemsPerPage,
    itemOffset,
    setItemOffset,
    isCheck,
    itemIsChecked,
    handleIsChecked,
    showRef,
    setShowRef,
    handleRef,
    showPermission,
    setShowPermission,
    handlePermission,
    showPut,
    setShowPut,
    handlePut,
    showDelete,
    setShowDelete,
    handleDelete,
  ] = useHandleTableData(
    search,
    filteredData,
    checkedInputId,
    setCheckedInputId,
    setCheckedInputValue
  );

  const [sortedData, sortOrder, setSortOrder, sortKey, setSortKey] =
    useHandleSortTable(filteredData);

  //Table Headers
  const table_headers = headers.find((header) => header.type === type)?.headers;
  //Pagination
  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  const currentData = sortedData().slice(itemOffset, endOffset);

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <table className="info-table">
        <thead>
          <tr>
            <th style={{ width: "4%" }}>
              <div className="header-align">
                <input
                  type="checkbox"
                  id="allSelect"
                  checked={isCheck()}
                  onChange={handleIsChecked}
                />
              </div>
            </th>
            {table_headers?.map((header) => {
              if (
                header.hiddenTable ||
                (header.onlySensedia && !isUserSensedia(user))
              ) {
                return undefined;
              }

              return (
                <th key={header.key} style={{ width: header.width }}>
                  <div className="header-align">
                    <span>{header.label}</span>
                    <SortTableButton
                      columnKey={header.key}
                      isSortable={header.sort}
                      {...{ sortOrder, setSortOrder, sortKey, setSortKey }}
                    />
                  </div>
                </th>
              );
            })}
            <th style={{ width: "8%" }}></th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => {
            return (
              <tr
                key={item._id}
                className={index % 2 === 0 ? "bg-dif" : null} //background-color alternado
              >
                <td>
                  <input
                    type="checkbox"
                    id={item._id}
                    checked={itemIsChecked(item)}
                    onChange={handleIsChecked}
                  />
                </td>
                {table_headers?.map((header) => {
                  if (
                    header.hiddenTable ||
                    (header.onlySensedia && !isUserSensedia(user))
                  ) {
                    return undefined;
                  }

                  return typeof item[header.key] !== "object" ? (
                    typeof item[header.key] !== "boolean" ? (
                      header.key !== "createdAt" ? (
                        <td key={header.key}>
                          {item[header.key] ? item[header.key] : "-"}
                        </td>
                      ) : (
                        <td key={header.key}>{formatDate(item[header.key])}</td>
                      )
                    ) : (
                      <td key={header.key} style={{ paddingLeft: "1.8em" }}>
                        {item[header.key] ? (
                          <IsCheckSuccessIcon
                            color={"rgb(110, 199, 110)"}
                            size={1.15}
                          />
                        ) : (
                          <IsCheckFailIcon color={"#D9534F"} size={1.3} />
                        )}
                      </td>
                    )
                  ) : (
                    <td key={header.key}>
                      {header?.ref ? (
                        <ActionSettingsButton
                          {...{ item, header }}
                          icon={<ShowIcon size={1.5} />}
                          color={"grey"}
                          handleAction={handleRef}
                        />
                      ) : header.permissions ? (
                        <ActionSettingsButton
                          {...{ item, header }}
                          icon={<ShowIcon size={1.5} />}
                          color={"grey"}
                          handleAction={handlePermission}
                        />
                      ) : item[header.key] ? (
                        item[header.key]["name"]
                      ) : (
                        "-"
                      )}
                    </td>
                  );
                })}
                <td>
                  <div className="td-actions">
                    <span
                      className={
                        !user?.group?.permissions.actions.includes(
                          getPermission("actions", "update")
                        )
                          ? "disabled"
                          : undefined
                      }
                    >
                      <ActionSettingsButton
                        {...{ item }}
                        title="Update"
                        icon={<EditIcon size={1.5} />}
                        color={"#000000"}
                        handleAction={handlePut}
                      />
                    </span>
                    <span
                      className={
                        !user?.group?.permissions.actions.includes(
                          getPermission("actions", "delete")
                        ) || item._id === user._id
                          ? "disabled"
                          : undefined
                      }
                    >
                      <ActionSettingsButton
                        {...{ item }}
                        title="Delete"
                        icon={<DeleteIcon size={1.5} />}
                        color={"#000000"}
                        handleAction={handleDelete}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="container-pagination">
        <RowsPerPage {...{ setItemOffset, itemsPerPage, setItemsPerPage }} />
        <Pagination data={filteredData} {...{ setItemOffset, itemsPerPage }} />
      </div>

      {loadingRef ? undefined : (
        <ModalRef
          {...{
            type,
            refKey,
            selectedItem,
            showRef,
            setShowRef,
            setLoadingRef,
          }}
        />
      )}

      {loadingPermission ? undefined : (
        <ModalPermission
          {...{
            type,
            refKey,
            selectedItem,
            showPermission,
            setShowPermission,
            setLoadingPermission,
          }}
        />
      )}

      {loadingPut ? undefined : (
        <ModalPut
          {...{
            type,
            selectedItem,
            setSelectedItem,
            showPut,
            setShowPut,
            setLoadingPut,
          }}
        />
      )}

      <ModalDelete {...{ type, selectedItem, showDelete, setShowDelete }} />
    </div>
  );
}
