import "./style.css";
import DeleteIcon from "../../../assets/icons/DeleteIcon";

import { useContext } from "react";
import { AuthContext } from "../../../store/context/AuthContext";
import { getPermission } from "../../../utils/getPermission";

export default function DeleteAllButton({ setShowDeleteAll }) {
  const { user } = useContext(AuthContext);

  return (
    <button
      onClick={() => setShowDeleteAll(true)}
      className={
        user?.group?.permissions.actions.includes(
          getPermission("actions", "delete")
        )
          ? "deleteAll-button primary-button"
          : "deleteAll-button primary-button disabled"
      }
    >
      <span className="deleteAll-icon">
        <DeleteIcon size={1.2} />
      </span>
      Delete All
    </button>
  );
}
