import "./style.css";
import RefreshIcon from "../../../assets/icons/RefreshIcon";

export default function RefreshSettingsButton({ handleRefresh }) {
  return (
    <button
      onClick={() => handleRefresh()}
      className="refresh-button primary-button"
    >
      <span className="refresh-icon">
        <RefreshIcon color="#ADADAD" size={1} />
      </span>
      Refresh
    </button>
  );
}
