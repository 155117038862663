// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Animação Botões */

.primary-button{
    box-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
    border: none;
    transition: all 0.05s;
    touch-action: manipulation;
}

.primary-button:hover{
    box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
    transform: scale(1.02);
    cursor: pointer;
}

.primary-button:active{
    transform: scale(1);
}

.secondary-button{
    border-radius: 100%;
    border: none;
    outline: none;
    background-color: transparent;
}

.secondary-button:hover{
    background-color: var(--low-light);
    cursor: pointer;
    transition: 0.6s;
}

.secondary-button:active{
    background-color: var(--line-color);
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/Buttons.style.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,wCAAwC;IACxC,YAAY;IACZ,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,wCAAwC;IACxC,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":["/* Animação Botões */\n\n.primary-button{\n    box-shadow: 1px 1px 2px rgb(0 0 0 / 30%);\n    border: none;\n    transition: all 0.05s;\n    touch-action: manipulation;\n}\n\n.primary-button:hover{\n    box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);\n    transform: scale(1.02);\n    cursor: pointer;\n}\n\n.primary-button:active{\n    transform: scale(1);\n}\n\n.secondary-button{\n    border-radius: 100%;\n    border: none;\n    outline: none;\n    background-color: transparent;\n}\n\n.secondary-button:hover{\n    background-color: var(--low-light);\n    cursor: pointer;\n    transition: 0.6s;\n}\n\n.secondary-button:active{\n    background-color: var(--line-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
