// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li .header-info-checkcard{
    height: 5em;
    padding: 1.2em 2em 1.2em 2.2em;
    font-weight: bold;
    background-color: var(--white-smoke);
    border-bottom: 1px solid var(--line-color-light);

    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 1em;
    align-items: center;
}

li .header-info-checkcard img{
    margin: auto;
}

li .body-info-checkcard{
    height: 12em;
    padding: 1.2em 2em;
    background-color: var(--white);
    border-bottom: 1px solid var(--line-color);
}

li .footer-info-checkcard{
    padding: 0.7em 1.6em 0.8em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 1.4em;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/infoChecked/checkedCard/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;IAC9B,iBAAiB;IACjB,oCAAoC;IACpC,gDAAgD;;IAEhD,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,8BAA8B;IAC9B,0CAA0C;AAC9C;;AAEA;IACI,0BAA0B;IAC1B,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":["li .header-info-checkcard{\n    height: 5em;\n    padding: 1.2em 2em 1.2em 2.2em;\n    font-weight: bold;\n    background-color: var(--white-smoke);\n    border-bottom: 1px solid var(--line-color-light);\n\n    display: grid;\n    grid-template-columns: 3fr 1fr;\n    column-gap: 1em;\n    align-items: center;\n}\n\nli .header-info-checkcard img{\n    margin: auto;\n}\n\nli .body-info-checkcard{\n    height: 12em;\n    padding: 1.2em 2em;\n    background-color: var(--white);\n    border-bottom: 1px solid var(--line-color);\n}\n\nli .footer-info-checkcard{\n    padding: 0.7em 1.6em 0.8em;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    column-gap: 1.4em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
