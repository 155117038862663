import "./style.css";
import "../Buttons.style.css";

export default function ActionSettingsButton({
  icon,
  title,
  color,
  item,
  header,
  handleAction,
}) {
  return (
    <button
      className="action-button secondary-button"
      title={title}
      onClick={() => handleAction(item, header?.key)}
      style={{ color }}
    >
      {icon}
    </button>
  );
}
