import "./style.css";
import SendIcon from "../../../assets/icons/SendIcon";

export default function SendPostButton({ type, index, item, handlePostItem }) {
  return (
    <>
      <button
        className="send-button secondary-button"
        onClick={() => handlePostItem(type, index, item)}
        title="Create"
      >
        <SendIcon size={1.3} />
      </button>
    </>
  );
}
