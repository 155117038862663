import "./style.css";
import { useContext, useState } from "react";
import { capitalize } from "@mui/material";
import ClearAllButton from "../../../components/buttons/clearAll";
import DeleteAllButton from "../../../components/buttons/deleteAll";
import InfoCheckedCard from "./checkedCard";
import ModalDelete from "../../../components/modal/modalSettings/modalCRUD/ModalDelete";
import ModalPut from "../../../components/modal/modalSettings/modalCRUD/ModalPut";
import ModalDeleteAll from "../../../components/modal/modalSettings/modalCRUD/ModalDeleteAll";

import { AuthContext } from "../../../store/context/AuthContext";

export default function InfoChecked({
  type,
  checkedInputValue,
  checkedInputId,
  setCheckedInputId,
}) {
  const { user } = useContext(AuthContext);
  const [selectedItem, setSelectedItem] = useState("");
  const [loadingPut, setLoadingPut] = useState(true);
  const [showPut, setShowPut] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const handleClearAll = () => {
    setCheckedInputId([]);
  };

  return (
    <>
      <div className="container-info-checked">
        <div className="container-selected-length">
          <span>
            Selected {capitalize(type)}s:{" "}
            <strong>{checkedInputValue.length}</strong>
          </span>
          <div className="infochecked-actions">
            <ClearAllButton {...{ handleClearAll }} />
            <DeleteAllButton {...{ setShowDeleteAll }} />
          </div>
        </div>
        <ul>
          {checkedInputValue
            ? checkedInputValue.map((checkedValue) => (
                <li key={checkedValue._id}>
                  <InfoCheckedCard
                    {...{
                      user,
                      type,
                      checkedValue,
                      checkedInputId,
                      setCheckedInputId,
                      setSelectedItem,
                      setLoadingPut,
                      setShowPut,
                      setShowDelete,
                    }}
                  />
                </li>
              ))
            : ""}
        </ul>
      </div>

      {loadingPut ? undefined : (
        <ModalPut
          {...{
            type,
            selectedItem,
            setSelectedItem,
            showPut,
            setShowPut,
            setLoadingPut,
          }}
        />
      )}

      <ModalDelete {...{ type, selectedItem, showDelete, setShowDelete }} />

      <ModalDeleteAll
        {...{ type, showDeleteAll, setShowDeleteAll }}
        selectedInfos={checkedInputValue}
      />
    </>
  );
}
