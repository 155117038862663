import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../store/context/AuthContext";
import { SettingsContext } from "../../../store/context/SettingsContext";

export default function useHandleTableData(
  search,
  filteredData,
  checkedInputId,
  setCheckedInputId,
  setCheckedInputValue
) {
  const { user } = useContext(AuthContext);
  const { data } = useContext(SettingsContext);
  const [selectedItem, setSelectedItem] = useState("");
  const [refKey, setRefKey] = useState("");
  const [loadingRef, setLoadingRef] = useState(true);
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [loadingPut, setLoadingPut] = useState(true);
  const [showRef, setShowRef] = useState(false);
  const [showPermission, setShowPermission] = useState(false);
  const [showPut, setShowPut] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  //Pagination states
  const [itemsPerPage, setItemsPerPage] = useState(5); //default 5
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setItemOffset(0); //paginacao em 0 ao filtrar
  }, [search]);

  //Monta o array de checkedValue baseado nos Ids de checkedInputId que existem dentro de data
  useEffect(() => {
    const checkedValues = [];
    checkedInputId.map((checkedId) =>
      data.find((item) => item._id === checkedId)
        ? checkedValues.push(data.find((item) => item._id === checkedId))
        : ""
    );
    setCheckedInputValue(checkedValues);
  }, [data, checkedInputId, setCheckedInputValue]);

  //remove ids de checkedInputId que não existem mais em data
  function idStillExist(checkedIds) {
    const newCheckedIds = [];
    data.map((item) =>
      checkedIds.find((id) => id === item._id)
        ? newCheckedIds.push(item._id)
        : undefined
    );
    return newCheckedIds;
  }

  //verifica se todos estao selecionados
  function isAllSelected(checkedIds, itemIds) {
    for (var i = 0; i < itemIds.length; i++) {
      const id = itemIds[i];
      if (!checkedIds.some((checkedId) => checkedId === id)) {
        return false;
      }
    }
    return true;
  }

  //funcao chamadada ao clicar no checkBox
  const handleIsChecked = (e) => {
    const { id } = e.target;
    const checkedIds = [...checkedInputId];
    if (id === "allSelect") {
      if (filteredData.length !== 0) {
        //caso nao tenha usuarios na tabela, o botao de allSelect nao funciona
        const itemIds = filteredData.map((item) => {
          var currentitem = { ...item };
          return currentitem._id;
        });

        if (isAllSelected(checkedIds, itemIds)) {
          for (var i = 0; i < itemIds.length; i++) {
            let index = checkedIds.indexOf(itemIds[i]);
            checkedIds.splice(index, 1);
          }
        } else {
          for (var j = 0; j < itemIds.length; j++) {
            if (!checkedIds.includes(itemIds[j])) {
              checkedIds.push(itemIds[j]);
            }
          }
        }
        setCheckedInputId(idStillExist(checkedIds));
      }
    } else {
      // checkBox individual
      let i = checkedIds.indexOf(id);
      if (i < 0) {
        checkedIds.push(id); //nao existe e adiciona
      } else {
        checkedIds.splice(i, 1); //existe e remove
      }
      setCheckedInputId(idStillExist(checkedIds)); //verifica se o id ainda existe no array de data
    }
  };

  //funcao que retorna se o Id deve estar marcado (true ou false)
  function itemIsChecked(item) {
    return checkedInputId.includes(item._id);
  }

  //funcao chamada no checkbox de allSelect
  const isCheck = () => {
    if (filteredData.length === 0) {
      return false;
    } else {
      for (var i = 0; i < filteredData.length; i++) {
        if (!checkedInputId.includes(filteredData[i]._id)) {
          return false;
        }
      }
      return true;
    }
  };

  //funcao modal ref
  function handleRef(item, key) {
    setSelectedItem(item);
    setRefKey(key);
    setShowRef(true);
    setLoadingRef(false);
  }

  //funcao modal permission
  function handlePermission(item, key) {
    setSelectedItem(item);
    setRefKey(key);
    setShowPermission(true);
    setLoadingPermission(false);
  }

  //funcao modal put
  function handlePut(item) {
    setSelectedItem(item);
    setShowPut(true);
    setLoadingPut(false);
  }

  //funcao modal delete
  function handleDelete(item) {
    setSelectedItem(item);
    setShowDelete(true);
  }

  return [
    user,
    selectedItem,
    setSelectedItem,
    refKey,
    loadingRef,
    setLoadingRef,
    loadingPermission,
    setLoadingPermission,
    loadingPut,
    setLoadingPut,
    itemsPerPage,
    setItemsPerPage,
    itemOffset,
    setItemOffset,
    isCheck,
    itemIsChecked,
    handleIsChecked,
    showRef,
    setShowRef,
    handleRef,
    showPermission,
    setShowPermission,
    handlePermission,
    showPut,
    setShowPut,
    handlePut,
    showDelete,
    setShowDelete,
    handleDelete,
  ];
}
