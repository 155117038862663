import "./style.css";

export default function BoxLogo({ customer }) {
  return (
    <div className="box-logo-cliente">
      <img
        src={customer.logo}
        alt={customer.alt}
        title={customer.title}
        style={{ width: `${customer.width_mainLogo}em` }}
      />
    </div>
  );
}
