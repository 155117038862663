//types
export const types = {
  roles: {
    user: "user",
    admin: "admin",
  },
  pages: {
    dashboard: "dashboard",
    customerstatus: "customerstatus",
    // healthstatus: "healthstatus",
    settings: "settings",
    // charts: "charts",
  },
  settings_menu: {
    user: "user",
    role: "role",
    organization: "organization",
    domain: "domain",
    group: "group",
  },
  actions: {
    get: "get",
    post: "post",
    put: "put",
    delete: "delete",
  },
  permissions: {
    pages: "pages",
    settings_menu: "settings_menu",
    actions: 'actions'
  },
  inputTypes: {
    text: "text",
    email: "email",
    select: "select",
    checkbox: "checkbox",
  },
  select: {
    roles: "roles",
    organizations: "organizations",
    domains: "domains",
    groups: "groups",
    defaultGroups: "defaultGroups"
  }
};
