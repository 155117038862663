import "../assets/css/index.css";
import "../assets/fonts/font.css";
import RoutesPages from "../store/routes/routes";

import { AuthProvider } from "../store/context/AuthContext";
import { SettingsProvider } from "../store/context/SettingsContext";

export default function App() {
  return (
    <AuthProvider>
      <SettingsProvider>
        <RoutesPages />
      </SettingsProvider>
    </AuthProvider>
  );
}
