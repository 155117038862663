import "./style.css";
import "../../../responsive/StatusPage-responsive.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { callRulesApi } from "../../../utils/callRulesApi";
import { support_premium_customers } from "../../../data/constants/clients";
import { severity_informations } from "../../../data/constants/severity";
import BoxRuleCard from "../boxcard/BoxRuleCard";
import BoxCustomerCard from "../boxcard/BoxCustomerCard";
import NoResultsFound from "../../../components/noresultsfound";
import AlertHigh from "../../../assets/icons/AlertHighIcon.jsx";
import AlertCritical from "../../../assets/icons/AlertCriticalIcon";
import AlertWarning from "../../../assets/icons/AlertWarningIcon";
import CheckSuccess from "../../../assets/icons/CheckSuccessIcon";

export default function StatusPage({ customer, logs }) {
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState([]);
  const [orderedRules, setOrderedRules] = useState([]);

  useEffect(() => {
    callRulesApi(customer, setRules, setLoading);
  }, [customer]);

  useEffect(() => {
    const rulesWithCriticalAlerts = [];
    const rulesWithHighAlerts = [];
    const rulesWithoutAlerts = [];

    rules.forEach((rule) => {
      const ruleId = rule.id;

      if (ruleIdExistInFiringAlerts(ruleId)) {
        const severity = getRuleFiringInfo(ruleId).severity;
        if (severity.includes("critical")) {
          rulesWithCriticalAlerts.push(rule);
        } else if (severity.includes("high")) {
          rulesWithHighAlerts.push(rule);
        }
      } else {
        rulesWithoutAlerts.push(rule);
      }
    });

    const temp_orderedRules = [
      ...rulesWithCriticalAlerts,
      ...rulesWithHighAlerts,
      ...rulesWithoutAlerts,
    ];

    setOrderedRules(temp_orderedRules);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules]);

  function ruleIdExistInFiringAlerts(ruleId) {
    //verifica se o ruleId atual esta disparando
    for (const log of logs) {
      if (log.id === String(ruleId)) {
        return true;
      }
    }
    return false;
  }

  function getRuleFiringInfo(ruleId) {
    //funcao que retorna as informacoes da rule que está alertando
    let ruleFiringDetails = logs.filter((info) => info.id === String(ruleId));

    var temp_description = [];
    var temp_firing_date = [];
    var temp_severity = [];

    for (var i = 0; i < ruleFiringDetails.length; i++) {
      temp_description.push(ruleFiringDetails[i].description);
      temp_firing_date.push(ruleFiringDetails[i].firing_date);
      temp_severity.push(ruleFiringDetails[i].severity);
    }

    return {
      ruleId: ruleFiringDetails[0].ruleId,
      ruleName: ruleFiringDetails[0].ruleName,
      description: temp_description,
      firing_date: temp_firing_date,
      severity: temp_severity,
    };
  }

  function getSeverityColor(alertSeverity) {
    //["critical", "high", "warning"]
    //funcao que retorna a cor baseado na severidade do alerta
    if (alertSeverity.includes("critical")) {
      //critical
      return getColorFromIndex("critical");
    } else if (alertSeverity.includes("high")) {
      //high
      return getColorFromIndex("high");
    } else if (alertSeverity.includes("warning")) {
      //warning
      return getColorFromIndex("warning");
    } else {
      //operational
      return getColorFromIndex("operational");
    }
  }

  function getColorFromIndex(alertSeverity) {
    let severityColor = severity_informations.find(
      (info) => info.severity === alertSeverity
    );
    return severityColor.color;
  }

  //funcao que retorna o icone baseado na severidade do alerta
  //["high", "critical", "warning"]
  function getSeverityIcon(alertSeverity) {
    if (alertSeverity.includes("critical")) {
      //critical
      return <AlertCritical color={"white"} size={1.2} />;
    } else if (alertSeverity.includes("high")) {
      //high
      return <AlertHigh color={"white"} size={1} />;
    } else if (alertSeverity.includes("warning")) {
      //warning
      return <AlertWarning color={"black"} size={1.2} />;
    }
  }

  return (
    <div className="container-status-page">
      {customer.customer_id === "all" ? (
        support_premium_customers.map((customer, index) => (
          <li key={index}>
            <Link to={`./../${customer.customer_name.toLowerCase()}`}>
              <BoxCustomerCard {...{ customer }} />
            </Link>
          </li>
        ))
      ) : loading ? (
        ""
      ) : rules.length === 0 ? ( //cliente nao configurado
        <div style={{ margin: "auto" }}>
          <NoResultsFound size={42} />
        </div>
      ) : (
        orderedRules.map((rule, index) => (
          <li key={index}>
            {ruleIdExistInFiringAlerts(rule.id) ? (
              <BoxRuleCard
                ruleId={rule.id}
                ruleName={rule.api_name}
                firing_date={getRuleFiringInfo(rule.id).firing_date}
                description={getRuleFiringInfo(rule.id).description}
                severity={getRuleFiringInfo(rule.id).severity}
                severity_color={getSeverityColor(
                  getRuleFiringInfo(rule.id).severity
                )}
                severity_icon={getSeverityIcon(
                  getRuleFiringInfo(rule.id).severity
                )}
              />
            ) : (
              <BoxRuleCard
                ruleId={rule.id}
                ruleName={rule.api_name}
                severity_color={getSeverityColor("operational")} //operational = green
                severity_icon={<CheckSuccess color={"white"} size={1} />}
              />
            )}
          </li>
        ))
      )}
    </div>
  );
}
