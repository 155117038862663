import "./style.css";
import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { showNotification } from "../toastNotification";

export default function ConfigTimer({ setConfigTime }) {
  const [value, setValue] = React.useState(dayjs("2022-31-10 00:01:00"));

  function updateTimer(newTime) {
    if (newTime !== null) {
      setValue(newTime);
      setConfigTime(newTime.format("mm:ss"));
    } else {
      showNotification("Please, set a timer information");
    }
  }

  return (
    <div style={{ height: "2em" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <TimePicker
            ampmInClock
            views={["minutes", "seconds"]}
            inputFormat="mm:ss"
            mask="__:__"
            label="Minutes and seconds"
            value={value}
            onChange={(newValue) => {
              updateTimer(newValue);
            }}
            renderInput={(params) => (
              <TextField
                style={{ width: 160, background: "#FFF" }}
                {...params}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
}
