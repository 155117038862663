import "../style.css";
import { Link } from "react-router-dom";
import SectionException from "..";

import { useContext } from "react";
import { AuthContext } from "../../../store/context/AuthContext";

export default function PageNotActive() {
  const { authenticated, handleLogOut } = useContext(AuthContext);

  return (
    <SectionException
      title={"401 - Unauthorized Access"}
      p={
        "We're sorry, your user must be active to be able to see the dashboard logs."
      }
    >
      {authenticated ? (
        <Link>
          <button className="exception-btn" onClick={() => handleLogOut()}>
            GO BACK TO LOGIN
          </button>
        </Link>
      ) : undefined}
    </SectionException>
  );
}
