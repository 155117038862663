import "./style.css";
import "../../../responsive/StatusPage-responsive.css";
import { customer_info } from "../../../data/constants/clients";

export default function BoxCustomerCard({ customer }) {
  const customer_inCard = customer_info.find(
    (info) => info.customer_id === customer.customer_id
  );

  return (
    <div className="box-customer-card" style={{ border: `1px solid #8953b0` }}>
      {customer_inCard ? (
        <img
          src={
            customer_inCard?.logo
              ? customer_inCard.logo
              : customer.customer_name
          }
          alt={customer_inCard?.alt}
          title={customer_inCard?.title}
          style={{ width: `${customer_inCard?.width_statusPage}em` }}
        />
      ) : (
        <span style={{ fontSize: `3em` }}>{customer.customer_name}</span>
      )}
    </div>
  );
}
