import "./style.css";
import "../../../responsive/Dashboard-responsive.css";
import React, { useEffect, useState } from "react";
import { showNotification } from "../../../components/toastNotification";
import { checkFieldsInResponse } from "../../../utils/checkFieldsInResponse.js";
import {
  customer_info,
  labels_validation_dashboard,
} from "../../../data/constants/clients";
import { isUserSensedia } from "../../../utils/isUserSensedia";
import { formatDate } from "../../../utils/formatDate";
import Dropdown from "../../../assets/icons/DropdownIcon";
import SeverityDot from "../../../assets/icons/SeverityDotIcon.jsx";
import NoResultsFound from "../../../components/noresultsfound";

export default function Dashboard({ user, logs }) {
  //Responsavel pelo id
  const [open, setOpen] = useState();
  const [currentLogs, setCurrentLogs] = useState([]);

  function checkIsOpen(key) {
    return open === key;
  }

  useEffect(() => {
    const builded_log = [];
    logs.forEach((log) => {
      if (checkFieldsInResponse(log, labels_validation_dashboard)) {
        builded_log.push({
          id: log.labels.id,
          name: log.labels.name,
          alertmetrics: `${log.labels.type.toUpperCase()} - ${log.labels.metric
            .toUpperCase()
            .replace(/_/g, " ")}`,
          date: formatDate(log.activeAt),
          customer: log.labels.customer_name.toLowerCase(),
          customer_id: log.labels.customer_id,
          severity: log.labels.severity,
          description: log.annotations.description,
        });
      } else if (!log.labels.error) {
        showNotification("Some field is missing!");
      }
    });
    setCurrentLogs(builded_log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  if (currentLogs.length === 0) {
    return <NoResultsFound size={26} />;
  }

  return (
    <div>
      <table className="table-apis">
        <thead className="fixed">
          <tr>
            <th className="collapse-th"></th>
            <th className="apiId-th">ID</th>
            <th className="apiName-th">Name</th>
            <th className="alert-th">Alert Metrics</th>
            <th className="firing-date-th">Firing Date</th>
            {isUserSensedia(user) ? (
              <th className="customer-th">Customer</th>
            ) : undefined}
            <th className="severity-th"></th>
          </tr>
        </thead>
        <tbody>
          {currentLogs.map((log, key) => {
            const current_customer = customer_info.find(
              (info) => info.customer_id === log.customer_id
            );
            return (
              <React.Fragment key={key}>
                {!current_customer ? undefined : ( //caso não exista, ele não irá renderizar
                  <tr className="tr-logs">
                    <td className="collapse-td">
                      <button
                        onClick={() =>
                          open === key ? setOpen() : setOpen(key)
                        }
                        type="button"
                        className="button-collapse-td"
                      >
                        <Dropdown color={"#673AB7"} size={"1.8em"} />
                      </button>
                    </td>
                    <td className="apiId-td">{log.id}</td>
                    <td className="apiName-td">{log.name}</td>
                    <td className="alert-td">{log.alertmetrics}</td>
                    <td className="firing-date-td">{log.date}</td>
                    {isUserSensedia(user) ? (
                      <td className="customer-td">
                        <img
                          src={current_customer.logo}
                          alt={current_customer.alt}
                          title={current_customer.title}
                          style={{
                            height: `${current_customer.height_table}em`,
                          }}
                        />
                      </td>
                    ) : undefined}
                    <td className="severity-td">
                      <SeverityDot severity={log.severity} size={0.8} />
                    </td>
                  </tr>
                )}
                {checkIsOpen(key) && (
                  <tr>
                    <td className="description-td" colSpan={7}>
                      {log.description}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="foot-table"></div>
    </div>
  );
}
