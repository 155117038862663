import "./style.css";
import "../../responsive/Timer-responsive.css";

export const getTimeRemaining = (e) => {
  const total = Date.parse(e) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
  return {
    total,
    hours,
    minutes,
    seconds,
  };
};

const startTimer = (e, setTimer) => {
  let { total, hours, minutes, seconds } = getTimeRemaining(e);
  if (total >= 0) {
    // update the timer
    // check if less than 10 then we need to
    // add '0' at the begining of the variable
    setTimer(
      (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );
  }
};

export const clearTimer = async (e, setTimer, Ref, configTime) => {
  // If you adjust it you should also need to
  // adjust the Endtime formula we are about
  // to code next
  var initial_time = `00:${configTime.substring(0, 2)}:${configTime.substring(
    3,
    5
  )}`;
  setTimer(initial_time);

  // If you try to remove this line the
  // updating of timer Variable will be
  // after 1000ms or 1sec
  if (Ref.current) {
    clearInterval(Ref.current);
  }
  const id = setInterval(() => {
    startTimer(e, setTimer);
  }, 1000);
  Ref.current = id;
};

export const getDeadTime = (configTime) => {
  let deadline = new Date();

  // This is where you need to adjust if
  // you entend to add more time
  deadline.setSeconds(
    deadline.getSeconds() +
      (Number(configTime.substring(0, 2)) * 60 +
        Number(configTime.substring(3, 5)))
  );
  return deadline; //hora atual mais o valor do configTime (valor settado)
};

export const onClickReset = (setTimer, Ref, configTime) => {
  clearTimer(getDeadTime(configTime), setTimer, Ref, configTime);
};

export const lastUpdate = (setCurrentDate) => {
  const date = new Date().toLocaleDateString();
  const time = new Date().toLocaleTimeString();
  const current_date = `${time} ${date}`;
  setCurrentDate(current_date);
};

export function buildConfigTime(configTime) {
  if (configTime === "00:00" || configTime === "Invalid Date") {
    return 999999999;
  } else {
    return (
      configTime.substring(0, 2) * 1000 * 60 + configTime.substring(3, 5) * 1000
    );
  }
}

export default function Timer({ timer, currentDate }) {
  return (
    <div className="box-timer">
      <p>
        Next Update in: <span className="timer-count">{timer}</span>
      </p>
      <p>
        Last Update: <span className="current-date">{currentDate}</span>
      </p>
    </div>
  );
}
