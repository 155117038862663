import "./style.css";
import NavBar from "../../components/navbar";

const SectionException = ({ children, title, p }) => {
  return (
    <>
      <NavBar />
      <div className="container-exception">
        <div className="page-exception">
          <h1
            style={{
              fontSize: "15em",
              color: "#5C2E7D",
              marginBottom: "0.2em",
            }}
          >
            Oops!
          </h1>
          <p
            style={{ fontSize: "3em", color: "#303030", marginBottom: "0.4em" }}
          >
            {title}
          </p>
          <p style={{ fontSize: "2em", color: "grey", marginBottom: "2em" }}>
            {p}
          </p>
          {children}
        </div>
      </div>
    </>
  );
};

export default SectionException;
