// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-alert-information{
    width: 100%;
    height: 8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1.3em;
    background: var(--white);
    box-shadow: 0px 0px 5px 1px var(--box-shadow-light);
}

.box-alert-information{
    margin-top: 0.4em;
    height: 5.6em;
    color: var(--light-purple-sensedia);
}

.box-alert-information p{
    font-size: 1.2em;
    margin-bottom: 0px;
}

.alerts-icon-count{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.1em;
}

.firing-alerts-icon{
    position: relative;
    bottom: 0.06em;
}

.firing-alerts-amount{
    font-size: 1.4em;
}`, "",{"version":3,"sources":["webpack://./src/components/leftmenu/firingAlerts/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,wBAAwB;IACxB,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".box-alert-information{\n    width: 100%;\n    height: 8em;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-radius: 1.3em;\n    background: var(--white);\n    box-shadow: 0px 0px 5px 1px var(--box-shadow-light);\n}\n\n.box-alert-information{\n    margin-top: 0.4em;\n    height: 5.6em;\n    color: var(--light-purple-sensedia);\n}\n\n.box-alert-information p{\n    font-size: 1.2em;\n    margin-bottom: 0px;\n}\n\n.alerts-icon-count{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    column-gap: 0.1em;\n}\n\n.firing-alerts-icon{\n    position: relative;\n    bottom: 0.06em;\n}\n\n.firing-alerts-amount{\n    font-size: 1.4em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
