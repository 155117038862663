// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1640px){
    .container-settings{
        font-size: 0.96rem;
    }
}

@media screen and (max-width: 1570px){
    .container-settings{
        font-size: 0.92rem;
    }
}

@media screen and (max-width: 1480px){
    .container-settings{
        font-size: 0.88rem;
    }
}

@media screen and (max-width: 1390px){
    .container-settings{
        font-size: 0.84rem;
    }
}

@media screen and (max-width: 1310px){
    .container-settings{
        font-size: 0.80rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/responsive/Settings-responsive.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["@media screen and (max-width: 1640px){\n    .container-settings{\n        font-size: 0.96rem;\n    }\n}\n\n@media screen and (max-width: 1570px){\n    .container-settings{\n        font-size: 0.92rem;\n    }\n}\n\n@media screen and (max-width: 1480px){\n    .container-settings{\n        font-size: 0.88rem;\n    }\n}\n\n@media screen and (max-width: 1390px){\n    .container-settings{\n        font-size: 0.84rem;\n    }\n}\n\n@media screen and (max-width: 1310px){\n    .container-settings{\n        font-size: 0.80rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
