import { useCallback, useState } from "react";

export default function useHandleSortTable(data) {
  const [sortKey, setSortKey] = useState("createdAt"); //key default que ira ordenar
  const [sortOrder, setSortOrder] = useState("random"); //ordem default

  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData; //ja possui valor default no state

    // eslint-disable-next-line array-callback-return
    const sortedData = data.sort((a, b) => {
      if (a[sortKey] && b[sortKey]) {
        const valA =
          typeof a[sortKey] !== "object" ? a[sortKey] : a[sortKey]["name"];
        const valB =
          typeof b[sortKey] !== "object" ? b[sortKey] : b[sortKey]["name"];

        return valA?.toLowerCase() > valB?.toLowerCase() ? 1 : -1;
      }
    });

    if (reverse) {
      return sortedData.reverse();
    }
    return sortedData;
  }

  const sortedData = useCallback(
    () =>
      sortData({
        tableData: data,
        sortKey,
        reverse: sortOrder === "desc",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, sortKey, sortOrder]
  );

  return [sortedData, sortOrder, setSortOrder, sortKey, setSortKey];
}
