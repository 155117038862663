// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-modal-template{
    width: 100%;
    font-size: 2.8em;
    background: var(--white);
    padding: 1.4em 0em;
    
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,wBAAwB;IACxB,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".container-modal-template{\n    width: 100%;\n    font-size: 2.8em;\n    background: var(--white);\n    padding: 1.4em 0em;\n    \n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
