import "./style.css";
import "../../../responsive/SectionDashBoard-responsive.css";
import { useEffect, useState, useRef } from "react";
import { onClickReset, lastUpdate } from "../../../components/timer";
import { isCustomerIdInArray } from "../../../utils/isCustomerInArray.js";
import { support_premium_customers } from "../../../data/constants/clients";
import LeftMenu from "../../../components/leftmenu/index.jsx";
import FadeLoader from "react-spinners/FadeLoader";
import Dashboard from "../table/index.jsx";
import Toast, { showNotification } from "../../../components/toastNotification";
import api_threshold_rules from "../../../services/api/api-threshold-rules";

export default function SectionDashBoard({ user, customer }) {
  const Ref = useRef(null);

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState("");
  const [configTime, setConfigTime] = useState("01:00"); //default 1 minute
  const [timer, setTimer] = useState("");

  const [severity, setSeverity] = useState("critical"); //default

  //Filtra o logs dos clientes que estão em constants
  function settingLogs(logs, array) {
    for (const log of logs) {
      if (customer.customer_id === "all") {
        if (
          log.labels.customer_id &&
          isCustomerIdInArray(log.labels.customer_id, support_premium_customers)
        ) {
          array.push(log);
        }
      } else {
        array.push(log);
      }
    }
  }

  //API Threshold
  async function getThreshold(customer, severity) {
    setLogs([]);
    setLoading(true);

    var logsToSet = [];

    await api_threshold_rules
      .get(
        `/api/thresholdsfiring/${customer.customer_id}?severity=${severity}&page=1&page_size=100`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "auth-header": customer.authHeader,
          },
        }
      )
      .then((response) => {
        response.data === 401
          ? setLogs([])
          : settingLogs(response.data.results, logsToSet); //filtra os alertas recebidos para mostrar somente os clientes desejados (configurado em ../Constants/index.jsx)
      })
      .catch((error) => {
        showNotification("Error in request to backend");
      })
      .finally(() => {
        setLoading(false);
        lastUpdate(setCurrentDate);
        onClickReset(setTimer, Ref, configTime);
      });
    setLogs(logsToSet);
  }

  useEffect(() => {
    getThreshold(customer, severity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, severity]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getThreshold(customer, severity);
  //   }, buildConfigTime(configTime) - 100); //atraso de 100 ms para evitar conflito entre o outro useEffect
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [configTime, customer, severity]);

  useEffect(() => {
    if (timer === "00:00:00") {
      getThreshold(customer, severity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <div className="section-body-dashboard">
      <div className="dashboard-customer">
        <LeftMenu
          {...{
            user,
            customer,
            logs,
            severity,
            setSeverity,
            configTime,
            setConfigTime,
            timer,
            currentDate,
          }}
          getThreshold={() => getThreshold(customer, severity)}
        />

        {loading ? (
          <div className="container-loading-icon">
            <FadeLoader color="#8241b0" loading={loading} radius={50} />
          </div>
        ) : (
          <div className="table-alerts">
            <Dashboard {...{ user, logs }} />
          </div>
        )}
      </div>
      <Toast />
    </div>
  );
}
