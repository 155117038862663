import { AiOutlineEye } from "react-icons/ai";
import { IconContext } from "react-icons";

export default function AlertWarningIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}em` }}>
      <div>
        <AiOutlineEye />
      </div>
    </IconContext.Provider>
  );
}
