import "./style.css";
import { capitalize } from "@mui/material";
import { types } from "../../../../data/constants/types";
import ModalTemplate from "../..";
import useModalPut from "../hooks/useModalPut";
import EditIcon from "../../../../assets/icons/EditIcon";
import DataPutFields from "../putFields";
import PermissionPutFields from "../putPermissionFields";
import DataInformations from "../datainfo";
import HandleModalButton from "../../../buttons/handlemodal";

export default function ModalPut({
  type,
  selectedItem,
  setSelectedItem,
  showPut,
  setShowPut,
  setLoadingPut,
}) {
  const [
    putItem,
    setPutItem,
    loading,
    isDisabled,
    handleOnChangeItem,
    handleOnChangeAllowedRoles,
    handleOnChangePermission,
    handleUpdateItem,
  ] = useModalPut(type, selectedItem, showPut);

  const handleClose = () => {
    setShowPut(false);
    setTimeout(() => {
      setPutItem("");
      setSelectedItem("");
      setLoadingPut(true);
    }, 200);
  };

  return loading ? undefined : (
    <ModalTemplate
      size={"lg"}
      color={"var(--dark-blue-sensedia)"}
      showModal={showPut}
      {...{ handleClose }}
    >
      <div
        className="header-modal-settings"
        style={{ color: "var(--dark-blue-sensedia)" }}
      >
        <EditIcon size={2} />
        <p>Edit {capitalize(type)}</p>
      </div>

      <div className="body-modal-settings">
        <div className="container-put-form">
          <form className="put-form">
            <DataPutFields
              {...{
                type,
                selectedItem,
                putItem,
                handleOnChangeItem,
                handleOnChangeAllowedRoles,
              }}
            />
          </form>
        </div>
        {type === types.settings_menu.group && (
          <div className="container-put-permissions-form">
            <form className="put-permissions-form">
              <PermissionPutFields
                {...{ putItem, handleOnChangePermission, isDisabled }}
              />
            </form>
          </div>
        )}

        <div className="container-data-info">
          <div>
            <span>{capitalize(type)} Informations:</span>
            <DataInformations {...{ type, selectedItem }} />
          </div>
          <div>
            <span>New {capitalize(type)} Informations:</span>
            <DataInformations {...{ type }} selectedItem={putItem} />
          </div>
        </div>
      </div>

      <div className="footer-modal-settings">
        <HandleModalButton
          text="confirm"
          color="var(--dark-blue-sensedia)"
          id={selectedItem._id}
          handleAction={handleUpdateItem}
          {...{ handleClose }}
        />
        <HandleModalButton text="cancel" handleAction={handleClose} />
      </div>
    </ModalTemplate>
  );
}
