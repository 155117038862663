import { RiArrowDropDownLine } from "react-icons/ri";
import { IconContext } from "react-icons";

export default function DropdownIcon({ color, size }) {
  return (
    <IconContext.Provider value={{ color: color, size: `${size}` }}>
      <div>
        <RiArrowDropDownLine />
      </div>
    </IconContext.Provider>
  );
}
