import { customer_info } from "../data/constants/clients";
import { showNotification } from "../components/toastNotification";
import api_threshold from "../services/api/api-threshold-rules";

export async function callRulesApi(customer, setRules, setLoading) {
  var customer_rules = [];
  setLoading(true);

  var current_customer = customer_info.find(
    (info) => info.customer_id === customer.customer_id
  );

  await api_threshold
    .get(`/api/rules/${current_customer.customer_id}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "auth-header": current_customer.authHeader,
      },
    })
    .then((response) => {
      if (response.data !== 401 && response.data.results[0] !== undefined) {
        for (var i = 0; i < response.data.results[0].apis.length; i++) {
          customer_rules.push(response.data.results[0].apis[i]);
        }
      } else {
        setRules([]);
      }
    })
    .catch((error) => {
      showNotification("Error in request to backend");
    })
    .finally(() => {
      setRules(customer_rules);
      setLoading(false);
    });
}
