// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-pagination{
    list-style: none;
    display: flex;
    column-gap: 0.7em;
}

.box-pagination a{
    color: inherit;
    text-decoration: none;
}

.box-pagination .pagination-item{
    padding: 1px 4px;
}   

.box-pagination .pagination-item:hover{
    color: var(--primary-purple-sensedia);
}   

.active-page{
    color: var(--primary-purple-sensedia);
    border-bottom: 1px solid var(--primary-purple-sensedia);
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;IACrC,uDAAuD;AAC3D","sourcesContent":[".box-pagination{\n    list-style: none;\n    display: flex;\n    column-gap: 0.7em;\n}\n\n.box-pagination a{\n    color: inherit;\n    text-decoration: none;\n}\n\n.box-pagination .pagination-item{\n    padding: 1px 4px;\n}   \n\n.box-pagination .pagination-item:hover{\n    color: var(--primary-purple-sensedia);\n}   \n\n.active-page{\n    color: var(--primary-purple-sensedia);\n    border-bottom: 1px solid var(--primary-purple-sensedia);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
