// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-login{
    min-height: 100vh;
    min-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background: radial-gradient(circle at 1.99% 43.04%,#22282d,transparent 100%),
    radial-gradient(circle at 99.79% 73.72%,#22282d,transparent 58%),
    radial-gradient(circle at 50% 50%,#4f5357,#4f5357 100%)!important;
}

.container-login{
    width: 380px;
    padding: 4.4em;
    background: var(--white);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    overflow: hidden;

    position: relative;
    bottom: 3em;
}

.container-login .form-login{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.section-login footer{
    position: absolute;
    bottom: 0;
    margin-bottom: 5em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.4em;
}

.footer-img{
    width: 14em;
}

.footer-text{
    font-size: 1.4em;
    color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/pages/authentication/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf;;qEAEiE;AACrE;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,wBAAwB;IACxB,uEAAuE;IACvE,gBAAgB;;IAEhB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".section-login{\n    min-height: 100vh;\n    min-width: 500px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1rem;\n    background: radial-gradient(circle at 1.99% 43.04%,#22282d,transparent 100%),\n    radial-gradient(circle at 99.79% 73.72%,#22282d,transparent 58%),\n    radial-gradient(circle at 50% 50%,#4f5357,#4f5357 100%)!important;\n}\n\n.container-login{\n    width: 380px;\n    padding: 4.4em;\n    background: var(--white);\n    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);\n    overflow: hidden;\n\n    position: relative;\n    bottom: 3em;\n}\n\n.container-login .form-login{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 1rem;\n}\n\n.section-login footer{\n    position: absolute;\n    bottom: 0;\n    margin-bottom: 5em;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    row-gap: 0.4em;\n}\n\n.footer-img{\n    width: 14em;\n}\n\n.footer-text{\n    font-size: 1.4em;\n    color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
